import { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { BellIcon } from '@heroicons/react/24/outline';

interface Notification {
  id: number;
  title: string;
  description: string;
  time: string;
  type: 'alert' | 'update' | 'success';
  read: boolean;
}

const notifications: Notification[] = [
  {
    id: 1,
    title: 'Deal Performance Alert',
    description: 'Deal XYZ is performing below target CTR',
    time: '5m ago',
    type: 'alert',
    read: false
  },
  {
    id: 2,
    title: 'Optimization Complete',
    description: 'Automated optimization improved performance by 15%',
    time: '1h ago',
    type: 'success',
    read: false
  },
  {
    id: 3,
    title: 'New Deal Created',
    description: 'Deal ABC has been set up and is ready for review',
    time: '2h ago',
    type: 'update',
    read: true
  }
];

export function Notifications() {
  const [unreadCount, setUnreadCount] = useState(
    notifications.filter(n => !n.read).length
  );

  const getTypeStyles = (type: Notification['type']) => {
    switch (type) {
      case 'alert':
        return 'bg-red-100 text-red-600';
      case 'success':
        return 'bg-green-100 text-green-600';
      case 'update':
        return 'bg-blue-100 text-blue-600';
    }
  };

  return (
    <Menu as="div" className="relative">
      <Menu.Button className="relative rounded-full p-1.5 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-openx-500">
        <span className="sr-only">View notifications</span>
        <BellIcon className="h-6 w-6" aria-hidden="true" />
        {unreadCount > 0 && (
          <span className="absolute -right-1 -top-1 flex h-5 w-5 items-center justify-center rounded-full bg-red-500 text-xs font-medium text-white">
            {unreadCount}
          </span>
        )}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-96 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-4 py-2 border-b border-gray-100">
            <h3 className="text-sm font-semibold text-gray-900">Notifications</h3>
          </div>
          <div className="max-h-96 overflow-y-auto">
            {notifications.map((notification) => (
              <Menu.Item key={notification.id}>
                {({ active }) => (
                  <div
                    className={`
                      ${active ? 'bg-gray-50' : ''}
                      ${!notification.read ? 'bg-gray-50' : ''}
                      px-4 py-3 border-b border-gray-100 last:border-0
                    `}
                  >
                    <div className="flex items-start">
                      <div className="flex-1 min-w-0">
                        <p className="text-sm font-medium text-gray-900">
                          {notification.title}
                        </p>
                        <p className="text-sm text-gray-500 mt-1">
                          {notification.description}
                        </p>
                        <p className="text-xs text-gray-400 mt-1">
                          {notification.time}
                        </p>
                      </div>
                      <div className={`ml-3 px-2 py-1 rounded-full text-xs font-medium ${getTypeStyles(notification.type)}`}>
                        {notification.type}
                      </div>
                    </div>
                  </div>
                )}
              </Menu.Item>
            ))}
          </div>
          <div className="px-4 py-2 border-t border-gray-100">
            <button
              onClick={() => setUnreadCount(0)}
              className="text-sm text-openx-600 hover:text-openx-700"
            >
              Mark all as read
            </button>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}