
// src/components/deals/steps/InventoryStep.tsx

import { useState, useMemo } from 'react';
import { 
  Card, 
  Title, 
  Text, 
  Badge, 
  ProgressBar, 
  Metric,
  BarChart,
  DonutChart 
} from '@tremor/react';
import { 
  GlobeAltIcon,
  ShieldCheckIcon,
  ChartBarIcon,
  SparklesIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
  StarIcon,
  VideoCameraIcon,
  NewspaperIcon,
  DeviceTabletIcon
} from '@heroicons/react/24/outline';
import { Button } from '../../ui/Button';

import type {
    StepComponentProps,
    DealConfiguration,
    InventoryPackage
  } from '../../../types/dealTypes';

// Mock inventory packages with rich data
const INVENTORY_PACKAGES: InventoryPackage[] = [
  {
    id: 'premium-news',
    name: 'Premium News Network',
    type: 'premium',
    description: 'Top-tier news publishers with high viewability',
    domains: [
      {
        url: 'nytimes.com',
        category: ['News', 'Premium'],
        quality_score: 95,
        brand_safety_score: 98,
        performance: {
          impressions: 8500000,
          clicks:189700,
          conversions:1993,
          viewability: 85,
          ctr: 0.12,
          completion_rate: 82,
          avgCpm: 18.50
        }
      },
      {
        url: 'wsj.com',
        category: ['News', 'Business', 'Premium'],
        quality_score: 94,
        brand_safety_score: 97,
        performance: {
            impressions: 2500000,
            clicks:2340,
            conversions:750,
          viewability: 83,
          ctr: 0.15,
          completion_rate: 80,
          avgCpm: 22.00
        }
      },
      // Add more domains...
    ],
    metrics: {
        impressions: 9000000,
        clicks:3560,
        conversions:982,
      viewability: 84,
      ctr: 0.135,
      completion_rate: 81,
      avgCpm: 20.25,
      totalReach: 80000000,
      uniqueUsers: 45000000,
      averageFrequency: 1.8
    },
    pricing: {
      floorPrice: 15.00,
      recommendedBid: 18.50,
      historicalClearing: 17.75
    },
    format_support: ['Display', 'Video', 'Native'],
    targeting_capabilities: ['Contextual', 'Viewability', 'Brand Safety'],
    availabilityScore: 92,
    qualityScore: 95,
    brandSafetyScore: 98,
    recommendations: [
      {
        type: 'pricing',
        message: 'Increase bid to $19.50 for better win rate',
        impact: '+15% predicted win rate'
      },
      {
        type: 'format',
        message: 'Include video formats for higher engagement',
        impact: '+25% predicted completion rate'
      }
    ]
  },
  // Add more packages...
];

const FORMAT_OPTIONS = [
  {
    id: 'display',
    name: 'Display',
    icon: NewspaperIcon,
    formats: ['Standard Display', 'Rich Media', 'Interstitial']
  },
  {
    id: 'video',
    name: 'Video',
    icon: VideoCameraIcon,
    formats: ['Pre-roll', 'Mid-roll', 'Outstream']
  },
  {
    id: 'native',
    name: 'Native',
    icon: StarIcon,
    formats: ['In-feed', 'Content Recommendation', 'Branded Content']
  }
];

interface InventoryStepProps {
  configuration: DealConfiguration;
  onUpdate: (updates: Partial<DealConfiguration>) => void;
}

export function InventoryStep({ 
    configuration,
    onUpdate,
    onNext,
    onBack,
    onLaunch 
  }: StepComponentProps) {
  const [selectedPackages, setSelectedPackages] = useState<string[]>(
    configuration.inventory.packages.map(p => p.id)
  );
  
  const [activeTab, setActiveTab] = useState<'packages' | 'formats' | 'quality'>('packages');

  // Calculate combined metrics
  const combinedMetrics = useMemo(() => {
    if (selectedPackages.length === 0) return null;

    const packages = INVENTORY_PACKAGES.filter(p => selectedPackages.includes(p.id));
    return {
      totalReach: packages.reduce((sum, p) => sum + p.metrics.totalReach, 0),
      avgViewability: packages.reduce((sum, p) => sum + p.metrics.viewability, 0) / packages.length,
      avgCTR: packages.reduce((sum, p) => sum + p.metrics.ctr, 0) / packages.length,
      weightedCPM: packages.reduce((sum, p) => sum + p.metrics.avgCpm * (p.metrics.totalReach / packages.reduce((s, pkg) => s + pkg.metrics.totalReach, 0)), 0)
    };
  }, [selectedPackages]);

  return (
    <div className="space-y-6">
      {/* Navigation Tabs */}
      <div className="flex space-x-4">
        {['packages', 'formats', 'quality'].map((tab) => (
          <button
            key={tab}
            className={`
              px-4 py-2 rounded-lg text-sm font-medium transition-colors
              ${activeTab === tab 
                ? 'bg-blue-100 text-blue-700' 
                : 'text-gray-600 hover:bg-gray-100'
              }
            `}
            onClick={() => setActiveTab(tab as typeof activeTab)}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
          </button>
        ))}
      </div>

      {activeTab === 'packages' && (
        <>
          {/* Package Selection */}
          <Card>
            <div className="flex items-center justify-between">
              <div>
                <Title>Curated Inventory Packages</Title>
                <Text className="text-gray-500">
                  Select from premium publisher packages
                </Text>
              </div>
              <Badge color="blue" icon={SparklesIcon}>
                AI Optimized
              </Badge>
            </div>

            <div className="mt-6 grid grid-cols-2 gap-6">
              {INVENTORY_PACKAGES.map((pkg) => (
                <div
                  key={pkg.id}
                  className={`
                    rounded-lg border p-6 cursor-pointer transition-all
                    ${selectedPackages.includes(pkg.id)
                      ? 'border-blue-500 bg-blue-50'
                      : 'border-gray-200 hover:bg-gray-50'
                    }
                  `}
                  onClick={() => {
                    const newSelection = selectedPackages.includes(pkg.id)
                      ? selectedPackages.filter(id => id !== pkg.id)
                      : [...selectedPackages, pkg.id];
                    setSelectedPackages(newSelection);
                    onUpdate({
                      inventory: {
                        ...configuration.inventory,
                        packages: INVENTORY_PACKAGES.filter(p => 
                          newSelection.includes(p.id)
                        )
                      }
                    });
                  }}
                >
                  {/* Package Header */}
                  <div className="flex items-start justify-between">
                    <div>
                      <Text className="font-medium text-lg">{pkg.name}</Text>
                      <Text className="text-gray-500 mt-1">
                        {pkg.description}
                      </Text>
                    </div>
                    <div className="flex space-x-2">
                      <Badge color="purple">
                        {pkg.type.charAt(0).toUpperCase() + pkg.type.slice(1)}
                      </Badge>
                      <Badge color="gray">
                        {pkg.domains.length} Domains
                      </Badge>
                    </div>
                  </div>

                  {/* Key Metrics */}
                  <div className="mt-6 grid grid-cols-4 gap-4">
                    <div>
                      <Text className="text-sm text-gray-500">Reach</Text>
                      <Text className="font-medium">
                        {(pkg.metrics.totalReach / 1000000).toFixed(1)}M
                      </Text>
                    </div>
                    <div>
                      <Text className="text-sm text-gray-500">Viewability</Text>
                      <Text className="font-medium">
                        {pkg.metrics.viewability}%
                      </Text>
                    </div>
                    <div>
                      <Text className="text-sm text-gray-500">Avg. CPM</Text>
                      <Text className="font-medium">
                        ${pkg.metrics.avgCpm.toFixed(2)}
                      </Text>
                    </div>
                    <div>
                      <Text className="text-sm text-gray-500">Quality</Text>
                      <Text className="font-medium">
                        {pkg.qualityScore}/100
                      </Text>
                    </div>
                  </div>

                  {selectedPackages.includes(pkg.id) && (
                    <>
                      {/* Detailed Metrics */}
                      <div className="mt-6 pt-6 border-t border-gray-200">
                        <div className="grid grid-cols-2 gap-6">
                          <div>
                            <Text className="font-medium">Performance</Text>
                            <BarChart
                              className="mt-4 h-32"
                              data={[
                                {
                                  metric: 'Viewability',
                                  value: pkg.metrics.viewability,
                                  benchmark: 70
                                },
                                {
                                  metric: 'CTR',
                                  value: pkg.metrics.ctr * 100,
                                  benchmark: 0.12 * 100
                                },
                                {
                                  metric: 'Completion',
                                  value: pkg.metrics.conversions || 0,
                                  benchmark: 65
                                }
                              ]}
                              index="metric"
                              categories={['value', 'benchmark']}
                              colors={['blue', 'gray']}
                            />
                          </div>
                          <div>
                            <Text className="font-medium">Format Distribution</Text>
                            <DonutChart
                              className="mt-4 h-32"
                              data={[
                                { format: 'Display', value: 60 },
                                { format: 'Video', value: 25 },
                                { format: 'Native', value: 15 }
                              ]}
                              category="value"
                              index="format"
                              colors={['blue', 'green', 'purple']}
                            />
                          </div>
                        </div>
                      </div>

                      {/* Recommendations */}
                      {pkg.recommendations && (
                        <div className="mt-6 space-y-3">
                          {pkg.recommendations.map((rec, idx) => (
                            <div
                              key={idx}
                              className="flex items-start space-x-3 p-3 bg-blue-50 rounded-lg"
                            >
                              <SparklesIcon className="h-5 w-5 text-blue-600 flex-shrink-0 mt-0.5" />
                              <div>
                                <Text className="font-medium">
                                  {rec.message}
                                </Text>
                                <Text className="text-sm text-blue-700">
                                  {rec.impact}
                                </Text>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                </div>
              ))}
            </div>
          </Card>

          {/* Combined Metrics */}
          {combinedMetrics && (
            <Card>
              <Title>Combined Package Metrics</Title>
              <div className="mt-4 grid grid-cols-4 gap-6">
                <div>
                  <Text className="text-gray-500">Total Reach</Text>
                  <Metric>
                    {(combinedMetrics.totalReach / 1000000).toFixed(1)}M
                  </Metric>
                </div>
                <div>
                  <Text className="text-gray-500">Avg. Viewability</Text>
                  <Metric>
                    {combinedMetrics.avgViewability.toFixed(1)}%
                  </Metric>
                </div>
                <div>
                  <Text className="text-gray-500">Avg. CTR</Text>
                  <Metric>
                    {(combinedMetrics.avgCTR * 100).toFixed(2)}%
                  </Metric>
                </div>
                <div>
                  <Text className="text-gray-500">Weighted CPM</Text>
                  <Metric>
                    ${combinedMetrics.weightedCPM.toFixed(2)}
                  </Metric>
                </div>
              </div>
            </Card>
          )}
        </>
      )}

      {activeTab === 'formats' && (
        <Card>
          <Title>Format Configuration</Title>
          <Text className="text-gray-500">
            Select and configure ad formats
          </Text>

          <div className="mt-6 space-y-6">
            {FORMAT_OPTIONS.map((formatGroup) => {
              const Icon = formatGroup.icon;
              return (
                <div key={formatGroup.id} className="space-y-4">
                  <div className="flex items-center space-x-3">
                    <div className="p-2 bg-gray-100 rounded-lg">
                      <Icon className="h-6 w-6 text-gray-600" />
                    </div>
                    <Text className="font-medium">{formatGroup.name}</Text>
                  </div>

                  <div className="grid grid-cols-3 gap-4 pl-11">
                    {formatGroup.formats.map((format) => (
                      <div
                        key={format}
                        className="p-4 rounded-lg border border-gray-200 hover:bg-gray-50 cursor-pointer"
                      >
                        <Text>{format}</Text>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </Card>
      )}

      {activeTab === 'quality' && (
        <Card>
          <Title>Quality & Brand Safety</Title>
          <Text className="text-gray-500">
            Configure quality and brand safety settings
          </Text>

          <div className="mt-6 space-y-6">
            {/* Brand Safety Levels */}
            <div>
              <Text className="font-medium">Brand Safety Level</Text>
              <div className="mt-4 grid grid-cols-3 gap-4">
                {[
                  {
                    level: 'standard',
                    name: 'Standard',
                    description: 'Industry standard brand safety measures'
                  },
                  {
                    level: 'strict',
                    name: 'Strict',
                    description: 'Enhanced protection with additional restrictions'
                  },
                  {
                    level: 'custom',
                    name: 'Custom',
                    description: 'Tailored brand safety rules and settings'
                  }
            ].map(({ level, name, description }) => (
                <div
                  key={level}
                  className={`
                    p-4 rounded-lg border cursor-pointer transition-all
                    ${configuration.inventory.brand_safety.level === level
                      ? 'border-blue-500 bg-blue-50'
                      : 'border-gray-200 hover:bg-gray-50'
                    }
                  `}
                  onClick={() => onUpdate({
                    inventory: {
                      ...configuration.inventory,
                      brand_safety: {
                        ...configuration.inventory.brand_safety,
                        level: level as 'standard' | 'strict' | 'custom'
                      }
                    }
                  })}
                >
                  <Text className="font-medium">{name}</Text>
                  <Text className="text-sm text-gray-500 mt-1">
                    {description}
                  </Text>
                </div>
              ))}
            </div>
          </div>

          {/* Block Lists */}
          <div>
            <Text className="font-medium">Category Exclusions</Text>
            <div className="mt-4 grid grid-cols-4 gap-4">
              {[
                'Adult Content',
                'Violence',
                'Gambling',
                'Politics',
                'Alcohol',
                'Hate Speech',
                'Controversial News',
                'User Generated Content'
              ].map((category) => (
                <div
                  key={category}
                  className={`
                    p-3 rounded-lg border cursor-pointer transition-all
                    flex items-center justify-between
                    ${configuration.inventory.brand_safety.blocks.includes(category)
                      ? 'border-red-500 bg-red-50'
                      : 'border-gray-200 hover:bg-gray-50'
                    }
                  `}
                  onClick={() => {
                    const newBlocks = configuration.inventory.brand_safety.blocks
                      .includes(category)
                        ? configuration.inventory.brand_safety.blocks
                            .filter(b => b !== category)
                        : [...configuration.inventory.brand_safety.blocks, category];
                    onUpdate({
                      inventory: {
                        ...configuration.inventory,
                        brand_safety: {
                          ...configuration.inventory.brand_safety,
                          blocks: newBlocks
                        }
                      }
                    });
                  }}
                >
                  <Text className="text-sm">{category}</Text>
                  {configuration.inventory.brand_safety.blocks.includes(category) && (
                    <ExclamationTriangleIcon className="h-5 w-5 text-red-500" />
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* Quality Thresholds */}
          <div>
            <Text className="font-medium">Quality Requirements</Text>
            <div className="mt-4 space-y-4">
              {[
                {
                  metric: 'Viewability',
                  current: 70,
                  recommended: 75,
                  range: [50, 100]
                },
                {
                  metric: 'Brand Safety Score',
                  current: 85,
                  recommended: 90,
                  range: [70, 100]
                },
                {
                  metric: 'Invalid Traffic',
                  current: 1,
                  recommended: 0.5,
                  range: [0, 5]
                }
              ].map((threshold) => (
                <div key={threshold.metric} className="space-y-2">
                  <div className="flex justify-between items-center">
                    <Text className="text-sm">{threshold.metric}</Text>
                    <div className="flex items-center space-x-2">
                      <Badge color="blue">
                        Current: {threshold.current}%
                      </Badge>
                      <Badge color="green">
                        Recommended: {threshold.recommended}%
                      </Badge>
                    </div>
                  </div>
                  <input
                    type="range"
                    min={threshold.range[0]}
                    max={threshold.range[1]}
                    value={threshold.current}
                    className="w-full"
                    onChange={(e) => {
                      // Update quality thresholds
                    }}
                  />
                  <div className="flex justify-between text-xs text-gray-500">
                    <span>{threshold.range[0]}%</span>
                    <span>{threshold.range[1]}%</span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Custom Rules */}
          {configuration.inventory.brand_safety.level === 'custom' && (
            <div>
              <Text className="font-medium">Custom Rules</Text>
              <div className="mt-4 space-y-4">
                {/* Add custom rule builder here */}
              </div>
            </div>
          )}
        </div>
      </Card>
    )}
  </div>
);
}