import { useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  Badge,
  ProgressBar
} from '@tremor/react';
import { 
  ChartBarIcon, 
  PencilIcon, 
  DocumentDuplicateIcon,
  ArrowTrendingUpIcon 
} from '@heroicons/react/24/outline';

interface AudienceSegment {
  id: string;
  name: string;
  type: 'First Party' | 'Third Party' | 'Contextual' | 'Custom';
  source: string;
  status: 'active' | 'draft' | 'archived';
  reach: number;
  matchRate: number;
  activeDeals: number;
  lastUpdated: string;
  performance: {
    ctr: number;
    viewability: number;
    conversion: number;
  };
}

const mockAudiences: AudienceSegment[] = [
  {
    id: 'AUD-001',
    name: 'Auto Intenders',
    type: 'Third Party',
    source: 'Oracle',
    status: 'active',
    reach: 2500000,
    matchRate: 84,
    activeDeals: 12,
    lastUpdated: '2024-01-15',
    performance: {
      ctr: 0.12,
      viewability: 76,
      conversion: 2.3
    }
  },
  {
    id: 'AUD-002',
    name: 'Premium Shoppers',
    type: 'Custom',
    source: 'OpenX',
    status: 'active',
    reach: 1800000,
    matchRate: 92,
    activeDeals: 8,
    lastUpdated: '2024-01-16',
    performance: {
      ctr: 0.15,
      viewability: 82,
      conversion: 3.1
    }
  },
  {
    id: 'AUD-003',
    name: 'Tech Enthusiasts',
    type: 'Contextual',
    source: 'OpenX',
    status: 'draft',
    reach: 3200000,
    matchRate: 88,
    activeDeals: 0,
    lastUpdated: '2024-01-14',
    performance: {
      ctr: 0,
      viewability: 0,
      conversion: 0
    }
  }
];

const statusColors = {
  active: 'green',
  draft: 'yellow',
  archived: 'gray'
} as const;

const typeColors = {
  'First Party': 'blue',
  'Third Party': 'purple',
  'Contextual': 'emerald',
  'Custom': 'orange'
} as const;

export function AudienceTable() {
  const [selectedSegments, setSelectedSegments] = useState<string[]>([]);

  const toggleSegmentSelection = (segmentId: string) => {
    setSelectedSegments(prev => 
      prev.includes(segmentId)
        ? prev.filter(id => id !== segmentId)
        : [...prev, segmentId]
    );
  };

  return (
    <div className="mt-4">
      {selectedSegments.length > 0 && (
        <div className="px-4 pb-4 flex items-center justify-between bg-blue-50 rounded-t-lg border-b border-blue-100">
          <span className="text-sm font-medium text-blue-900">
            {selectedSegments.length} segments selected
          </span>
          <div className="flex space-x-2">
            <button className="inline-flex items-center px-3 py-1.5 text-sm font-medium text-blue-700 hover:text-blue-800">
              <DocumentDuplicateIcon className="h-4 w-4 mr-1.5" />
              Clone
            </button>
            <button className="inline-flex items-center px-3 py-1.5 text-sm font-medium text-blue-700 hover:text-blue-800">
              <ArrowTrendingUpIcon className="h-4 w-4 mr-1.5" />
              Compare
            </button>
          </div>
        </div>
      )}

      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell className="w-12">
              <input
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                checked={selectedSegments.length === mockAudiences.length}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedSegments(mockAudiences.map(segment => segment.id));
                  } else {
                    setSelectedSegments([]);
                  }
                }}
              />
            </TableHeaderCell>
            <TableHeaderCell>Audience Details</TableHeaderCell>
            <TableHeaderCell>Type</TableHeaderCell>
            <TableHeaderCell>Status</TableHeaderCell>
            <TableHeaderCell>Reach</TableHeaderCell>
            <TableHeaderCell>Performance</TableHeaderCell>
            <TableHeaderCell>Active Deals</TableHeaderCell>
            <TableHeaderCell className="text-right">Actions</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mockAudiences.map((segment) => (
            <TableRow 
              key={segment.id}
              className={selectedSegments.includes(segment.id) ? 'bg-blue-50' : ''}
            >
              <TableCell>
                <input
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                  checked={selectedSegments.includes(segment.id)}
                  onChange={() => toggleSegmentSelection(segment.id)}
                />
              </TableCell>
              <TableCell>
                <div>
                  <div className="font-medium text-gray-900">{segment.name}</div>
                  <div className="text-sm text-gray-500">{segment.source}</div>
                  <div className="text-xs text-gray-400">{segment.id}</div>
                </div>
              </TableCell>
              <TableCell>
                <Badge color={typeColors[segment.type]} size="sm">
                  {segment.type}
                </Badge>
              </TableCell>
              <TableCell>
                <Badge color={statusColors[segment.status]} size="sm">
                  {segment.status.charAt(0).toUpperCase() + segment.status.slice(1)}
                </Badge>
              </TableCell>
              <TableCell>
                <div className="space-y-1">
                  <div className="font-medium">{segment.reach.toLocaleString()}</div>
                  <div className="flex items-center justify-between text-sm">
                    <span className="text-gray-500">Match Rate</span>
                    <span>{segment.matchRate}%</span>
                  </div>
                  <ProgressBar
                    value={segment.matchRate}
                    color="blue"
                    className="h-1.5"
                  />
                </div>
              </TableCell>
              <TableCell>
                {segment.status === 'active' ? (
                  <div className="space-y-1">
                    <div className="flex items-center justify-between text-sm">
                      <span>CTR</span>
                      <span className="font-medium">{(segment.performance.ctr * 100).toFixed(2)}%</span>
                    </div>
                    <div className="flex items-center justify-between text-sm">
                      <span>Viewability</span>
                      <span className="font-medium">{segment.performance.viewability}%</span>
                    </div>
                    <div className="flex items-center justify-between text-sm">
                      <span>Conversion</span>
                      <span className="font-medium">{segment.performance.conversion}%</span>
                    </div>
                  </div>
                ) : (
                  <span className="text-sm text-gray-500">No data available</span>
                )}
              </TableCell>
              <TableCell>
                <div className="text-sm font-medium">
                  {segment.activeDeals}
                </div>
              </TableCell>
              <TableCell>
                <div className="flex justify-end space-x-2">
                  <button className="rounded-md p-2 hover:bg-gray-100">
                    <PencilIcon className="h-5 w-5 text-gray-400" />
                  </button>
                  <button className="rounded-md p-2 hover:bg-gray-100">
                    <ChartBarIcon className="h-5 w-5 text-gray-400" />
                  </button>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}