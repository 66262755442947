import { Fragment, useState } from 'react';
import { Dialog, Combobox, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useUserRole } from '../../hooks/useUserRole';

interface CommandPaletteProps {
  isOpen: boolean;
  onClose: () => void;
}

// This would typically come from your data store
const searchCategories = [
  { id: 'deals', name: 'Deals', shortcut: 'd', color: 'bg-purple-500' },
  { id: 'inventory', name: 'Inventory', shortcut: 'i', color: 'bg-blue-500' },
  { id: 'audiences', name: 'Audiences', shortcut: 'a', color: 'bg-green-500' },
  { id: 'performance', name: 'Performance', shortcut: 'p', color: 'bg-yellow-500' },
] as const;

const roleSpecificQueries = {
  'Sales': [
    { id: 1, name: 'Show available inventory for automotive category', category: 'inventory' },
    { id: 2, name: 'Find deals with >80% viewability in the last 30 days', category: 'deals' },
    { id: 3, name: 'Generate site list for CPG vertical', category: 'inventory' },
  ],
  'Account Management': [
    { id: 4, name: 'Show underperforming deals for client X', category: 'performance' },
    { id: 5, name: 'Find optimization opportunities', category: 'performance' },
    { id: 6, name: 'Show campaign performance trends', category: 'performance' },
  ],
  // Add more role-specific queries
};

export function CommandPalette({ isOpen, onClose }: CommandPaletteProps) {
  const [query, setQuery] = useState('');
  const { currentRole } = useUserRole();

  const filteredItems = 
    query === ''
      ? roleSpecificQueries[currentRole as keyof typeof roleSpecificQueries] || []
      : roleSpecificQueries[currentRole as keyof typeof roleSpecificQueries]?.filter((item) =>
          item.name.toLowerCase().includes(query.toLowerCase())
        ) || [];

  return (
    <Transition.Root show={isOpen} as={Fragment} afterLeave={() => setQuery('')}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox onChange={(item: any) => {
                onClose();
                // Handle selection
              }}>
                <div className="relative">
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                    placeholder="Search or type a command..."
                    onChange={(event) => setQuery(event.target.value)}
                  />
                </div>

                {filteredItems.length > 0 && (
                  <Combobox.Options static className="max-h-96 scroll-py-3 overflow-y-auto p-3">
                    {filteredItems.map((item) => (
                      <Combobox.Option
                        key={item.id}
                        value={item}
                        className={({ active }) =>
                          `flex cursor-default select-none rounded-xl p-3 ${
                            active ? 'bg-gray-100' : ''
                          }`
                        }
                      >
                        {({ active }) => (
                          <>
                            <div
                              className={`flex h-6 w-6 flex-none items-center justify-center rounded-lg ${
                                searchCategories.find(cat => cat.id === item.category)?.color
                              }`}
                            >
<MagnifyingGlassIcon className="h-4 w-4 text-white" aria-hidden="true" />
                            </div>
                            <div className="ml-4 flex-auto">
                              <p className={`text-sm ${active ? 'text-gray-900' : 'text-gray-700'}`}>
                                {item.name}
                              </p>
                              <p className="mt-1 text-sm text-gray-500">
                                {searchCategories.find(cat => cat.id === item.category)?.name}
                              </p>
                            </div>
                          </>
                        )}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}

                {query !== '' && filteredItems.length === 0 && (
                  <div className="px-6 py-14 text-center text-sm sm:px-14">
                    <p className="text-gray-900">No results found</p>
                    <p className="mt-1 text-gray-500">
                      Try adjusting your search query or browse through the categories below.
                    </p>
                  </div>
                )}

                {/* Quick links and shortcuts */}
                <div className="flex flex-wrap items-center bg-gray-50 px-4 py-2.5 text-xs text-gray-700">
                  {searchCategories.map((category) => (
                    <div key={category.id} className="flex items-center mr-4">
                      <kbd className="mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white font-semibold">
                        {category.shortcut}
                      </kbd>
                      <span className="ml-1">{category.name}</span>
                    </div>
                  ))}
                </div>
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}