import type { Deal } from '../types/deals';

export const mockDeals: Deal[] = [
  {
    id: 'DEAL-001',
    name: 'Premium Auto Campaign',
    advertiser: 'AutoMax Inc',
    type: 'PMP',
    status: 'active',
    health: {
      score: 65,
      status: 'warning',
      lastChecked: '2024-01-20T10:00:00Z',
      issues: [
        {
          type: 'bid_response',
          severity: 'high',
          message: 'Low bid response rate detected'
        }
      ]
    },
    performance: {
      bidResponses: 45,
      winRate: 15,
      viewability: 82,
      targetCpm: 12.50,
      actualCpm: 10.80
    },
    setup: {
      audiences: ['Auto Intenders', 'High Income'],
      domains: ['premium-news.com', 'auto-enthusiast.com'],
      formats: ['Display', 'Native'],
      targeting: {
        geo: ['US'],
        devices: ['Desktop', 'Mobile']
      }
    },
    optimization: {
      lastOptimized: '2024-01-15T08:00:00Z',
      recommendations: [
        {
          type: 'audience_expansion',
          impact: 25,
          confidence: 85,
          message: 'Add similar auto-interest segments'
        }
      ]
    }
  },
  {
    id: 'DEAL-002',
    name: 'Gaming Network Premium',
    advertiser: 'GameStream',
    type: 'Programmatic Guaranteed',
    status: 'critical',
    health: {
      score: 45,
      status: 'critical',
      lastChecked: '2024-01-20T09:30:00Z',
      issues: [
        {
          type: 'no_bids',
          severity: 'high',
          message: 'No bid responses in last 24 hours'
        },
        {
          type: 'setup_issue',
          severity: 'high',
          message: 'DSP targeting mismatch detected'
        }
      ]
    },
    performance: {
      bidResponses: 0,
      winRate: 0,
      viewability: 0,
      targetCpm: 18.00,
      actualCpm: 0
    },
    setup: {
      audiences: ['Gaming Enthusiasts', 'Console Owners'],
      domains: ['gaming-network.com', 'esports-central.com'],
      formats: ['Video', 'Display'],
      targeting: {
        geo: ['US', 'CA'],
        devices: ['Desktop', 'Mobile', 'Connected TV']
      }
    },
    optimization: {
      lastOptimized: null,
      recommendations: [
        {
          type: 'dsp_setup',
          impact: 100,
          confidence: 95,
          message: 'Verify DSP seat ID and targeting setup'
        }
      ]
    }
  },
  {
    id: 'DEAL-003',
    name: 'Retail Performance Bundle',
    advertiser: 'ShopRight',
    type: 'PMP',
    status: 'active',
    health: {
      score: 92,
      status: 'healthy',
      lastChecked: '2024-01-20T10:15:00Z',
      issues: []
    },
    performance: {
      bidResponses: 95,
      winRate: 42,
      viewability: 88,
      targetCpm: 8.50,
      actualCpm: 9.20
    },
    setup: {
      audiences: ['Shopping Intenders', 'High Value Customers', 'Recent Purchasers'],
      domains: ['retail-news.com', 'shopping-guide.com', 'deals-daily.com'],
      formats: ['Display', 'Native', 'Video'],
      targeting: {
        geo: ['US'],
        devices: ['All']
      }
    },
    optimization: {
      lastOptimized: '2024-01-18T14:00:00Z',
      recommendations: [
        {
          type: 'scale_opportunity',
          impact: 15,
          confidence: 75,
          message: 'Potential to expand to similar retail domains'
        }
      ]
    }
  },
  {
    id: 'DEAL-004',
    name: 'Finance Professional Network',
    advertiser: 'WealthMax',
    type: 'Preferred',
    status: 'warning',
    health: {
      score: 72,
      status: 'warning',
      lastChecked: '2024-01-20T10:30:00Z',
      issues: [
        {
          type: 'price_efficiency',
          severity: 'medium',
          message: 'Win rate below target at current CPM'
        }
      ]
    },
    performance: {
      bidResponses: 82,
      winRate: 12,
      viewability: 91,
      targetCpm: 25.00,
      actualCpm: 22.50
    },
    setup: {
      audiences: ['Finance Professionals', 'Investment Decision Makers'],
      domains: ['finance-pro.com', 'investment-daily.com'],
      formats: ['Display'],
      targeting: {
        geo: ['US', 'UK', 'HK', 'SG'],
        devices: ['Desktop']
      }
    },
    optimization: {
      lastOptimized: '2024-01-17T11:00:00Z',
      recommendations: [
        {
          type: 'bid_optimization',
          impact: 35,
          confidence: 88,
          message: 'Adjust CPM to improve win rate'
        }
      ]
    }
  },
  {
    id: 'DEAL-005',
    name: 'Travel Enthusiasts Campaign',
    advertiser: 'TravelPlus',
    type: 'PMP',
    status: 'paused',
    health: {
      score: 58,
      status: 'warning',
      lastChecked: '2024-01-20T09:45:00Z',
      issues: [
        {
          type: 'scale_issue',
          severity: 'medium',
          message: 'Delivery below forecast by 45%'
        }
      ]
    },
    performance: {
      bidResponses: 65,
      winRate: 28,
      viewability: 85,
      targetCpm: 15.00,
      actualCpm: 14.20
    },
    setup: {
      audiences: ['Frequent Travelers', 'Luxury Travelers'],
      domains: ['travel-mag.com', 'destination-guide.com'],
      formats: ['Native', 'Video'],
      targeting: {
        geo: ['US', 'EU'],
        devices: ['All']
      }
    },
    optimization: {
      lastOptimized: '2024-01-16T13:00:00Z',
      recommendations: [
        {
          type: 'audience_expansion',
          impact: 40,
          confidence: 82,
          message: 'Add weekend traveler segments'
        },
        {
          type: 'format_optimization',
          impact: 25,
          confidence: 78,
          message: 'Enable display format for broader reach'
        }
      ]
    }
  }
];