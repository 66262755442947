// src/components/deals/DealCreationWizard.tsx

import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Card, Title, Text, Badge } from '@tremor/react';
import { Button } from '../ui/Button';

// Import step components
import { ObjectivesStep } from './steps/ObjectivesStep';
import { AudienceStep } from './steps/AudienceStep';
import { InventoryStep } from './steps/InventoryStep';
import { BudgetStep } from './steps/BudgetStep';
import { ReviewStep } from './steps/ReviewStep';

import { DealConfiguration, initialConfiguration } from '../../types/dealTypes';


// Import types
// import type { 
//   DealBasicInfo,
//   AudienceConfiguration,
//   InventoryConfiguration,
//   BudgetConfiguration,
//   GoalsConfiguration,
//   StepProps
// } from '../../types/dealCreation';

// import type { 
//     DealConfiguration,
//     DealBasicInfo
//   } from '../../types/dealTypes';
  
// Define initial configuration


const steps = [
  { id: 'objectives', name: 'Objectives', component: ObjectivesStep },
  { id: 'audience', name: 'Audience', component: AudienceStep },
  { id: 'inventory', name: 'Inventory', component: InventoryStep },
  { id: 'budget', name: 'Budget', component: BudgetStep },
  { id: 'review', name: 'Review', component: ReviewStep }
];

interface DealCreationWizardProps {
    isOpen: boolean;
    onClose: () => void;
    onComplete: (configuration: DealConfiguration) => void;
  }
  
  export function DealCreationWizard({ 
    isOpen, 
    onClose, 
    onComplete 
  }: DealCreationWizardProps) {
    const [currentStep, setCurrentStep] = useState(0);
    const [configuration, setConfiguration] = useState<DealConfiguration>(initialConfiguration);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
  
    const handleStepComplete = (updates: Partial<DealConfiguration>) => {
      setConfiguration(prev => ({
        ...prev,
        ...updates
      }));
    };
  
    const handleNext = () => {
      if (currentStep < steps.length - 1) {
        setCurrentStep(prev => prev + 1);
      }
    };
  
    const handleBack = () => {
      if (currentStep > 0) {
        setCurrentStep(prev => prev - 1);
      }
    };
  
    const handleLaunch = () => {
      onComplete(configuration);
      onClose();
    };
  
    const CurrentStepComponent = steps[currentStep].component;
  
    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        className="fixed inset-0 z-50 overflow-y-auto"
      >
        <div className="flex min-h-screen items-center justify-center">
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
  
          <div className="relative bg-white w-full max-w-6xl rounded-xl shadow-xl">
            {/* Header */}
            <div className="px-6 py-4 border-b border-gray-200">
              <div className="flex items-center justify-between">
                <div>
                  <Dialog.Title className="text-xl font-semibold">
                    Create New Deal
                  </Dialog.Title>
                  <Text className="text-gray-500">
                    Step {currentStep + 1} of {steps.length}: {steps[currentStep].name}
                  </Text>
                </div>
                <button
                  onClick={onClose}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-6 w-6" />
                </button>
              </div>
  
              {/* Progress bar */}
              <div className="mt-4">
                <div className="flex justify-between mb-2">
                  {steps.map((step, index) => (
                    <div
                      key={step.id}
                      className={`flex items-center ${
                        index > 0 ? 'ml-4' : ''
                      }`}
                    >
                      <div
                        className={`rounded-full h-8 w-8 flex items-center justify-center ${
                          index <= currentStep
                            ? 'bg-blue-600 text-white'
                            : 'bg-gray-200 text-gray-400'
                        }`}
                      >
                        {index + 1}
                      </div>
                      <Text className="ml-2">{step.name}</Text>
                    </div>
                  ))}
                </div>
                <div className="h-2 bg-gray-200 rounded-full">
                  <div
                    className="h-full bg-blue-600 rounded-full transition-all duration-300"
                    style={{ width: `${((currentStep + 1) / steps.length) * 100}%` }}
                  />
                </div>
              </div>
            </div>
  
            {/* Validation Errors */}
            {validationErrors.length > 0 && (
              <div className="px-6 py-2 bg-red-50">
                {validationErrors.map((error, index) => (
                  <Text key={index} className="text-red-600 text-sm">
                    {error}
                  </Text>
                ))}
              </div>
            )}
  
            {/* Main content */}
            <div className="p-6">
              <CurrentStepComponent
                configuration={configuration}
                onUpdate={handleStepComplete}
                onNext={handleNext}
                onBack={handleBack}
                onLaunch={handleLaunch}
              />
            </div>
  
            {/* Footer */}
            {currentStep < steps.length - 1 && (
              <div className="px-6 py-4 border-t border-gray-200 flex justify-between">
                <Button
                  variant="secondary"
                  onClick={handleBack}
                  disabled={currentStep === 0}
                >
                  Back
                </Button>
                <Button
                  variant="primary"
                  onClick={handleNext}
                >
                  Continue
                </Button>
              </div>
            )}
          </div>
        </div>
      </Dialog>
    );
  }