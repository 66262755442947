import { Fragment, useState } from 'react';
import { Dialog, Transition, RadioGroup } from '@headlessui/react';
import { XMarkIcon, CheckIcon } from '@heroicons/react/24/outline';
import { Calendar } from '../ui/calendar';


interface DateRangePickerProps {
  open: boolean;
  onClose: () => void;
  onSelect: (range: string) => void;
}

const predefinedRanges = [
  { id: 'today', name: 'Today', description: 'Last 24 hours' },
  { id: '7d', name: 'Last 7 days', description: 'Previous week performance' },
  { id: '30d', name: 'Last 30 days', description: 'Monthly overview' },
  { id: '90d', name: 'Last quarter', description: 'Quarterly performance' },
  { id: 'ytd', name: 'Year to date', description: 'Performance since January 1st' },
  { id: 'custom', name: 'Custom range', description: 'Select specific dates' },
];

export function DateRangePicker({ open, onClose, onSelect }: DateRangePickerProps) {
  const [selectedRange, setSelectedRange] = useState(predefinedRanges[1]);
  const [customStartDate, setCustomStartDate] = useState<Date | undefined>(undefined);
  const [customEndDate, setCustomEndDate] = useState<Date | undefined>(undefined);

  const handleSelect = () => {
    if (selectedRange.id === 'custom' && customStartDate && customEndDate) {
      onSelect(`${customStartDate.toLocaleDateString()} - ${customEndDate.toLocaleDateString()}`);
    } else {
      onSelect(selectedRange.name);
    }
    onClose();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 pr-4 pt-4">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" />
                  </button>
                </div>

                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    <Dialog.Title as="h3" className="text-lg font-semibold leading-6 text-gray-900">
                      Select Date Range
                    </Dialog.Title>

                    <div className="mt-6">
                      <RadioGroup value={selectedRange} onChange={setSelectedRange}>
                        <div className="space-y-4">
                          {predefinedRanges.map((range) => (
                            <RadioGroup.Option
                              key={range.id}
                              value={range}
                              className={({ checked }) =>
                                `${checked ? 'bg-blue-50 border-blue-200' : 'border-gray-200'}
                                relative flex cursor-pointer rounded-lg border p-4 focus:outline-none`
                              }
                            >
                              {({ checked }) => (
                                <div className="flex w-full items-center justify-between">
                                  <div className="flex items-center">
                                    <div className="text-sm">
                                      <RadioGroup.Label
                                        as="p"
                                        className={`font-medium ${
                                          checked ? 'text-blue-900' : 'text-gray-900'
                                        }`}
                                      >
                                        {range.name}
                                      </RadioGroup.Label>
                                      <RadioGroup.Description
                                        as="p"
                                        className={`${
                                          checked ? 'text-blue-700' : 'text-gray-500'
                                        }`}
                                      >
                                        {range.description}
                                      </RadioGroup.Description>
                                    </div>
                                  </div>
                                  {checked && (
                                    <div className="shrink-0 text-blue-600">
                                      <CheckIcon className="h-6 w-6" />
                                    </div>
                                  )}
                                </div>
                              )}
                            </RadioGroup.Option>
                          ))}
                        </div>
                      </RadioGroup>

                      {selectedRange.id === 'custom' && (
                        <div className="mt-6 space-y-4">
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Start Date
                            </label>
                            <Calendar
                              mode="single"
                              selected={customStartDate}
                              onSelect={setCustomStartDate}
                              className="mt-2"
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              End Date
                            </label>
                            <Calendar
                              mode="single"
                              selected={customEndDate}
                              onSelect={setCustomEndDate}
                              className="mt-2"
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="mt-6 sm:mt-8 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:ml-3 sm:w-auto"
                        onClick={handleSelect}
                      >
                        Apply
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={onClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}