// src/pages/Deals.tsx
import { useState } from 'react';
import { Tab } from '@headlessui/react';
import {
  AdjustmentsHorizontalIcon,
  PlusIcon,
  TableCellsIcon,
  Squares2X2Icon,
  SparklesIcon,
  ShieldCheckIcon,
  ChartBarIcon,
  ArrowTrendingUpIcon
} from '@heroicons/react/24/outline';
import { Card } from '../components/ui/Card';
import { Text, Badge } from '@tremor/react';
import { DealsTable } from '../components/deals/DealsTable';
import { DealList } from '../components/deals/DealList';
import { DealsGrid } from '../components/deals/DealsGrid';
import { DealMetrics } from '../components/deals/DealMetrics';
import { DealFilters } from '../components/deals/DealFilters';
import { DealHealthWizard } from '../components/deals/health/DealHealthWizard';
import { DealOptimizationWizard } from '../components/deals/optimization/DealOptimizationWizard';
import { DealsOverview } from '../components/deals/DealsOverview';
import type { FilterType } from '../types/deals';
import { DealManagementTiles } from '../components/deals/management/tiles/DealManagementTiles';

// Define view modes at the top level
const viewModes = [
  { 
    id: 'overview',
    name: 'Overview',
    icon: ChartBarIcon,
    description: 'Deal performance dashboard and insights'
  },
  { 
    id: 'management',
    name: 'Deal Management',
    icon: TableCellsIcon,
    description: 'Detailed deal management and analysis'
  },
  { 
    id: 'optimization',
    name: 'Health & Optimization',
    icon: SparklesIcon,
    description: 'Deal health monitoring and optimization'
  }
];

// Quick filters for health & optimization view
const quickFilters = [
  {
    id: 'needs-attention',
    name: 'Needs Attention',
    description: 'Deals with critical health issues',
    count: 12,
    severity: 'red'
  },
  {
    id: 'optimization-ready',
    name: 'Ready for Optimization',
    description: 'Deals eligible for performance improvements',
    count: 28,
    severity: 'yellow'
  },
  {
    id: 'no-bids',
    name: 'No Bid Response',
    description: 'Deals receiving no bid responses',
    count: 8,
    severity: 'red'
  },
  {
    id: 'low-win-rate',
    name: 'Low Win Rate',
    description: 'Win rate below target threshold',
    count: 15,
    severity: 'yellow'
  }
];

export default function Deals() {
  const [activeView, setActiveView] = useState('overview');
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [showHealthWizard, setShowHealthWizard] = useState(false);
  const [showOptimizationWizard, setShowOptimizationWizard] = useState(false);
  const [selectedDealId, setSelectedDealId] = useState<string | null>(null);
  const [selectedQuickFilter, setSelectedQuickFilter] = useState<string | null>(null);

  const handleQuickFilterSelect = (filterId: string) => {
    setSelectedQuickFilter(filterId === selectedQuickFilter ? null : filterId);
  };

  return (
    <div className="min-h-screen bg-gray-50 pb-12">
      <div className="max-w-[1920px] mx-auto p-4 sm:p-6 lg:p-8">
        {/* Header */}
        <div className="mb-8">
          <div className="flex items-center justify-between">
            <div>
              <h1 className="text-2xl font-semibold text-gray-900">Deals</h1>
              <p className="mt-1 text-gray-500">
                Manage and optimize your programmatic deals
              </p>
            </div>
            <div className="flex space-x-4">
              {activeView === 'optimization' ? (
                <>
                  <button
                    type="button"
                    className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={() => setShowHealthWizard(true)}
                  >
                    <ShieldCheckIcon className="h-5 w-5 mr-2" />
                    Check Health
                  </button>
                  <button
                    type="button"
                    className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500"
                    onClick={() => setShowOptimizationWizard(true)}
                  >
                    <SparklesIcon className="h-5 w-5 mr-2" />
                    Optimize Deal
                  </button>
                </>
              ) : (
                <button
                  type="button"
                  className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500"
                  onClick={() => {/* Handle create deal */}}
                >
                  <PlusIcon className="h-5 w-5 mr-2" />
                  Create Deal
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Main Content */}
        <Tab.Group>
          {/* View Selector */}
          <Tab.List className="flex space-x-1 rounded-xl bg-gray-100 p-1 mb-6">
            {viewModes.map((mode) => (
              <Tab
                key={mode.id}
                className={({ selected }) =>
                  `flex items-center space-x-2 rounded-lg py-2.5 px-4 text-sm font-medium leading-5
                  ${selected
                    ? 'bg-white text-blue-700 shadow'
                    : 'text-gray-600 hover:text-blue-600'
                  }`
                }
                onClick={() => setActiveView(mode.id)}
              >
                <mode.icon className="h-5 w-5" />
                <span>{mode.name}</span>
              </Tab>
            ))}
          </Tab.List>

          {/* Content Panels */}
          <Tab.Panels>
            {/* Overview Panel */}
            <Tab.Panel>
              <DealsOverview />
            </Tab.Panel>


            {/* Deal Management Panel */}
            <Tab.Panel>
              <div className="space-y-6">
                <Card>
                <DealsTable 
                  onHealthCheck={(dealId: string) => {/* Do nothing */}} 
                  onOptimize={(dealId: string) => {/* Do nothing */}}
                />
                </Card>
              </div>
            </Tab.Panel>

            {/* Health & Optimization Panel */}
            <Tab.Panel>
              <div className="space-y-6">
                <Card>
                  {/* Quick filters for health and optimization */}
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
                    {quickFilters.map((filter) => (
                      <button
                        key={filter.id}
                        onClick={() => handleQuickFilterSelect(filter.id)}
                        className="p-4 rounded-lg border transition-all text-left"
                      >
                        <div className="flex items-start justify-between mb-2">
                          <Text className="font-medium">{filter.name}</Text>
                          <Badge color={filter.severity}>
                            {filter.count}
                          </Badge>
                        </div>
                        <Text className="text-sm text-gray-500">
                          {filter.description}
                        </Text>
                      </button>
                    ))}
                  </div>

              {/* Health & Optimization Table */}
              <DealList
                mode="optimization"
                onHealthCheck={(dealId: string) => {
                  setSelectedDealId(dealId);
                  setShowHealthWizard(true);
                }}
                onOptimize={(dealId: string) => {
                  setSelectedDealId(dealId);
                  setShowOptimizationWizard(true);
                }}
              />
            </Card>
          </div>
        </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>

        {/* Wizards */}
        {showHealthWizard && (
          <DealHealthWizard
            dealId={selectedDealId}
            onClose={() => {
              setShowHealthWizard(false);
              setSelectedDealId(null);
            }}
          />
        )}

        {showOptimizationWizard && (
          <DealOptimizationWizard
            dealId={selectedDealId}
            onClose={() => {
              setShowOptimizationWizard(false);
              setSelectedDealId(null);
            }}
          />
        )}

        {/* Filters */}
        <DealFilters
          open={isFiltersOpen}
          onClose={() => setIsFiltersOpen(false)}
        />
      </div>
    </div>
  );
}