// src/components/discovery/SmartSearchBar.tsx

import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { MagnifyingGlassIcon, SparklesIcon, PlusIcon } from '@heroicons/react/24/outline';
import type { SmartDiscoveryQuery } from '../../types/discovery';

interface SearchSuggestion {
  id: string;
  type: 'create_deal' | 'query' | 'filter' | 'recommendation';
  text: string;
  description?: string;
  icon?: typeof SparklesIcon;
  metadata?: {
    dealType?: string;
    audience?: string;
    geography?: string[];
    category?: string;
  };
}

interface SmartSearchBarProps {
  onQueryChange: (query: SmartDiscoveryQuery) => void;
  onCreateDeal?: (suggestion: SearchSuggestion) => void;
  placeholder?: string;
}

const mockSuggestions: SearchSuggestion[] = [
  {
    id: 'create-auto-deal',
    type: 'create_deal',
    text: 'Create Premium Auto Campaign',
    description: 'PMP deal targeting auto intenders in top DMAs',
    icon: PlusIcon,
    metadata: {
      dealType: 'PMP',
      audience: 'Auto Intenders',
      geography: ['New York', 'Los Angeles', 'Chicago'],
      category: 'Automotive'
    }
  },
  {
    id: 'create-retail',
    type: 'create_deal',
    text: 'Create Retail Prospecting Campaign',
    description: 'Performance campaign for retail customer acquisition',
    icon: PlusIcon,
    metadata: {
      dealType: 'Preferred',
      audience: 'Shopping Intenders',
      category: 'Retail'
    }
  },
  {
    id: 'high-viewability',
    type: 'query',
    text: 'Find high viewability inventory',
    description: 'Inventory with >80% viewability',
    icon: MagnifyingGlassIcon
  },
  {
    id: 'optimization',
    type: 'recommendation',
    text: 'View optimization opportunities',
    description: 'AI-detected opportunities to improve performance',
    icon: SparklesIcon
  }
];

export function SmartSearchBar({ onQueryChange, onCreateDeal, placeholder }: SmartSearchBarProps) {
  const [searchText, setSearchText] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState<SearchSuggestion[]>(mockSuggestions);
  const [selectedSuggestion, setSelectedSuggestion] = useState<number>(-1);

  useEffect(() => {
    if (searchText.length > 0) {
      const filtered = mockSuggestions.filter(suggestion =>
        suggestion.text.toLowerCase().includes(searchText.toLowerCase()) ||
        suggestion.description?.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredSuggestions(filtered);
      setShowSuggestions(true);
    } else {
      setFilteredSuggestions(mockSuggestions);
      setShowSuggestions(isFocused);
    }
  }, [searchText, isFocused]);

  const handleSearch = (value: string) => {
    setSearchText(value);
    const query: SmartDiscoveryQuery = {
      type: 'deal',
      keywords: value.toLowerCase().split(' ').filter(k => k.length > 0),
      constraints: {},
      preferences: {
        prioritize: ['performance']
      }
    };
    onQueryChange(query);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setSelectedSuggestion(prev => 
        prev < filteredSuggestions.length - 1 ? prev + 1 : prev
      );
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setSelectedSuggestion(prev => prev > 0 ? prev - 1 : -1);
    } else if (e.key === 'Enter' && selectedSuggestion >= 0) {
      e.preventDefault();
      const selected = filteredSuggestions[selectedSuggestion];
      if (selected.type === 'create_deal' && onCreateDeal) {
        onCreateDeal(selected);
      } else {
        handleSearch(selected.text);
      }
      setShowSuggestions(false);
    }
  };

  return (
    <div className="relative z-50">
      <div className={`
        relative rounded-lg border-2 transition-all
        ${isFocused ? 'border-blue-500 shadow-lg' : 'border-gray-200 shadow-sm'}
        bg-white
      `}>
        <input
          type="text"
          className="w-full px-4 py-4 pl-12 pr-12 
                   text-gray-900 placeholder:text-gray-500
                   bg-transparent rounded-lg
                   focus:outline-none text-lg"
          placeholder={placeholder || "Search or create new deals, audiences..."}
          value={searchText}
          onChange={(e) => handleSearch(e.target.value)}
          onFocus={() => {
            setIsFocused(true);
            setShowSuggestions(true);
          }}
          onBlur={() => {
            setIsFocused(false);
            // Delay hiding suggestions to allow for clicks
            setTimeout(() => setShowSuggestions(false), 200);
          }}
          onKeyDown={handleKeyDown}
        />
        <MagnifyingGlassIcon 
          className="absolute left-4 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" 
        />
        <SparklesIcon 
          className="absolute right-4 top-1/2 -translate-y-1/2 h-5 w-5 text-blue-500" 
        />
      </div>

      <AnimatePresence>
        {showSuggestions && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="absolute w-full mt-2 bg-white rounded-lg shadow-lg border border-gray-200"
          >
            <div className="py-2">
              {filteredSuggestions.map((suggestion, index) => {
                const Icon = suggestion.icon || SparklesIcon;
                return (
                  <motion.div
                    key={suggestion.id}
                    className={`
                      px-4 py-3 cursor-pointer
                      ${index === selectedSuggestion ? 'bg-blue-50' : 'hover:bg-gray-50'}
                    `}
                    onClick={() => {
                      if (suggestion.type === 'create_deal' && onCreateDeal) {
                        onCreateDeal(suggestion);
                      } else {
                        handleSearch(suggestion.text);
                      }
                      setShowSuggestions(false);
                    }}
                    whileHover={{ x: 4 }}
                  >
                    <div className="flex items-start space-x-3">
                      <div className={`
                        flex-shrink-0 w-8 h-8 rounded-lg flex items-center justify-center
                        ${suggestion.type === 'create_deal' ? 'bg-blue-100 text-blue-600' :
                          suggestion.type === 'recommendation' ? 'bg-purple-100 text-purple-600' :
                          'bg-gray-100 text-gray-600'}
                      `}>
                        <Icon className="h-5 w-5" />
                      </div>
                      <div className="flex-1">
                        <p className="text-sm font-medium text-gray-900">
                          {suggestion.text}
                        </p>
                        {suggestion.description && (
                          <p className="text-xs text-gray-500 mt-0.5">
                            {suggestion.description}
                          </p>
                        )}
                      </div>
                    </div>
                  </motion.div>
                );
              })}
            </div>
            <div className="px-4 py-2 border-t border-gray-100">
              <p className="text-xs text-gray-500">
                Press ↑↓ to navigate, Enter to select
              </p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}