// src/components/deals/steps/ObjectivesStep.tsx

import { useState } from 'react';
import { Card, Title, Text, Badge, Select, SelectItem, ProgressBar } from '@tremor/react';
import { 
  RocketLaunchIcon, 
  ChartBarIcon, 
  HandRaisedIcon, 
  CurrencyDollarIcon 
} from '@heroicons/react/24/outline';
import { Button } from '../../ui/Button';
import type { 
    DealConfiguration,
    StepComponentProps,
    ValidationMessage 
  } from '../../../types/dealTypes';

const CAMPAIGN_OBJECTIVES = [
  {
    id: 'awareness',
    name: 'Brand Awareness',
    description: 'Maximize reach and visibility among target audience',
    icon: RocketLaunchIcon,
    kpis: ['impressions', 'reach', 'viewability'],
    recommendedFormats: ['Display', 'Video', 'Native'],
    benchmarks: {
      viewability: 70,
      completion: 65,
      ctr: 0.08
    }
  },
  {
    id: 'consideration',
    name: 'Brand Consideration',
    description: 'Drive engagement and interest in your brand',
    icon: HandRaisedIcon,
    kpis: ['engagement_rate', 'video_completion', 'time_spent'],
    recommendedFormats: ['Rich Media', 'Interactive Video', 'Native'],
    benchmarks: {
      viewability: 75,
      completion: 72,
      engagement: 3.5
    }
  },
  {
    id: 'conversion',
    name: 'Performance & Conversion',
    description: 'Drive specific actions and conversions',
    icon: CurrencyDollarIcon,
    kpis: ['conversions', 'cpa', 'roas'],
    recommendedFormats: ['Display', 'Native', 'Dynamic'],
    benchmarks: {
      ctr: 0.12,
      conversion_rate: 2.1,
      cpa: 45
    }
  }
];

interface ObjectivesStepProps {
  configuration: DealConfiguration;
  onUpdate: (updates: Partial<DealConfiguration>) => void;
}

export function ObjectivesStep({ 
    configuration,
    onUpdate,
    onNext,
    onBack,
    onLaunch
  }: StepComponentProps) {
  const [selectedObjective, setSelectedObjective] = useState<string | null>(
    configuration.basic.objectives[0] || null
  );

  const objective = CAMPAIGN_OBJECTIVES.find(o => o.id === selectedObjective);

  
  return (
    <div className="space-y-6">
      {/* Primary Objective Selection */}
      <div>
        <Title>Campaign Objective</Title>
        <Text className="text-gray-500">
          Select your primary campaign goal to get optimized recommendations
        </Text>
        
        <div className="mt-4 grid grid-cols-3 gap-4">
          {CAMPAIGN_OBJECTIVES.map((obj) => {
            const Icon = obj.icon;
            const isSelected = selectedObjective === obj.id;
            
            return (
              <Card 
                key={obj.id}
                className={`cursor-pointer transition-all duration-200 ${
                  isSelected ? 'ring-2 ring-blue-500 bg-blue-50' : 'hover:bg-gray-50'
                }`}
                onClick={() => {
                  setSelectedObjective(obj.id);
                  onUpdate({
                    basic: {
                      ...configuration.basic,
                      objectives: [obj.id]
                    }
                  });
                }}
              >
                <div className="flex items-start space-x-3">
                  <div className={`
                    p-2 rounded-lg 
                    ${isSelected ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-600'}
                  `}>
                    <Icon className="h-6 w-6" />
                  </div>
                  <div>
                    <Text className="font-medium">{obj.name}</Text>
                    <Text className="text-sm text-gray-500 mt-1">
                      {obj.description}
                    </Text>
                  </div>
                </div>

                {isSelected && (
                  <div className="mt-4 space-y-3">
                    <div>
                      <Text className="text-sm font-medium">Recommended KPIs</Text>
                      <div className="flex flex-wrap gap-2 mt-2">
                        {obj.kpis.map(kpi => (
                          <Badge key={kpi} color="blue">
                            {kpi.replace('_', ' ').toUpperCase()}
                          </Badge>
                        ))}
                      </div>
                    </div>
                    <div>
                      <Text className="text-sm font-medium">Format Recommendations</Text>
                      <div className="flex flex-wrap gap-2 mt-2">
                        {obj.recommendedFormats.map(format => (
                          <Badge key={format} color="gray">
                            {format}
                          </Badge>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </Card>
            );
          })}
        </div>
      </div>

      {/* Campaign Details */}
      {selectedObjective && (
        <Card>
          <Title>Campaign Details</Title>
          <div className="mt-4 space-y-4">
            {/* Basic Information */}
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Campaign Name
                </label>
                <input
                  type="text"
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                  value={configuration.basic.name}
                  onChange={(e) => onUpdate({
                    basic: {
                      ...configuration.basic,
                      name: e.target.value
                    }
                  })}
                  placeholder="Enter campaign name"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Advertiser
                </label>
                <input
                  type="text"
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                  value={configuration.basic.advertiser}
                  onChange={(e) => onUpdate({
                    basic: {
                      ...configuration.basic,
                      advertiser: e.target.value
                    }
                  })}
                  placeholder="Select advertiser"
                />
              </div>
            </div>

            {/* Timeline */}
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Start Date
                </label>
                <input
                  type="date"
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                  value={configuration.basic.start_date}
                  onChange={(e) => onUpdate({
                    basic: {
                      ...configuration.basic,
                      start_date: e.target.value
                    }
                  })}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  End Date
                </label>
                <input
                  type="date"
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                  value={configuration.basic.end_date}
                  onChange={(e) => onUpdate({
                    basic: {
                      ...configuration.basic,
                      end_date: e.target.value
                    }
                  })}
                />
              </div>
            </div>

            {/* Deal Type */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Deal Type
              </label>
              <Select 
  value={configuration.basic.type}
  onValueChange={(value) => {
    if (value === 'PMP' || value === 'Preferred' || value === 'Programmatic Guaranteed') {
      onUpdate({
        basic: {
          ...configuration.basic,
          type: value
        }
      });
    }
  }}
>
  <SelectItem value="PMP">Private Marketplace (PMP)</SelectItem>
  <SelectItem value="Preferred">Preferred Deal</SelectItem>
  <SelectItem value="Programmatic Guaranteed">Programmatic Guaranteed</SelectItem>
</Select>
            </div>

            {/* Initial Budget Range */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Budget Range
              </label>
              <div className="mt-1 grid grid-cols-2 gap-4">
                <input
                  type="number"
                  className="block w-full rounded-md border border-gray-300 px-3 py-2"
                  placeholder="Minimum budget"
                  onChange={(e) => onUpdate({
                    budget: {
                      ...configuration.budget,
                      total: Number(e.target.value)
                    }
                  })}
                />
                <input
                  type="number"
                  className="block w-full rounded-md border border-gray-300 px-3 py-2"
                  placeholder="Maximum budget"
                />
              </div>
            </div>
          </div>
        </Card>
      )}

      {/* Performance Benchmarks */}
      {objective && (
        <Card>
          <Title>Performance Benchmarks</Title>
          <Text className="text-gray-500">
            Industry benchmarks for your selected objective
          </Text>

          <div className="mt-4 grid grid-cols-3 gap-4">
            {Object.entries(objective.benchmarks).map(([key, value]) => (
              <div key={key} className="space-y-2">
                <Text className="text-sm text-gray-500 capitalize">
                  {key.replace('_', ' ')}
                </Text>
                <div>
                  <Text className="text-lg font-medium">
                    {typeof value === 'number' ? `${value}%` : value}
                  </Text>
                  <ProgressBar value={typeof value === 'number' ? value : 0} color="blue" />
                </div>
              </div>
            ))}
          </div>
        </Card>
      )}
    </div>
  );
}