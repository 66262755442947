// src/pages/Discovery.tsx

import { FC, useState } from 'react';
import { Tab } from '@headlessui/react';
import {
  UsersIcon,
  GlobeAltIcon,
  ChartPieIcon,
  AdjustmentsHorizontalIcon,
  MagnifyingGlassIcon,
  ClockIcon,
  BoltIcon,
} from '@heroicons/react/24/outline';
import { Card } from '../components/ui/Card';
import { Button } from '../components/ui/Button';
import { DealsView } from '../components/discovery/DealsView';
import { AudienceView } from '../components/discovery/AudienceView';
import { DomainBundleView } from '../components/discovery/DomainBundleView'; 
import { DomainPackager } from '../components/discovery/DomainPackager';
import { QuickActions } from '../components/discovery/QuickActions';
import { RecentSearches } from '../components/discovery/RecentSearches';
import { SmartRecommendations } from '../components/discovery/SmartRecommendations';
import { SmartSearchBar } from '../components/discovery/SmartSearchBar';
import type { SmartDiscoveryQuery } from '../types/discovery';
import { EnhancedSmartSearch } from '../components/discovery/EnhancedSmartSearch';
import { SmartDealCreator } from '../components/deals/SmartDealCreator';
import type { DealIntent } from '../types/deals';



const tabs = [
  {
    name: 'Deals',
    icon: ChartPieIcon,
    description: 'Explore and manage programmatic deals',
  },
  {
    name: 'Audiences',
    icon: UsersIcon,
    description: 'Discover and analyze audience segments',
  },
  {
    name: 'Domains',
    icon: GlobeAltIcon,
    description: 'Browse and package premium domains',
  },
];

const Discovery: FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [currentQuery, setCurrentQuery] = useState<SmartDiscoveryQuery | null>(null);
  const [showDealCreator, setShowDealCreator] = useState(false);
  const [currentIntent, setCurrentIntent] = useState<DealIntent | null>(null);


  const handleSearch = (query: SmartDiscoveryQuery) => {
    // Check for deal creation intent
    if (query.keywords.some(k => k.toLowerCase().includes('create'))) {
      setCurrentIntent({
        type: 'create_deal',
        parameters: {
          // Parse parameters from query
        }
      });
      setShowDealCreator(true);
    }
    setCurrentQuery(query);
  };

  const renderTabPanel = (Component: FC<{ query: SmartDiscoveryQuery | null }>) => (
    <div className="h-full">
      <Component query={currentQuery} />
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-[1920px] mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Header with Smart Search */}
        <div className="mb-8">
          <div className="flex items-center justify-between mb-4">
            <div>
              <h1 className="text-2xl font-semibold text-gray-900">Discovery</h1>
              <p className="mt-1 text-sm text-gray-500">
                Explore and analyze inventory, audiences, and deals across the platform
              </p>
            </div>
            <div className="flex items-center space-x-4">
              <Button variant="secondary" size="sm">
                <ClockIcon className="h-5 w-5 mr-2" />
                History
              </Button>
              <Button variant="secondary" size="sm">
                <BoltIcon className="h-5 w-5 mr-2" />
                Quick Actions
              </Button>
            </div>
          </div>

          <SmartSearchBar
        onQueryChange={handleSearch}
        placeholder="Try 'Create a PMP deal for auto intenders...'"
      />

{/* <SmartDealCreator
        isOpen={showDealCreator}
        onClose={() => setShowDealCreator(false)}
        intent={currentIntent || undefined}
      /> */}
        </div>

        {/* Main Content Container */}
        <div className="flex flex-col lg:flex-row gap-6">
          {/* Left Side - Main Content */}
          <div className="flex-1 overflow-hidden">
            <div className="bg-white rounded-lg shadow">
              <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
                <div className="border-b border-gray-200 px-4 py-3">
                  <div className="flex items-center justify-between">
                    <Tab.List className="flex space-x-1 rounded-xl bg-gray-100 p-1">
                      {tabs.map((tab) => (
                        <Tab
                          key={tab.name}
                          className={({ selected }) =>
                            `flex items-center space-x-2 rounded-lg py-2.5 px-4 text-sm font-medium leading-5 
                            ${selected 
                              ? 'bg-white text-blue-700 shadow'
                              : 'text-gray-600 hover:text-blue-600'
                            }`
                          }
                        >
                          <tab.icon className="h-5 w-5" />
                          <span>{tab.name}</span>
                        </Tab>
                      ))}
                    </Tab.List>

                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={() => setIsFiltersOpen(true)}
                    >
                      <AdjustmentsHorizontalIcon className="h-5 w-5 mr-2" />
                      Filters
                    </Button>
                  </div>
                </div>

                <div className="p-4">
                <Tab.Panels>
                <Tab.Panel>
                    {renderTabPanel(DealsView)}
                </Tab.Panel>
                <Tab.Panel>
                    {renderTabPanel(AudienceView)}
                </Tab.Panel>
                <Tab.Panel>
                    {renderTabPanel(DomainBundleView)}
                </Tab.Panel>
                </Tab.Panels>
                </div>
              </Tab.Group>
            </div>
          </div>

          {/* Right Side - Sidebar */}
          <div className="lg:w-80 flex-shrink-0">
            <div className="space-y-6 sticky top-8">
              <QuickActions />
              <RecentSearches />
              <SmartRecommendations />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Discovery;