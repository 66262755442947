// src/components/ui/calendar.tsx
import { useState } from 'react';

interface CalendarProps {
  mode: 'single';
  selected?: Date;
  onSelect: (date: Date) => void;
  className?: string;
}

export function Calendar({ mode, selected, onSelect, className }: CalendarProps) {
  const today = new Date();
  const [viewDate, setViewDate] = useState(selected || today);
  
  const startOfMonth = new Date(viewDate.getFullYear(), viewDate.getMonth(), 1);
  const endOfMonth = new Date(viewDate.getFullYear(), viewDate.getMonth() + 1, 0);
  
  const days = Array.from({ length: endOfMonth.getDate() }, (_, i) => i + 1);
  const startDay = startOfMonth.getDay();
  
  const handleDateSelect = (day: number) => {
    const selectedDate = new Date(viewDate.getFullYear(), viewDate.getMonth(), day);
    onSelect(selectedDate);
  };

  return (
    <div className={`p-4 bg-white rounded-lg shadow ${className}`}>
      <div className="flex justify-between items-center mb-4">
        <button
          onClick={() => setViewDate(new Date(viewDate.getFullYear(), viewDate.getMonth() - 1))}
          className="p-1 hover:bg-gray-100 rounded"
        >
          ←
        </button>
        <div>
          {viewDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
        </div>
        <button
          onClick={() => setViewDate(new Date(viewDate.getFullYear(), viewDate.getMonth() + 1))}
          className="p-1 hover:bg-gray-100 rounded"
        >
          →
        </button>
      </div>
      
      <div className="grid grid-cols-7 gap-1">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
          <div key={day} className="text-center text-sm text-gray-500 py-1">
            {day}
          </div>
        ))}
        
        {Array.from({ length: startDay }).map((_, i) => (
          <div key={`empty-${i}`} />
        ))}
        
        {days.map(day => {
          const date = new Date(viewDate.getFullYear(), viewDate.getMonth(), day);
          const isSelected = selected?.toDateString() === date.toDateString();
          const isToday = today.toDateString() === date.toDateString();
          
          return (
            <button
              key={day}
              onClick={() => handleDateSelect(day)}
              className={`
                p-2 text-sm rounded-lg
                ${isSelected ? 'bg-blue-600 text-white' : 'hover:bg-gray-100'}
                ${isToday ? 'font-bold' : ''}
              `}
            >
              {day}
            </button>
          );
        })}
      </div>
    </div>
  );
}