import { Card } from '../ui/Card';
import { Title, ProgressBar } from '@tremor/react';
import { ArrowTrendingUpIcon } from '@heroicons/react/24/outline';

interface TrendingTopic {
  id: string;
  name: string;
  category: string;
  growth: number;
  volume: number;
  sentiment: 'positive' | 'neutral' | 'negative';
}

const topics: TrendingTopic[] = [
  {
    id: '1',
    name: 'Electric Vehicles',
    category: 'Automotive',
    growth: 45,
    volume: 85,
    sentiment: 'positive'
  },
  {
    id: '2',
    name: 'Sustainable Fashion',
    category: 'Retail',
    growth: 32,
    volume: 72,
    sentiment: 'positive'
  },
  {
    id: '3',
    name: 'Smart Home',
    category: 'Technology',
    growth: 28,
    volume: 65,
    sentiment: 'neutral'
  },
  {
    id: '4',
    name: 'Streaming Services',
    category: 'Entertainment',
    growth: 25,
    volume: 90,
    sentiment: 'positive'
  }
];

const sentimentColors = {
  positive: 'text-green-700',
  neutral: 'text-yellow-700',
  negative: 'text-red-700'
};

export function TrendingTopics() {
  return (
    <Card>
      <div className="flex items-center justify-between mb-4">
        <div>
          <Title className="text-base font-medium">Trending Topics</Title>
          <p className="text-sm text-gray-500 mt-1">
            Popular themes across your inventory
          </p>
        </div>
        <ArrowTrendingUpIcon className="h-5 w-5 text-gray-400" />
      </div>

      <div className="space-y-6">
        {topics.map((topic) => (
          <div key={topic.id} className="space-y-2">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm font-medium text-gray-900">{topic.name}</p>
                <p className="text-xs text-gray-500">{topic.category}</p>
              </div>
              <div className="flex items-center gap-2">
                <span className={`text-xs font-medium ${sentimentColors[topic.sentiment]}`}>
                  +{topic.growth}%
                </span>
              </div>
            </div>
            <div className="space-y-1">
              <ProgressBar 
                value={topic.volume} 
                color="blue"
                className="h-1.5"
              />
              <div className="flex justify-between text-xs text-gray-500">
                <span>Volume</span>
                <span>{topic.volume}%</span>
              </div>
            </div>
          </div>
        ))}
      </div>

      <button className="w-full mt-6 text-sm text-blue-600 hover:text-blue-700 font-medium">
        View Full Analysis
      </button>
    </Card>
  );
}