import { useState } from 'react';
import {
  Card,
  Title,
  Text,
  Metric,
  Badge,
  AreaChart,
  TabGroup,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  List,
  ListItem
} from '@tremor/react';
import {
  ChartBarIcon,
  SparklesIcon,
  ArrowTrendingUpIcon,
  ExclamationTriangleIcon,
  CheckCircleIcon,
  BuildingOfficeIcon,
  GlobeAltIcon,
  UserGroupIcon,
  RocketLaunchIcon
} from '@heroicons/react/24/outline';
import { Button } from '../ui/Button';

interface DealSummary {
  totalDeals: number;
  activeDeals: number;
  totalRevenue: number;
  avgPerformanceScore: number;
  healthDistribution: {
    healthy: number;
    warning: number;
    critical: number;
  };
  topVerticals: Array<{
    name: string;
    deals: number;
    revenue: number;
    growth: number;
  }>;
  topBrands: Array<{
    name: string;
    deals: number;
    spend: number;
    performance: number;
  }>;
  trendingDeals: Array<{
    id: string;
    name: string;
    metric: string;
    value: number;
    change: number;
  }>;
}

// Mock data for demonstration
const dealSummaryData: DealSummary = {
  totalDeals: 456,
  activeDeals: 328,
  totalRevenue: 2500000,
  avgPerformanceScore: 82,
  healthDistribution: {
    healthy: 65,
    warning: 25,
    critical: 10
  },
  topVerticals: [
    {
      name: 'Automotive',
      deals: 45,
      revenue: 450000,
      growth: 15
    },
    {
      name: 'Finance',
      deals: 38,
      revenue: 380000,
      growth: 12
    },
    {
      name: 'Retail',
      deals: 35,
      revenue: 320000,
      growth: 8
    },
    {
      name: 'Technology',
      deals: 32,
      revenue: 300000,
      growth: 10
    }
  ],
  topBrands: [
    {
      name: 'AutoMax Corp',
      deals: 12,
      spend: 150000,
      performance: 88
    },
    {
      name: 'TechGiant Inc',
      deals: 10,
      spend: 120000,
      performance: 85
    },
    {
      name: 'RetailPro',
      deals: 8,
      spend: 100000,
      performance: 82
    }
  ],
  trendingDeals: [
    {
      id: '1',
      name: 'Premium Auto Campaign',
      metric: 'CTR',
      value: 0.25,
      change: 15
    },
    {
      id: '2',
      name: 'Finance Professional Network',
      metric: 'Viewability',
      value: 92,
      change: 8
    }
  ]
};

const performanceHistory = [
  {
    date: '2024-01',
    'Active Deals': 286,
    'Win Rate': 65,
    'Health Score': 82
  },
  {
    date: '2024-02',
    'Active Deals': 312,
    'Win Rate': 68,
    'Health Score': 85
  },
  {
    date: '2024-03',
    'Active Deals': 328,
    'Win Rate': 71,
    'Health Score': 88
  }
];

export function DealsOverview() {
  const [timeRange, setTimeRange] = useState('7d');

  return (
    <div className="space-y-6">
      {/* Header Section */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">Deals Overview</h1>
          <Text className="text-gray-500">
            Comprehensive view of all programmatic deals
          </Text>
        </div>
        <div className="flex items-center space-x-4">
          <TabGroup>
            <TabList className="flex space-x-1 rounded-lg bg-gray-100 p-1">
              <Tab className="rounded-md px-3 py-1.5 text-sm font-medium ui-selected:bg-white ui-selected:shadow">
                7D
              </Tab>
              <Tab className="rounded-md px-3 py-1.5 text-sm font-medium ui-selected:bg-white ui-selected:shadow">
                30D
              </Tab>
              <Tab className="rounded-md px-3 py-1.5 text-sm font-medium ui-selected:bg-white ui-selected:shadow">
                90D
              </Tab>
            </TabList>
          </TabGroup>
          {/* <Button variant="primary">
            Create Deal
          </Button> */}
        </div>
      </div>

      {/* Key Metrics Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {/* Total Active Deals */}
        <Card className="p-6">
          <div className="flex justify-between items-start">
            <div>
              <Text className="text-gray-600">Active Deals</Text>
              <Metric>{dealSummaryData.activeDeals}</Metric>
            </div>
            <Badge color="green">+5% vs last period</Badge>
          </div>
          <div className="mt-4">
            <Text className="text-sm text-gray-600">Health Distribution</Text>
            <div className="flex space-x-2 mt-2">
              <Badge color="green">
                {dealSummaryData.healthDistribution.healthy}% Healthy
              </Badge>
              <Badge color="yellow">
                {dealSummaryData.healthDistribution.warning}% Warning
              </Badge>
              <Badge color="red">
                {dealSummaryData.healthDistribution.critical}% Critical
              </Badge>
            </div>
          </div>
        </Card>

        {/* Revenue */}
        <Card className="p-6">
          <div className="flex justify-between items-start">
            <div>
              <Text className="text-gray-600">Total Revenue</Text>
              <Metric>${(dealSummaryData.totalRevenue / 1000000).toFixed(2)}M</Metric>
            </div>
            <Badge color="green">+12% vs last period</Badge>
          </div>
          <div className="h-14 mt-4">
            <AreaChart
              className="h-14"
              data={performanceHistory}
              index="date"
              categories={['Active Deals']}
              colors={['blue']}
              showXAxis={false}
              showYAxis={false}
              showLegend={false}
            />
          </div>
        </Card>

        {/* Performance Score */}
        <Card className="p-6">
          <div className="flex justify-between items-start">
            <div>
              <Text className="text-gray-600">Avg Performance Score</Text>
              <Metric>{dealSummaryData.avgPerformanceScore}</Metric>
            </div>
            <Badge color="green">+3 pts vs last period</Badge>
          </div>
          <div className="mt-4">
            <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div 
                className="bg-green-600 h-2.5 rounded-full" 
                style={{ width: `${dealSummaryData.avgPerformanceScore}%` }}
              />
            </div>
          </div>
        </Card>

        {/* Optimization Status */}
        <Card className="p-6">
          <div className="flex justify-between items-start">
            <div>
              <Text className="text-gray-600">Optimization Status</Text>
              <Metric>85%</Metric>
            </div>
            <Badge color="yellow">12 deals need attention</Badge>
          </div>
          <div className="mt-4">
            <Text className="text-sm text-gray-600">Recent Optimizations</Text>
            <div className="flex space-x-2 mt-2">
              <Badge color="blue">28 Last 7 days</Badge>
              <Badge color="green">92% Success rate</Badge>
            </div>
          </div>
        </Card>
      </div>

      {/* Main Content Grid */}
      <div className="grid grid-cols-12 gap-6">
        {/* Left Column - Verticals & Brands */}
        <div className="col-span-12 lg:col-span-8 space-y-6">
          {/* Top Verticals */}
          <Card>
            <div className="p-6">
              <div className="flex items-center justify-between mb-6">
                <Title>Top Performing Verticals</Title>
                <Button variant="secondary" size="sm">
                  View All
                </Button>
              </div>
              <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
                {dealSummaryData.topVerticals.map((vertical) => (
                  <Card key={vertical.name} className="p-4">
                    <Text className="text-gray-600">{vertical.name}</Text>
                    <Metric className="mt-2">
                      ${(vertical.revenue / 1000).toFixed(0)}K
                    </Metric>
                    <div className="mt-4 flex items-center justify-between">
                      <Text className="text-sm text-gray-600">
                        {vertical.deals} deals
                      </Text>
                      <Badge color="green">
                        +{vertical.growth}%
                      </Badge>
                    </div>
                  </Card>
                ))}
              </div>
            </div>
          </Card>

          {/* Top Brands */}
          <Card>
            <div className="p-6">
              <div className="flex items-center justify-between mb-6">
                <Title>Leading Brands</Title>
                <Button variant="secondary" size="sm">
                  View All
                </Button>
              </div>
              <div className="space-y-4">
                {dealSummaryData.topBrands.map((brand) => (
                  <Card key={brand.name} className="p-4">
                    <div className="flex items-center justify-between">
                      <div>
                        <Text className="font-medium">{brand.name}</Text>
                        <div className="flex items-center space-x-2 mt-1">
                          <Text className="text-sm text-gray-600">
                            {brand.deals} active deals
                          </Text>
                          <span className="text-gray-300">•</span>
                          <Text className="text-sm text-gray-600">
                            ${(brand.spend / 1000).toFixed(0)}K spend
                          </Text>
                        </div>
                      </div>
                      <div className="flex items-center space-x-4">
                        <Badge color={brand.performance >= 85 ? 'green' : 'yellow'}>
                          {brand.performance} performance
                        </Badge>
                        <Button variant="secondary" size="sm">
                          View Deals
                        </Button>
                      </div>
                    </div>
                  </Card>
                ))}
              </div>
            </div>
          </Card>
        </div>

        {/* Right Column - Trending & Alerts */}
        <div className="col-span-12 lg:col-span-4 space-y-6">
          {/* Trending Deals */}
          <Card>
            <div className="p-6">
              <Title className="mb-6">Trending Deals</Title>
              <div className="space-y-4">
                {dealSummaryData.trendingDeals.map((deal) => (
                  <Card key={deal.id} className="p-4">
                    <div className="flex items-center justify-between">
                      <div>
                        <Text className="font-medium">{deal.name}</Text>
                        <div className="flex items-center space-x-2 mt-1">
                          <Text className="text-sm text-gray-600">
                            {deal.metric}:
                          </Text>
                          <Badge color="green">
                            {deal.value}% (+{deal.change}%)
                          </Badge>
                        </div>
                      </div>
                      <Button variant="secondary" size="sm">
                        View
                      </Button>
                    </div>
                  </Card>
                ))}
              </div>
            </div>
          </Card>

          {/* Quick Actions */}
          <Card>
            <div className="p-6">
              <Title className="mb-6">Quick Actions Needed</Title>
              <div className="space-y-4">
                <Button 
                  variant="secondary"
                  className="w-full justify-between"
                >
                  <span className="flex items-center">
                    <ExclamationTriangleIcon className="h-5 w-5 mr-2 text-red-500" />
                    Critical Health Issues
                  </span>
                  <Badge color="red">5</Badge>
                </Button>
                <Button 
                  variant="secondary"
                  className="w-full justify-between"
                >
                  <span className="flex items-center">
                    <SparklesIcon className="h-5 w-5 mr-2 text-blue-500" />
                    Ready for Optimization
                  </span>
                  <Badge color="blue">12</Badge>
                </Button>
                <Button 
                  variant="secondary"
                  className="w-full justify-between"
                >
                  <span className="flex items-center">
                    <ArrowTrendingUpIcon className="h-5 w-5 mr-2 text-green-500" />
                    Scale Opportunities
                  </span>
                  <Badge color="green">8</Badge>
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}