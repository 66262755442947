import { Fragment } from 'react';
import { Dialog, Transition, Disclosure } from '@headlessui/react';
import { XMarkIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

interface AudienceFiltersProps {
  open: boolean;
  onClose: () => void;
}

const filters = [
  {
    id: 'type',
    name: 'Audience Type',
    options: [
      { value: 'first-party', label: 'First Party', count: 24 },
      { value: 'third-party', label: 'Third Party', count: 36 },
      { value: 'contextual', label: 'Contextual', count: 18 },
      { value: 'custom', label: 'Custom Built', count: 12 }
    ]
  },
  {
    id: 'reach',
    name: 'Reach Size',
    options: [
      { value: '0-1m', label: '0 - 1M users', count: 32 },
      { value: '1m-5m', label: '1M - 5M users', count: 28 },
      { value: '5m-10m', label: '5M - 10M users', count: 16 },
      { value: '10m+', label: '10M+ users', count: 8 }
    ]
  },
  {
    id: 'performance',
    name: 'Performance',
    options: [
      { value: 'high-ctr', label: 'High CTR (>0.1%)', count: 24 },
      { value: 'high-viewability', label: 'High Viewability (>70%)', count: 28 },
      { value: 'high-conversion', label: 'High Conversion (>2%)', count: 16 }
    ]
  },
  {
    id: 'match-rate',
    name: 'Match Rate',
    options: [
      { value: '90-100', label: '90-100%', count: 16 },
      { value: '80-90', label: '80-90%', count: 24 },
      { value: '70-80', label: '70-80%', count: 18 },
      { value: 'below-70', label: 'Below 70%', count: 12 }
    ]
  }
];

export function AudienceFilters({ open, onClose }: AudienceFiltersProps) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            Filters
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                              onClick={onClose}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        <div className="space-y-6">
                          {filters.map((section) => (
                            <Disclosure as="div" key={section.id} defaultOpen={true}>
                              {({ open }) => (
                                <div>
                                  <Disclosure.Button className="flex w-full items-center justify-between rounded-lg bg-gray-50 px-4 py-2 text-left text-sm font-medium text-gray-900 hover:bg-gray-100">
                                    <span>{section.name}</span>
                                    <ChevronUpIcon
                                      className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-gray-500`}
                                    />
                                  </Disclosure.Button>
                                  <Disclosure.Panel className="px-4 pt-4 pb-2">
                                    <div className="space-y-4">
                                      {section.options.map((option) => (
                                        <div key={option.value} className="flex items-center justify-between">
                                          <div className="flex items-center">
                                            <input
                                              id={`filter-${section.id}-${option.value}`}
                                              name={`${section.id}[]`}
                                              type="checkbox"
                                              className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                            />
                                            <label
                                              htmlFor={`filter-${section.id}-${option.value}`}
                                              className="ml-3 text-sm text-gray-600"
                                            >
                                              {option.label}
                                            </label>
                                          </div>
                                          <span className="text-xs text-gray-500">{option.count}</span>
                                        </div>
                                      ))}
                                    </div>
                                  </Disclosure.Panel>
                                </div>
                              )}
                            </Disclosure>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={onClose}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="ml-4 inline-flex justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                      >
                        Apply Filters
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}