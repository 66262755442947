
// src/components/deals/steps/ReviewStep.tsx

import { useMemo } from 'react';
import { 
  Card, 
  Title, 
  Text, 
  Badge, 
  ProgressBar,
  Metric,
  AreaChart,
  DonutChart 
} from '@tremor/react';
import { 
  CheckCircleIcon,
  ExclamationTriangleIcon,
  SparklesIcon,
  ShieldCheckIcon,
  ChartBarIcon,
  ClockIcon,
  UserGroupIcon,
  GlobeAltIcon,
  CurrencyDollarIcon
} from '@heroicons/react/24/outline';
import { Button } from '../../ui/Button';

import type {
    StepComponentProps,
    DealConfiguration,
  } from '../../../types/dealTypes';

interface ReviewStepProps {
  configuration: DealConfiguration;
  onLaunch: () => void;
  onBack: () => void;
}

interface ValidationResult {
  status: 'success' | 'warning' | 'error';
  message: string;
  details?: string;
}

export function ReviewStep({ 
    configuration,
    onUpdate,
    onNext,
    onBack,
    onLaunch 
  }: StepComponentProps) {
  // Perform validation checks
  const validationResults = useMemo(() => {
    const results: Record<string, ValidationResult> = {
      objectives: {
        status: 'success',
        message: 'Campaign objectives properly defined',
        details: `Primary KPI: ${configuration.goals.primary.kpi}`
      },
      audience: {
        status: configuration.audience.segments.length > 0 ? 'success' : 'error',
        message: configuration.audience.segments.length > 0 
          ? `${configuration.audience.segments.length} segments selected` 
          : 'No audience segments selected'
      },
      budget: {
        status: configuration.budget.total > 0 ? 'success' : 'error',
        message: 'Budget allocation',
        details: `$${configuration.budget.total.toLocaleString()} total budget`
      },
      targeting: {
        status: configuration.audience.targeting.geography.length > 0 ? 'success' : 'warning',
        message: 'Targeting configuration',
        details: configuration.audience.targeting.geography.join(', ')
      },
      inventory: {
        status: configuration.inventory.packages.length > 0 ? 'success' : 'error',
        message: 'Inventory selection',
        details: `${configuration.inventory.packages.length} packages selected`
      }
    };

    return results;
  }, [configuration]);

  const launchReadiness = useMemo(() => {
    const totalChecks = Object.keys(validationResults).length;
    const passedChecks = Object.values(validationResults)
      .filter(r => r.status === 'success').length;
    
    return {
      score: (passedChecks / totalChecks) * 100,
      canLaunch: !Object.values(validationResults)
        .some(r => r.status === 'error')
    };
  }, [validationResults]);

  return (
    <div className="space-y-6">
      {/* Launch Readiness Overview */}
      <Card>
        <div className="flex items-start justify-between">
          <div>
            <Title>Launch Readiness</Title>
            <Text className="text-gray-500">
              Campaign validation and readiness check
            </Text>
          </div>
          <div className="flex items-center space-x-3">
            <div className="text-right">
              <Text className="font-medium">Readiness Score</Text>
              <Text className={`text-2xl font-bold ${
                launchReadiness.score >= 90 ? 'text-green-600' :
                launchReadiness.score >= 70 ? 'text-yellow-600' :
                'text-red-600'
              }`}>
                {launchReadiness.score.toFixed(0)}%
              </Text>
            </div>
            <div className="h-16 w-16 relative">
              <DonutChart
                data={[
                  { name: 'Ready', value: launchReadiness.score },
                  { name: 'Not Ready', value: 100 - launchReadiness.score }
                ]}
                category="value"
                index="name"
                colors={[
                  launchReadiness.score >= 90 ? 'emerald' :
                  launchReadiness.score >= 70 ? 'yellow' :
                  'red',
                  'gray'
                ]}
                showAnimation={true}
                className="h-16 w-16"
              />
              {launchReadiness.canLaunch ? (
                <CheckCircleIcon className="h-6 w-6 text-green-500 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
              ) : (
                <ExclamationTriangleIcon className="h-6 w-6 text-yellow-500 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
              )}
            </div>
          </div>
        </div>

        {/* Validation Checklist */}
        <div className="mt-6 space-y-4">
          {Object.entries(validationResults).map(([key, result]) => (
            <div
              key={key}
              className={`
                flex items-start space-x-3 p-4 rounded-lg
                ${result.status === 'success' ? 'bg-green-50' :
                  result.status === 'warning' ? 'bg-yellow-50' :
                  'bg-red-50'
                }
              `}
            >
              {result.status === 'success' ? (
                <CheckCircleIcon className="h-5 w-5 text-green-500 mt-0.5" />
              ) : result.status === 'warning' ? (
                <ExclamationTriangleIcon className="h-5 w-5 text-yellow-500 mt-0.5" />
              ) : (
                <ExclamationTriangleIcon className="h-5 w-5 text-red-500 mt-0.5" />
              )}
              <div>
                <Text className="font-medium capitalize">{key}</Text>
                <Text className="text-sm text-gray-600">{result.message}</Text>
                {result.details && (
                  <Text className="text-sm text-gray-500 mt-1">
                    {result.details}
                  </Text>
                )}
              </div>
            </div>
          ))}
        </div>
      </Card>

      {/* Campaign Summary */}
      <Card>
        <Title>Campaign Summary</Title>
        <div className="mt-6 grid grid-cols-3 gap-6">
          {/* Basic Information */}
          <div>
            <Text className="font-medium">Basic Information</Text>
            <div className="mt-4 space-y-3">
              <div>
                <Text className="text-sm text-gray-500">Campaign Name</Text>
                <Text className="font-medium">{configuration.basic.name}</Text>
              </div>
              <div>
                <Text className="text-sm text-gray-500">Advertiser</Text>
                <Text className="font-medium">{configuration.basic.advertiser}</Text>
              </div>
              <div>
                <Text className="text-sm text-gray-500">Deal Type</Text>
                <Badge color="blue">{configuration.basic.type}</Badge>
              </div>
              <div>
                <Text className="text-sm text-gray-500">Timeline</Text>
                <Text className="font-medium">
                  {new Date(configuration.basic.start_date).toLocaleDateString()} - 
                  {new Date(configuration.basic.end_date).toLocaleDateString()}
                </Text>
              </div>
            </div>
          </div>

          {/* Audience Overview */}
          <div>
            <Text className="font-medium">Audience Overview</Text>
            <div className="mt-4 space-y-3">
              <div>
                <Text className="text-sm text-gray-500">Selected Segments</Text>
                <div className="flex flex-wrap gap-2 mt-1">
                  {configuration.audience.segments.map((segment: any) => (
                    <Badge key={segment.id} color="purple">
                      {segment.name}
                    </Badge>
                  ))}
                </div>
              </div>
              <div>
                <Text className="text-sm text-gray-500">Geographic Targeting</Text>
                <div className="flex flex-wrap gap-2 mt-1">
                  {configuration.audience.targeting.geography.map((geo) => (
                    <Badge key={geo} color="gray">
                      {geo}
                    </Badge>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Budget & Goals */}
          <div>
            <Text className="font-medium">Budget & Goals</Text>
            <div className="mt-4 space-y-3">
              <div>
                <Text className="text-sm text-gray-500">Total Budget</Text>
                <Text className="font-medium">
                  ${configuration.budget.total.toLocaleString()}
                </Text>
              </div>
              <div>
                <Text className="text-sm text-gray-500">Pricing Model</Text>
                <div className="flex items-center space-x-2">
                  <Badge color="blue">{configuration.budget.pricing.model}</Badge>
                  <Text className="font-medium">
                    ${configuration.budget.pricing.rate}
                  </Text>
                </div>
              </div>
              <div>
                <Text className="text-sm text-gray-500">Primary KPI</Text>
                <div className="flex items-center space-x-2">
                  <Text className="font-medium">
                    {configuration.goals.primary.kpi}
                  </Text>
                  <Text>
                    Target: {configuration.goals.primary.target}%
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>

      {/* Performance Predictions */}
      <Card>
        <div className="flex items-center justify-between">
          <div>
            <Title>Performance Predictions</Title>
            <Text className="text-gray-500">
              AI-powered campaign performance forecast
            </Text>
          </div>
          <Badge color="blue" icon={SparklesIcon}>
            92% Confidence
          </Badge>
        </div>

        <div className="mt-6 grid grid-cols-2 gap-6">
          {/* Delivery Forecast */}
          <div>
            <Text className="font-medium">Delivery Forecast</Text>
            <AreaChart
              className="h-64 mt-4"
              data={[
                { day: 1, delivery: 3.2, benchmark: 3.0 },
                { day: 2, delivery: 3.4, benchmark: 3.0 },
                // Add more forecast data...
              ]}
              index="day"
              categories={["delivery", "benchmark"]}
              colors={["blue", "gray"]}
            />
          </div>

          {/* Performance Metrics */}
          <div>
            <Text className="font-medium">Key Metrics Forecast</Text>
            <div className="mt-4 space-y-4">
              {[
                { 
                  metric: 'Viewability',
                  forecast: 82,
                  benchmark: 70,
                  trend: 'up'
                },
                {
                  metric: 'CTR',
                  forecast: 0.15,
                  benchmark: 0.12,
                  trend: 'up'
                },
                {
                  metric: 'Completion Rate',
                  forecast: 75,
                  benchmark: 65,
                  trend: 'up'
                }
              ].map((metric) => (
                <div key={metric.metric}>
                  <div className="flex justify-between mb-1">
                    <Text className="text-sm">{metric.metric}</Text>
                    <div className="flex items-center space-x-2">
                      <Text className="font-medium">
                        {typeof metric.forecast === 'number' 
                          ? metric.forecast < 1 
                            ? `${(metric.forecast * 100).toFixed(2)}%`
                            : `${metric.forecast}%`
                          : metric.forecast}
                      </Text>
                      <Badge color={metric.trend === 'up' ? 'green' : 'red'}>
                        vs. {typeof metric.benchmark === 'number'
                          ? metric.benchmark < 1
                            ? `${(metric.benchmark * 100).toFixed(2)}%`
                            : `${metric.benchmark}%`
                          : metric.benchmark} benchmark
                      </Badge>
                    </div>
                  </div>
                  <ProgressBar
                    value={typeof metric.forecast === 'number' ? metric.forecast : 0}
                    color="blue"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Card>

      {/* Action Buttons */}
      <div className="flex justify-between pt-6 border-t">
        <Button
          variant="secondary"
          onClick={onBack}
        >
          Back to Edit
        </Button>
        <Button
          variant="primary"
          onClick={onLaunch}
          disabled={!launchReadiness.canLaunch}
        >
          {launchReadiness.canLaunch 
            ? 'Launch Campaign'
            : 'Review Required'}
        </Button>
      </div>
    </div>
  );
}
