import { useState } from 'react';
import { Tab } from '@headlessui/react';
import {
  AdjustmentsHorizontalIcon,
  CalendarDaysIcon,
} from '@heroicons/react/24/outline';
import { Card } from '../components/ui/Card';
import { PerformanceMetrics } from '../components/performance/PerformanceMetrics';
import { CampaignPerformance } from '../components/performance/CampaignPerformance';
import { AudienceInsights } from '../components/performance/AudienceInsights';
import { TrendAnalysis } from '../components/performance/TrendAnalysis';
import { DateRangePicker } from '../components/performance/DateRangePicker';

const performanceViews = [
  { name: 'Overview', id: 'overview' },
  { name: 'Campaigns', id: 'campaigns' },
  { name: 'Audiences', id: 'audiences' },
  { name: 'Trends', id: 'trends' }
];

export default function Performance() {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [dateRange, setDateRange] = useState('Last 7 days');

  return (
    <div className="min-h-screen bg-gray-50 pb-12">
      <div className="max-w-[1920px] mx-auto p-4 sm:p-6 lg:p-8">
        {/* Header */}
        <div className="mb-8">
          <div className="flex items-center justify-between">
            <div>
              <h1 className="text-2xl font-semibold text-gray-900">Performance Analytics</h1>
              <p className="mt-1 text-sm text-gray-500">
                Track, analyze, and optimize your campaign performance
              </p>
            </div>
            <div className="flex items-center space-x-4">
              <button
                type="button"
                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                onClick={() => setIsDatePickerOpen(true)}
              >
                <CalendarDaysIcon className="h-5 w-5 mr-2 text-gray-400" />
                {dateRange}
              </button>
              <button
                type="button"
                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                <AdjustmentsHorizontalIcon className="h-5 w-5 mr-2 text-gray-400" />
                Filters
              </button>
            </div>
          </div>
        </div>

        {/* Performance Metrics Summary */}
        <PerformanceMetrics />

        {/* Main Content */}
        <div className="mt-8">
          <Card>
            <Tab.Group>
              <div className="border-b border-gray-200">
                <Tab.List className="flex space-x-8 px-4">
                  {performanceViews.map((view) => (
                    <Tab
                      key={view.id}
                      className={({ selected }) =>
                        `py-4 px-1 text-sm font-medium border-b-2 whitespace-nowrap ${
                          selected
                            ? 'text-blue-600 border-blue-600'
                            : 'text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300'
                        }`
                      }
                    >
                      {view.name}
                    </Tab>
                  ))}
                </Tab.List>
              </div>

              <div className="p-4">
                <Tab.Panels>
                  <Tab.Panel>
                    <CampaignPerformance />
                  </Tab.Panel>
                  <Tab.Panel>
                    <CampaignPerformance showDetailed />
                  </Tab.Panel>
                  <Tab.Panel>
                    <AudienceInsights />
                  </Tab.Panel>
                  <Tab.Panel>
                    <TrendAnalysis />
                  </Tab.Panel>
                </Tab.Panels>
              </div>
            </Tab.Group>
          </Card>
        </div>
      </div>

      <DateRangePicker 
        open={isDatePickerOpen}
        onClose={() => setIsDatePickerOpen(false)}
        onSelect={(range) => {
          setDateRange(range);
          setIsDatePickerOpen(false);
        }}
      />
    </div>
  );
}