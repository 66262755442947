import { RouteObject } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import DetailedDashboard from '../pages/DetailedDashboard';
import Discovery from '../pages/Discovery';
import Intelligence from '../pages/Intelligence';
import Deals from '../pages/Deals';
import Audiences from '../pages/Audiences';
import Performance from '../pages/Performance';


import { 
  HomeIcon,
  CursorArrowRaysIcon,
  BoltIcon,
  ChartPieIcon,
  UsersIcon,
  ChartBarSquareIcon,
  SparklesIcon
} from '@heroicons/react/24/outline';


interface NavigationItem {
  name: string;
  path: string;
  icon: typeof HomeIcon;
  element: JSX.Element;
}

export const navigation: NavigationItem[] = [
  {
    name: 'Dashboard',
    path: '/',
    icon: HomeIcon,
    element: <Dashboard />
  },
  // {
  //   name: 'Detailed Dashboard',
  //   path: '/dashboard/detailed',
  //   icon: ChartPieIcon,
  //   element: <DetailedDashboard />
  // },

  {
    name: 'Discovery',
    path: '/discovery',
    icon: CursorArrowRaysIcon,
    element: <Discovery />
  },
  
  {
    name: 'Intelligence',
    path: '/intelligence',
    icon: BoltIcon,
    element: <Intelligence />
  },
  {
    name: 'Performance',
    path: '/performance',
    icon: ChartBarSquareIcon,
    element: <Performance />
  },
  {
    name: 'Deals',
    path: '/deals',
    icon: ChartPieIcon,
    element: <Deals />
  },
  // {
  //   name: 'Deal Optimization',
  //   path: '/deals/optimization',
  //   icon: SparklesIcon,
  //   element: <DealOptimization />
  // },  
  {
    name: 'Audiences',
    path: '/audiences',
    icon: UsersIcon,
    element: <Audiences />
  }
];

export const routes: RouteObject[] = navigation.map(({ path, element }) => ({
  path,
  element,
}));