// src/components/deals/steps/AudienceStep.tsx

import { useState, useMemo } from 'react';
import { 
  Card, 
  Title, 
  Text, 
  Badge, 
  ProgressBar, 
  DonutChart,
  AreaChart,
  Metric 
} from '@tremor/react';
import type {
    StepComponentProps,
    DealConfiguration,
    AudienceSegment,
    AudienceTargeting,
    FrequencyCaps
  } from '../../../types/dealTypes';

import { 
  UserGroupIcon, 
  GlobeAltIcon, 
  DeviceTabletIcon,
  ClockIcon,
  ChartBarIcon,
  SparklesIcon
} from '@heroicons/react/24/outline';
import { Button } from '../../ui/Button';


type DaypartingRecord = Record<`${number}-${number}`, boolean>;

  

  
// Mock data for audience segments
const AVAILABLE_SEGMENTS: AudienceSegment[] = [
  {
    id: 'auto-int-1',
    name: 'Auto Intenders',
    type: 'first_party',
    size: 2500000,
    coverage: 85,
    performance: {
      viewability: 82,
      ctr: 0.15,
      completion: 78,
      avgCpm: 18.50
    },
    composition: {
      demographics: {
        '25-34': 35,
        '35-44': 28,
        '45-54': 22,
        'Male': 65,
        'Female': 35
      },
      interests: {
        'Automotive': 100,
        'Luxury': 45,
        'Technology': 38
      },
      behaviors: {
        'Car Research': 85,
        'Dealer Visits': 42,
        'Price Comparison': 68
      }
    },
    affinity: 92,
    recency: 1,
    refreshFrequency: 'daily'
  },
  {
    id: 'lux-shop-1',
    name: 'Luxury Shoppers',
    type: 'third_party',
    size: 1800000,
    coverage: 72,
    performance: {
      viewability: 78,
      ctr: 0.12,
      completion: 72,
      avgCpm: 22.00
    },
    composition: {
      demographics: {
        '35-44': 40,
        '45-54': 35,
        '55+': 25,
        'Male': 55,
        'Female': 45
      },
      interests: {
        'Luxury': 100,
        'Travel': 75,
        'Fine Dining': 68
      },
      behaviors: {
        'High-end Purchases': 82,
        'Travel Booking': 65,
        'Investment Activity': 58
      }
    },
    affinity: 88,
    recency: 2,
    refreshFrequency: 'daily'
  }
];

// Geographic targeting options
const GEO_OPTIONS = {
  countries: ['United States', 'Canada', 'United Kingdom', 'Germany', 'France'],
  regions: {
    'United States': ['New York', 'California', 'Texas', 'Florida', 'Illinois'],
    'Canada': ['Ontario', 'Quebec', 'British Columbia'],
    // Add other regions...
  },
  dmas: ['New York', 'Los Angeles', 'Chicago', 'San Francisco', 'Miami']
};

const DEVICE_OPTIONS = [
  { id: 'desktop', name: 'Desktop', icon: DeviceTabletIcon },
  { id: 'mobile', name: 'Mobile', icon: DeviceTabletIcon },
  { id: 'tablet', name: 'Tablet', icon: DeviceTabletIcon },
  { id: 'ctv', name: 'Connected TV', icon: DeviceTabletIcon }
];

interface AudienceStepProps {
  configuration: DealConfiguration;
  onUpdate: (updates: Partial<DealConfiguration>) => void;
}

export function AudienceStep({ 
    configuration,
    onUpdate,
    onNext,
    onBack,
    onLaunch
  }: StepComponentProps) {
  
  const [selectedSegments, setSelectedSegments] = useState<string[]>(
    configuration.audience.segments.map(s => s.id)
  );


  type DayHourKey = `${number}-${number}`;


// Add this helper function
const updateDaypartingHours = (
    current: Record<string, boolean>,
    days: number[],
    hours: number[],
    value: boolean
  ): Record<string, boolean> => {
    const newDayparting = { ...current };
    days.forEach(day => {
      hours.forEach(hour => {
        newDayparting[`${day}-${hour}`] = value;
      });
    });
    return newDayparting;
  };





  const handleSegmentSelection = (segmentId: string) => {
    const segment = AVAILABLE_SEGMENTS.find(s => s.id === segmentId);
    if (!segment) return;
  
    const newSegments = configuration.audience.segments.includes(segment) 
      ? configuration.audience.segments.filter(s => s.id !== segmentId)
      : [...configuration.audience.segments, segment];
  
    onUpdate({
      audience: {
        ...configuration.audience,
        segments: newSegments
      }
    });
  };
  
  
  type DaypartingKey = `${number}-${number}`;

// Add helper function to safely type dayparting operations
const createDaypartingUpdate = (
  current: Record<string, boolean>,
  updates: Record<DaypartingKey, boolean>
): Record<string, boolean> => {
  return {
    ...current,
    ...updates
  };
};


  const [activeTab, setActiveTab] = useState<'segments' | 'targeting'>('segments');



  // Calculate combined reach and metrics
  const combinedMetrics = useMemo(() => {
    if (selectedSegments.length === 0) return null;

    const segments = AVAILABLE_SEGMENTS.filter(s => selectedSegments.includes(s.id));
    return {
      totalReach: segments.reduce((sum, s) => sum + s.size, 0),
      avgViewability: segments.reduce((sum, s) => sum + s.performance.viewability, 0) / segments.length,
      avgCTR: segments.reduce((sum, s) => sum + s.performance.ctr, 0) / segments.length,
      predictedFrequency: 3.2 // This would be calculated based on actual data
    };
  }, [selectedSegments]);

  return (
    <div className="space-y-6">
      {/* Tabs */}
      <div className="flex space-x-4">
        <button
          className={`px-4 py-2 rounded-lg text-sm font-medium ${
            activeTab === 'segments' 
              ? 'bg-blue-100 text-blue-700' 
              : 'text-gray-600 hover:bg-gray-100'
          }`}
          onClick={() => setActiveTab('segments')}
        >
          Audience Segments
        </button>
        <button
          className={`px-4 py-2 rounded-lg text-sm font-medium ${
            activeTab === 'targeting' 
              ? 'bg-blue-100 text-blue-700' 
              : 'text-gray-600 hover:bg-gray-100'
          }`}
          onClick={() => setActiveTab('targeting')}
        >
          Targeting Options
        </button>
      </div>

      {activeTab === 'segments' && (
        <>
          {/* Segment Selection */}
          <Card>
            <Title>Available Segments</Title>
            <Text className="text-gray-500">
              Select audience segments to target
            </Text>

            <div className="mt-4 grid grid-cols-2 gap-4">
              {AVAILABLE_SEGMENTS.map((segment) => (
                <div
                  key={segment.id}
                  className={`
                    rounded-lg border p-4 cursor-pointer transition-all
                    ${selectedSegments.includes(segment.id) 
                      ? 'border-blue-500 bg-blue-50' 
                      : 'border-gray-200 hover:bg-gray-50'
                    }
                  `}
                  onClick={() => {
                    const newSelection = selectedSegments.includes(segment.id)
                      ? selectedSegments.filter(id => id !== segment.id)
                      : [...selectedSegments, segment.id];
                    setSelectedSegments(newSelection);
                    onUpdate({
                      audience: {
                        ...configuration.audience,
                        segments: AVAILABLE_SEGMENTS.filter(s => newSelection.includes(s.id))
                      }
                    });
                  }}
                >
                  <div className="flex items-start justify-between">
                    <div>
                      <Text className="font-medium">{segment.name}</Text>
                      <div className="flex items-center space-x-2 mt-1">
                        <Badge color={
                          segment.type === 'first_party' ? 'blue' :
                          segment.type === 'third_party' ? 'purple' :
                          'green'
                        }>
                          {segment.type.replace('_', ' ')}
                        </Badge>
                        <Text className="text-sm text-gray-500">
                          {(segment.size / 1000000).toFixed(1)}M users
                        </Text>
                      </div>
                    </div>
                    <Badge color="gray">
                      {segment.affinity}% Match
                    </Badge>
                  </div>

                  {/* Performance Metrics */}
                  <div className="mt-4 grid grid-cols-3 gap-4">
                    <div>
                      <Text className="text-xs text-gray-500">Viewability</Text>
                      <div className="mt-1">
                        <Text className="font-medium">
                          {segment.performance.viewability}%
                        </Text>
                        <ProgressBar 
                          value={segment.performance.viewability} 
                          color="blue" 
                        />
                      </div>
                    </div>
                    <div>
                      <Text className="text-xs text-gray-500">CTR</Text>
                      <div className="mt-1">
                        <Text className="font-medium">
                          {(segment.performance.ctr * 100).toFixed(2)}%
                        </Text>
                        <ProgressBar 
                          value={segment.performance.ctr * 100} 
                          color="blue" 
                        />
                      </div>
                    </div>
                    <div>
                      <Text className="text-xs text-gray-500">Coverage</Text>
                      <div className="mt-1">
                        <Text className="font-medium">
                          {segment.coverage}%
                        </Text>
                        <ProgressBar 
                          value={segment.coverage} 
                          color="blue" 
                        />
                      </div>
                    </div>
                  </div>

                  {/* Composition Preview */}
                  {selectedSegments.includes(segment.id) && (
                    <div className="mt-4 pt-4 border-t border-gray-200">
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <Text className="text-sm font-medium">Demographics</Text>
                          <DonutChart
                            data={Object.entries(segment.composition.demographics)
                              .map(([key, value]) => ({
                                name: key,
                                value: value
                              }))}
                            category="value"
                            index="name"
                            valueFormatter={(value) => `${value}%`}
                            className="h-32 mt-2"
                          />
                        </div>
                        <div>
                          <Text className="text-sm font-medium">Interests</Text>
                          <DonutChart
                            data={Object.entries(segment.composition.interests)
                              .map(([key, value]) => ({
                                name: key,
                                value: value
                              }))}
                            category="value"
                            index="name"
                            valueFormatter={(value) => `${value}%`}
                            className="h-32 mt-2"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </Card>

          {/* Combined Metrics */}
          {combinedMetrics && (
            <Card>
              <Title>Combined Audience Metrics</Title>
              <div className="mt-4 grid grid-cols-4 gap-4">
                <div>
                  <Text className="text-gray-500">Total Reach</Text>
                  <Metric>{(combinedMetrics.totalReach / 1000000).toFixed(1)}M</Metric>
                </div>
                <div>
                  <Text className="text-gray-500">Avg. Viewability</Text>
                  <Metric>{combinedMetrics.avgViewability.toFixed(1)}%</Metric>
                </div>
                <div>
                  <Text className="text-gray-500">Avg. CTR</Text>
                  <Metric>{(combinedMetrics.avgCTR * 100).toFixed(2)}%</Metric>
                </div>
                <div>
                  <Text className="text-gray-500">Predicted Frequency</Text>
                  <Metric>{combinedMetrics.predictedFrequency.toFixed(1)}x</Metric>
                </div>
              </div>
            </Card>
          )}
        </>
      )}

      {activeTab === 'targeting' && (
        <>
          {/* Geographic Targeting */}
          <Card>
            <Title>Geographic Targeting</Title>
            <div className="mt-4 space-y-4">
              {/* Countries */}
              <div>
                <Text className="font-medium">Countries</Text>
                <div className="mt-2 flex flex-wrap gap-2">
                  {GEO_OPTIONS.countries.map(country => (
                    <Badge
                      key={country}
                      color={
                        configuration.audience.targeting.geography.includes(country)
                          ? 'blue'
                          : 'gray'
                      }
                      className="cursor-pointer"
                      onClick={() => {
                        const newGeo = configuration.audience.targeting.geography
                          .includes(country)
                            ? configuration.audience.targeting.geography
                                .filter(g => g !== country)
                            : [...configuration.audience.targeting.geography, country];
                        onUpdate({
                          audience: {
                            ...configuration.audience,
                            targeting: {
                              ...configuration.audience.targeting,
                              geography: newGeo
                            }
                          }
                        });
                      }}
                    >
                      {country}
                    </Badge>
                  ))}
                </div>
              </div>

              {/* DMAs */}
              <div>
                <Text className="font-medium">Major DMAs</Text>
                <div className="mt-2 flex flex-wrap gap-2">
                  {GEO_OPTIONS.dmas.map(dma => (
                    <Badge
                      key={dma}
                      color="gray"
                      className="cursor-pointer"
                    >
                      {dma}
                    </Badge>
                  ))}
                </div>
              </div>
            </div>
          </Card>

          {/* Device Targeting */}
          <Card>
            <Title>Device Targeting</Title>
            <div className="mt-4 grid grid-cols-4 gap-4">
              {DEVICE_OPTIONS.map(device => {
                const Icon = device.icon;
                const isSelected = configuration.audience.targeting.devices
                  .includes(device.id);

                return (
                  <div
                    key={device.id}
                    className={`
                      p-4 rounded-lg border cursor-pointer transition-all
                      ${isSelected 
                        ? 'border-blue-500 bg-blue-50' 
                        : 'border-gray-200 hover:bg-gray-50'
                      }
                    `}
                    onClick={() => {
                      const newDevices = isSelected
                        ? configuration.audience.targeting.devices
                            .filter(d => d !== device.id)
                        : [...configuration.audience.targeting.devices, device.id];
                      onUpdate({
                        audience: {
                          ...configuration.audience,
                          targeting: {
                            ...configuration.audience.targeting,
                            devices: newDevices
                          }
                        }
                      });
                    }}
                  >
                    <div className="text-center">
                      <Icon className={`
                        h-8 w-8 mx-auto
                        ${isSelected ? 'text-blue-600' : 'text-gray-400'}
                      `} />
                      <Text className="mt-2 font-medium">{device.name}</Text>
                    </div>
                  </div>
                );
              })}
            </div>
          </Card>

          {/* Time Targeting */}
          <Card>
            <Title>Time Targeting</Title>
            <div className="mt-4 space-y-6">
              {/* Day Parting */}
              <div>
                <Text className="font-medium">Day Parting</Text>
                <div className="mt-4 grid grid-cols-8 gap-2">
                  {Array.from({ length: 24 }, (_, hour) => (
                    Array.from({ length: 7 }, (_, day) => (
                      <button
                        key={`${day}-${hour}`}
                        className={`
                          w-full h-8 rounded-sm transition-colors
                          ${configuration.audience.targeting.dayparting?.[`${day}-${hour}`]
                            ? 'bg-blue-500'
                            : 'bg-gray-100 hover:bg-blue-100'}
                        `}
                        onClick={() => {
                          const newDayparting = {
                            ...configuration.audience.targeting.dayparting,
                            [`${day}-${hour}`]: !configuration.audience.targeting.dayparting?.[`${day}-${hour}`]
                          };
                          onUpdate({
                            audience: {
                              ...configuration.audience,
                              targeting: {
                                ...configuration.audience.targeting,
                                dayparting: newDayparting
                              }
                            }
                          });
                        }}
                      />
                    ))
                  ))}
                </div>
                <div className="mt-2 flex justify-between text-sm text-gray-500">
                  <span>Sunday</span>
                  <span>Monday</span>
                  <span>Tuesday</span>
                  <span>Wednesday</span>
                  <span>Thursday</span>
                  <span>Friday</span>
                  <span>Saturday</span>
                </div>
              </div>

              {/* Quick Presets */}
              <div className="flex space-x-4">

                <Button
                variant="secondary"
                size="sm"
                onClick={() => {
                    const newDayparting: DaypartingRecord = {};
    
                    for (let day = 1; day <= 5; day++) {
                      for (let hour = 9; hour <= 17; hour++) {
                        newDayparting[`${day}-${hour}`] = true;
                      }
                    }
                    
                    onUpdate({
                      audience: {
                        ...configuration.audience,
                        targeting: {
                          ...configuration.audience.targeting,
                          dayparting: newDayparting
                        }
                      }
                    });
                }}
                >
                Business Hours
                </Button>
                <Button 
                  variant="secondary" 
                  size="sm"
                  onClick={() => {
                        const weekends: DaypartingRecord = {};
                        [0, 6].forEach(day => {
                          for (let hour = 0; hour < 24; hour++) {
                            weekends[`${day}-${hour}`] = true;
                          }
                        });
                        onUpdate({
                          audience: {
                            ...configuration.audience,
                            targeting: {
                              ...configuration.audience.targeting,
                              dayparting: weekends
                            }
                          }
                        });

                  }}
                >
                  Weekends Only
                </Button>
                <Button 
                  variant="secondary" 
                  size="sm"
                  onClick={() => {
                        const allHours: DaypartingRecord = {};
                        for (let day = 0; day < 7; day++) {
                          for (let hour = 0; hour < 24; hour++) {
                            allHours[`${day}-${hour}`] = true;
                          }
                        }
                        onUpdate({
                          audience: {
                            ...configuration.audience,
                            targeting: {
                              ...configuration.audience.targeting,
                              dayparting: allHours
                            }
                          }
                        });
                      
                  }}
                >
                  All Hours
                </Button>
              </div>
            </div>
          </Card>

          {/* Advanced Targeting */}
          <Card>
            <Title>Advanced Targeting Options</Title>
            <div className="mt-4 space-y-6">
              {/* Frequency Capping */}
              <div>
                <Text className="font-medium">Frequency Capping</Text>
                <div className="mt-2 grid grid-cols-3 gap-4">
                  <div>
                    <label className="block text-sm text-gray-500">Per Day</label>
                    <input
                      type="number"
                      className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                      min="0"
                      placeholder="No limit"
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                        onUpdate({
                          audience: {
                            ...configuration.audience,
                            targeting: {
                              ...configuration.audience.targeting,
                              frequency_caps: {
                                ...configuration.audience.targeting.frequency_caps,
                                daily: value
                              }
                            }
                          }
                        });
                      }}
                    />
                  </div>
                  <div>
                    <label className="block text-sm text-gray-500">Per Week</label>
                    <input
                      type="number"
                      className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                      min="0"
                      placeholder="No limit"
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                        onUpdate({
                          audience: {
                            ...configuration.audience,
                            targeting: {
                              ...configuration.audience.targeting,
                              frequency_caps: {
                                ...configuration.audience.targeting.frequency_caps,
                                weekly: value
                              }
                            }
                          }
                        });
                      }}
                    />
                  </div>
                  <div>
                    <label className="block text-sm text-gray-500">Total</label>
                    <input
                      type="number"
                      className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                      min="0"
                      placeholder="No limit"
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                        onUpdate({
                          audience: {
                            ...configuration.audience,
                            targeting: {
                              ...configuration.audience.targeting,
                              frequency_caps: {
                                ...configuration.audience.targeting.frequency_caps,
                                total: value
                              }
                            }
                          }
                        });
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* Audience Expansion */}
              <div>
                <div className="flex items-center justify-between">
                  <Text className="font-medium">Audience Expansion</Text>
                  <Badge color="blue" icon={SparklesIcon}>AI Powered</Badge>
                </div>
                <Text className="mt-1 text-sm text-gray-500">
                  Automatically expand reach to similar users
                </Text>
                <div className="mt-4">
                            <select
            className="block w-full rounded-md border border-gray-300 px-3 py-2"
            onChange={(e) => {
                const value = e.target.value as "none" | "conservative" | "moderate" | "aggressive";
                onUpdate({
                audience: {
                    ...configuration.audience,
                    targeting: {
                    ...configuration.audience.targeting,
                    expansion: value
                    }
                }
                });
            }}
            >
            <option value="none">No expansion</option>
            <option value="conservative">Conservative (25% similar)</option>
            <option value="moderate">Moderate (50% similar)</option>
            <option value="aggressive">Aggressive (75% similar)</option>
            </select>
                </div>
              </div>
            </div>
          </Card>
        </>
      )}

      {/* Performance Predictions */}
      {(selectedSegments.length > 0 || configuration.audience.targeting.geography.length > 0) && (
        <Card>
          <div className="flex items-center justify-between">
            <Title>Performance Predictions</Title>
            <Badge color="blue" icon={SparklesIcon}>AI Powered</Badge>
          </div>
          <Text className="text-gray-500">
            Estimated performance based on your targeting selections
          </Text>

          <div className="mt-6 grid grid-cols-2 gap-6">
            <div>
              <Text className="font-medium">Reach & Frequency</Text>
              <AreaChart
                className="h-48 mt-4"
                data={[
                  { date: 'Week 1', reach: 1000000, frequency: 1.2 },
                  { date: 'Week 2', reach: 1800000, frequency: 2.1 },
                  { date: 'Week 3', reach: 2400000, frequency: 2.8 },
                  { date: 'Week 4', reach: 2800000, frequency: 3.2 }
                ]}
                index="date"
                categories={['reach', 'frequency']}
                colors={['blue', 'green']}
              />
            </div>
            <div>
              <Text className="font-medium">Performance Metrics</Text>
              <div className="mt-4 space-y-4">
                {[
                  { label: 'Predicted CTR', value: 0.15, benchmark: 0.12 },
                  { label: 'Predicted Viewability', value: 82, benchmark: 75 },
                  { label: 'Expected Completion Rate', value: 75, benchmark: 70 }
                ].map(metric => (
                  <div key={metric.label}>
                    <div className="flex justify-between mb-1">
                      <Text className="text-sm">{metric.label}</Text>
                      <Text className="text-sm font-medium">
                        {typeof metric.value === 'number' 
                          ? metric.value < 1 
                            ? `${(metric.value * 100).toFixed(2)}%`
                            : `${metric.value}%`
                          : metric.value}
                      </Text>
                    </div>
                    <ProgressBar
                      value={typeof metric.value === 'number' ? metric.value : 0}
                      color="blue"
                    />
                    <Text className="text-xs text-gray-500 mt-1">
                      Industry benchmark: {typeof metric.benchmark === 'number'
                        ? metric.benchmark < 1
                          ? `${(metric.benchmark * 100).toFixed(2)}%`
                          : `${metric.benchmark}%`
                        : metric.benchmark}
                    </Text>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Card>
      )}
    </div>
  );
}                      