import { Card } from '../ui/Card';
import { AreaChart, BadgeDelta, Flex, Metric, Text } from '@tremor/react';
import {
  CurrencyDollarIcon,
  ChartBarSquareIcon,
  EyeIcon,
  CursorArrowRaysIcon,
} from '@heroicons/react/24/outline';

const chartdata = [
  { date: '2024-01-01', Revenue: 12400, Impressions: 1200000, CTR: 0.12, CPM: 10.33 },
  { date: '2024-01-02', Revenue: 14250, Impressions: 1350000, CTR: 0.14, CPM: 10.56 },
  { date: '2024-01-03', Revenue: 15800, Impressions: 1480000, CTR: 0.13, CPM: 10.68 },
  { date: '2024-01-04', Revenue: 16500, Impressions: 1520000, CTR: 0.15, CPM: 10.86 },
  { date: '2024-01-05', Revenue: 17200, Impressions: 1600000, CTR: 0.14, CPM: 10.75 },
  { date: '2024-01-06', Revenue: 18100, Impressions: 1680000, CTR: 0.16, CPM: 10.77 },
  { date: '2024-01-07', Revenue: 18900, Impressions: 1720000, CTR: 0.15, CPM: 10.99 },
];

const metrics = [
  {
    name: 'Revenue',
    value: '$113,150',
    change: '12.3%',
    deltaType: 'increase',
    icon: CurrencyDollarIcon,
    chart: {
      data: chartdata,
      index: 'date',
      categories: ['Revenue'],
      colors: ['emerald'],
    },
  },
  {
    name: 'Impressions',
    value: '10.5M',
    change: '8.1%',
    deltaType: 'increase',
    icon: EyeIcon,
    chart: {
      data: chartdata,
      index: 'date',
      categories: ['Impressions'],
      colors: ['blue'],
    },
  },
  {
    name: 'Click-Through Rate',
    value: '0.14%',
    change: '5.2%',
    deltaType: 'increase',
    icon: CursorArrowRaysIcon,
    chart: {
      data: chartdata,
      index: 'date',
      categories: ['CTR'],
      colors: ['purple'],
    },
  },
  {
    name: 'Average CPM',
    value: '$10.75',
    change: '-2.1%',
    deltaType: 'decrease',
    icon: ChartBarSquareIcon,
    chart: {
      data: chartdata,
      index: 'date',
      categories: ['CPM'],
      colors: ['orange'],
    },
  },
];

export function PerformanceMetrics() {
  return (
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 xl:grid-cols-4">
      {metrics.map((metric) => (
        <Card key={metric.name} className="space-y-4">
          <div className="flex items-center justify-between">
            <div>
              <Text className="text-sm text-gray-500">{metric.name}</Text>
              <Metric>{metric.value}</Metric>
            </div>
            <metric.icon className="h-6 w-6 text-gray-400" />
          </div>

          <div className="flex items-center">
            <BadgeDelta 
              deltaType={metric.deltaType as 'increase' | 'decrease' | 'unchanged'}
              isIncreasePositive={true}
              size="sm"
            >
              {metric.change}
            </BadgeDelta>
            <Text className="ml-2 text-sm text-gray-500">vs. previous period</Text>
          </div>

          <AreaChart
            data={metric.chart.data}
            index={metric.chart.index}
            categories={metric.chart.categories}
            colors={metric.chart.colors}
            showXAxis={false}
            showYAxis={false}
            showLegend={false}
            showGridLines={false}
            showAnimation
            className="h-24 mt-4"
          />
        </Card>
      ))}
    </div>
  );
}