import { FC, useState, useMemo, Fragment } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Dialog, Transition } from '@headlessui/react';
import {
  Card,
  Badge,
  Text,
  Title,
  ProgressBar,
  Select,
  SelectItem,
  Metric,
  DonutChart,
} from '@tremor/react';
import { Button } from '../ui/Button';
import {
  GlobeAltIcon,
  ChartBarIcon,
  UsersIcon,
  ArrowsUpDownIcon,
  NewspaperIcon,
  ShieldCheckIcon,
  AdjustmentsHorizontalIcon,
  PlusIcon,
  CheckCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import type { SmartDiscoveryQuery } from '../../types/discovery';
import type { DomainBundle, Domain } from '../../types/domains';
import { mockDomainBundles } from '../../types/domains';

interface DomainBundleViewProps {
  query: SmartDiscoveryQuery | null;
}

interface BundleDetailsProps {
  bundle: DomainBundle;
  onClose: () => void;
}

interface IntersectedDomains {
  domains: Domain[];
  metrics: {
    totalReach: number;
    avgViewability: number;
    avgCtr: number;
    avgCpm: number;
  };
}

interface CreateBundleModalProps {
    intersectedDomains: {
      domains: Domain[];
      metrics: {
        totalReach: number;
        avgViewability: number;
        avgCtr: number;
        avgCpm: number;
      };
    };
    onClose: () => void;
    onSave: (bundleName: string, description: string) => void;
  }
// BundleDetails Component
const BundleDetails: FC<BundleDetailsProps> = ({ bundle, onClose }) => {
  const [activeTab, setActiveTab] = useState<'overview' | 'domains' | 'performance'>('overview');

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex items-start justify-between">
        <div>
          <Dialog.Title as="h3" className="text-xl font-semibold text-gray-900">
            {bundle.name}
          </Dialog.Title>
          <Text className="text-gray-500 mt-1">{bundle.description}</Text>
        </div>
        <div className="flex items-center space-x-4">
          <Badge color="blue">{bundle.type}</Badge>
          <Button variant="ghost" size="sm" onClick={onClose}>
            <XMarkIcon className="h-5 w-5" />
          </Button>
        </div>
      </div>

      {/* Tabs */}
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex space-x-8">
          {['overview', 'domains', 'performance'].map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab as typeof activeTab)}
              className={`
                whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm
                ${activeTab === tab
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }
              `}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </nav>
      </div>

      {/* Content based on active tab */}
      {activeTab === 'overview' && (
        <div className="space-y-6">
          {/* Quick Stats */}
          <div className="grid grid-cols-4 gap-4">
            <Card>
              <Text>Total Domains</Text>
              <Metric>{bundle.domains.length}</Metric>
            </Card>
            <Card>
              <Text>Total Reach</Text>
              <Metric>{(bundle.metrics.totalReach / 1000000).toFixed(1)}M</Metric>
            </Card>
            <Card>
              <Text>Avg. Viewability</Text>
              <Metric>{bundle.metrics.avgViewability.toFixed(1)}%</Metric>
            </Card>
            <Card>
              <Text>Avg. CPM</Text>
              <Metric>${bundle.metrics.avgCpm.toFixed(2)}</Metric>
            </Card>
          </div>
          {/* Add more overview content */}
        </div>
      )}

      {activeTab === 'domains' && (
        <div className="space-y-4">
          {/* Domains list */}
          {bundle.domains.map(domain => (
            <Card key={domain.url}>
              <div className="flex justify-between items-center">
                <div>
                  <Text className="font-medium">{domain.url}</Text>
                  <Text className="text-sm text-gray-500">
                    {domain.categories.join(', ')}
                  </Text>
                </div>
                <Badge color={
                  domain.quality_score >= 90 ? 'emerald' :
                  domain.quality_score >= 80 ? 'blue' : 'yellow'
                }>
                  {domain.quality_score}
                </Badge>
              </div>
            </Card>
          ))}
        </div>
      )}

      {activeTab === 'performance' && (
        <div className="space-y-6">
          {/* Performance metrics */}
          <Card>
            <Title>Performance Overview</Title>
            <div className="mt-4">
              <DonutChart
                data={[
                  { name: 'Viewability', value: bundle.metrics.avgViewability },
                  { name: 'CTR', value: bundle.metrics.avgCtr * 100 },
                  { name: 'Completion', value: bundle.metrics.avgCompletion || 0 }
                ]}
                category="value"
                index="name"
                colors={['blue', 'emerald', 'violet']}
              />
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

// CreateBundleModal Component
const CreateBundleModal: FC<CreateBundleModalProps> = ({
    intersectedDomains,
    onClose,
    onSave,
  }) => {
    const [bundleName, setBundleName] = useState('');
    const [description, setDescription] = useState('');
  
    return (
      <div className="space-y-6">
        <div className="flex items-start justify-between">
          <div>
            <Dialog.Title as="h3" className="text-xl font-semibold text-gray-900">
              Create New Bundle
            </Dialog.Title>
            <Text className="text-gray-500 mt-1">
              Create a new bundle from {intersectedDomains.domains.length} common domains
            </Text>
          </div>
          <Button variant="ghost" size="sm" onClick={onClose}>
            <XMarkIcon className="h-5 w-5" />
          </Button>
        </div>
  
        <div className="space-y-6">
          {/* Bundle Information Form */}
          <div className="space-y-4">
            <div>
              <label htmlFor="bundleName" className="block text-sm font-medium text-gray-700">
                Bundle Name
              </label>
              <input
                type="text"
                id="bundleName"
                value={bundleName}
                onChange={(e) => setBundleName(e.target.value)}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={3}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
              />
            </div>
          </div>
  
          {/* Bundle Preview */}
          <Card>
            <Title>Bundle Preview</Title>
            <div className="mt-4 space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <Text className="text-sm text-gray-500">Total Reach</Text>
                  <Text className="font-medium">
                    {(intersectedDomains.metrics.totalReach / 1000000).toFixed(1)}M
                  </Text>
                </div>
                <div>
                  <Text className="text-sm text-gray-500">Avg. Viewability</Text>
                  <Text className="font-medium">
                    {intersectedDomains.metrics.avgViewability.toFixed(1)}%
                  </Text>
                </div>
                <div>
                  <Text className="text-sm text-gray-500">Avg. CTR</Text>
                  <Text className="font-medium">
                    {(intersectedDomains.metrics.avgCtr * 100).toFixed(2)}%
                  </Text>
                </div>
                <div>
                  <Text className="text-sm text-gray-500">Avg. CPM</Text>
                  <Text className="font-medium">
                    ${intersectedDomains.metrics.avgCpm.toFixed(2)}
                  </Text>
                </div>
              </div>
  
              <div>
                <Text className="font-medium mb-2">Included Domains</Text>
                <div className="max-h-40 overflow-y-auto space-y-2">
                  {intersectedDomains.domains.map((domain) => (
                    <div key={domain.url} className="flex items-center justify-between p-2 bg-gray-50 rounded-lg">
                    <div>
                      <Text className="font-medium">{domain.url}</Text>
                      <Text className="text-xs text-gray-500">
                        {domain.categories.join(', ')}
                      </Text>
                    </div>
                    <Badge color={
                      domain.quality_score >= 90 ? 'emerald' :
                      domain.quality_score >= 80 ? 'blue' :
                      'yellow'
                    }>
                      {domain.quality_score}
                    </Badge>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Card>
      </div>

      {/* Actions */}
      <div className="flex justify-end space-x-4 pt-6 border-t">
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          disabled={!bundleName.trim()}
          onClick={() => onSave(bundleName, description)}
        >
          Create Bundle
        </Button>
        
      </div>
    </div>
  );
};

// Main DomainBundleView Component
export const DomainBundleView: FC<DomainBundleViewProps> = ({ query }) => {
  const [selectedBundles, setSelectedBundles] = useState<string[]>([]);
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  const [sortBy, setSortBy] = useState<string>('similarity');
  const [filterType, setFilterType] = useState<string>('all');
  const [showBundleDetails, setShowBundleDetails] = useState<string | null>(null);
  const [showCreateBundle, setShowCreateBundle] = useState(false);

  // Calculate intersection of selected bundles
  const intersectedDomains = useMemo(() => {
    if (selectedBundles.length < 2) return null;
    
    const bundles = selectedBundles.map(id => 
      mockDomainBundles.find(b => b.id === id)!
    );
    
    const domains = bundles[0].domains.filter(domain =>
      bundles.every(bundle =>
        bundle.domains.some(d => d.url === domain.url)
      )
    );

    if (domains.length === 0) return null;

    return {
      domains,
      metrics: {
        totalReach: domains.reduce((acc, d) => acc + d.metrics.reach, 0),
        avgViewability: domains.reduce((acc, d) => acc + d.metrics.viewability, 0) / domains.length,
        avgCtr: domains.reduce((acc, d) => acc + d.metrics.ctr, 0) / domains.length,
        avgCpm: domains.reduce((acc, d) => acc + d.metrics.avgCpm, 0) / domains.length,
      }
    };
  }, [selectedBundles]);

  const handleBundleCreation = (name: string, description: string) => {
    if (!intersectedDomains) return;

    const newBundle: DomainBundle = {
      id: `BUNDLE-${Math.random().toString(36).substr(2, 9)}`,
      name,
      description,
      type: 'News', // Using a valid type from the union
      domains: intersectedDomains.domains,
      metrics: intersectedDomains.metrics,
      tags: ['Custom', 'Intersection'],
      lastUpdated: new Date().toISOString()
    };

    console.log('New bundle created:', newBundle);
    setShowCreateBundle(false);
    setSelectedBundles([]);
  };

  // Domain Bundle Card Component
  const DomainBundleCard: FC<{ bundle: DomainBundle }> = ({ bundle }) => (
    <motion.div
      layout
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="h-full"
    >
      <Card className={`h-full cursor-pointer transition-all duration-200 ${
        selectedBundles.includes(bundle.id) ? 'ring-2 ring-blue-500' : ''
      }`}>
        {/* Selection Checkbox */}
        <div className="absolute top-3 left-3">
          <input
            type="checkbox"
            checked={selectedBundles.includes(bundle.id)}
            onChange={() => {
              setSelectedBundles(prev =>
                prev.includes(bundle.id)
                  ? prev.filter(id => id !== bundle.id)
                  : [...prev, bundle.id]
              );
            }}
            className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            onClick={(e) => e.stopPropagation()}
          />
        </div>

        {/* Similarity Score */}
        {bundle.similarityScore && (
          <div className="absolute top-3 right-3">
            <Badge
              color={bundle.similarityScore > 90 ? 'emerald' : 'blue'}
              size="lg"
            >
              {bundle.similarityScore}% Match
            </Badge>
          </div>
        )}

        {/* Main Content */}
        <div className="space-y-4 pt-10">
          {/* Header */}
          <div>
            <div className="flex items-start justify-between">
              <div className="flex-1 min-w-0 pr-4">
                <Title className="text-base truncate">{bundle.name}</Title>
                <Text className="text-sm text-gray-500 truncate">
                  {bundle.description}
                </Text>
              </div>
            </div>
            <div className="flex items-center space-x-2 mt-2">
              <Badge color="blue">{bundle.type}</Badge>
              <Badge color="gray">{bundle.domains.length} Domains</Badge>
            </div>
          </div>

          {/* Metrics Grid */}
          <div className="grid grid-cols-2 gap-4">
            <div>
              <Text className="text-xs text-gray-500">Total Reach</Text>
              <Text className="text-sm font-medium">
                {(bundle.metrics.totalReach / 1000000).toFixed(1)}M
              </Text>
            </div>
            <div>
              <Text className="text-xs text-gray-500">Avg. Viewability</Text>
              <Text className="text-sm font-medium">
                {bundle.metrics.avgViewability.toFixed(1)}%
              </Text>
            </div>
            <div>
              <Text className="text-xs text-gray-500">Avg. CTR</Text>
              <Text className="text-sm font-medium">
                {(bundle.metrics.avgCtr * 100).toFixed(2)}%
              </Text>
            </div>
            <div>
              <Text className="text-xs text-gray-500">Avg. CPM</Text>
              <Text className="text-sm font-medium">
                ${bundle.metrics.avgCpm.toFixed(2)}
              </Text>
            </div>
          </div>

          {/* Tags */}
          <div className="flex flex-wrap gap-2">
            {bundle.tags.map((tag) => (
              <Badge key={tag} color="gray" size="sm">
                {tag}
              </Badge>
            ))}
          </div>

          {/* Actions */}
          <div className="border-t pt-3 flex justify-between items-center">
            <Button
              variant="ghost"
              size="sm"
              className="text-xs"
              onClick={() => setShowBundleDetails(bundle.id)}
            >
              View Details
            </Button>
            <Text className="text-xs text-gray-500">
              Updated {new Date(bundle.lastUpdated).toLocaleDateString()}
            </Text>
          </div>
        </div>
      </Card>
    </motion.div>
  );

  return (
    <div className="space-y-6">
      {/* Metrics Overview */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <Card>
          <Text>Total Bundles</Text>
          <Metric>{mockDomainBundles.length}</Metric>
          <Text className="text-sm text-gray-500">
            {mockDomainBundles.reduce((acc, b) => acc + b.domains.length, 0)} domains
          </Text>
        </Card>
        <Card>
          <Text>Avg. Viewability</Text>
          <Metric>
            {(mockDomainBundles.reduce((acc, b) => acc + b.metrics.avgViewability, 0) / 
              mockDomainBundles.length).toFixed(1)}%
          </Metric>
        </Card>
        <Card>
          <Text>Total Reach</Text>
          <Metric>
            {(mockDomainBundles.reduce((acc, b) => acc + b.metrics.totalReach, 0) / 
              1000000).toFixed(1)}M
          </Metric>
        </Card>
        <Card>
          <Text>Bundle Types</Text>
          <Metric>{new Set(mockDomainBundles.map(b => b.type)).size}</Metric>
        </Card>
      </div>

      {/* Controls */}
      <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
        <div className="flex items-center space-x-4">
          <Select 
            value={viewMode} 
            onValueChange={(value: string) => setViewMode(value as 'grid' | 'list')}
          >
            <SelectItem value="grid">Grid View</SelectItem>
            <SelectItem value="list">List View</SelectItem>
          </Select>

          <Select 
            value={sortBy} 
            onValueChange={(value: string) => setSortBy(value)}
          >
            <SelectItem value="similarity">Sort by Match</SelectItem>
            <SelectItem value="reach">Sort by Reach</SelectItem>
            <SelectItem value="viewability">Sort by Viewability</SelectItem>
            <SelectItem value="recent">Sort by Recent</SelectItem>
          </Select>

          <Select 
            value={filterType} 
            onValueChange={(value: string) => setFilterType(value)}
          >
            <SelectItem value="all">All Types</SelectItem>
            <SelectItem value="News">News</SelectItem>
            <SelectItem value="Sports">Sports</SelectItem>
            <SelectItem value="Entertainment">Entertainment</SelectItem>
            <SelectItem value="Technology">Technology</SelectItem>
          </Select>
        </div>

        <div className="flex items-center space-x-4">
          {selectedBundles.length >= 2 && (
            <Button variant="secondary" size="sm" onClick={() => setShowCreateBundle(true)}>
              <ArrowsUpDownIcon className="h-5 w-5 mr-2" />
              Create Intersection ({selectedBundles.length})
            </Button>
          )}
          <Button variant="primary" size="sm">
            <PlusIcon className="h-5 w-5 mr-2" />
            Create Bundle
          </Button>
        </div>
      </div>

      {/* Selected Bundles Intersection Preview */}
      {intersectedDomains && (
        <Card className="bg-blue-50 border-blue-100">
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <ArrowsUpDownIcon className="h-5 w-5 text-blue-500" />
                <Title className="text-blue-900">Bundle Intersection</Title>
              </div>
              <Badge color="blue">
                {intersectedDomains.domains.length} Common Domains
              </Badge>
            </div>

            <div className="grid grid-cols-4 gap-4">
              <div>
                <Text className="text-sm text-blue-700">Total Reach</Text>
                <Text className="font-medium text-blue-900">
                  {(intersectedDomains.metrics.totalReach / 1000000).toFixed(1)}M
                </Text>
              </div>
              <div>
                <Text className="text-sm text-blue-700">Avg. Viewability</Text>
                <Text className="font-medium text-blue-900">
                  {intersectedDomains.metrics.avgViewability.toFixed(1)}%
                </Text>
              </div>
              <div>
                <Text className="text-sm text-blue-700">Avg. CTR</Text>
                <Text className="font-medium text-blue-900">
                  {(intersectedDomains.metrics.avgCtr * 100).toFixed(2)}%
                </Text>
              </div>
              <div>
                <Text className="text-sm text-blue-700">Avg. CPM</Text>
                <Text className="font-medium text-blue-900">
                  ${intersectedDomains.metrics.avgCpm.toFixed(2)}
                </Text>
              </div>
            </div>

            <Button
              variant="primary"
              size="sm"
              className="w-full"
              onClick={() => setShowCreateBundle(true)}
            >
              Create New Bundle from Intersection
            </Button>
          </div>
        </Card>
      )}

      {/* Domain Bundles Grid/List */}
      <div className={
        viewMode === 'grid'
          ? 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'
          : 'space-y-4'
      }>
        <AnimatePresence>
          {mockDomainBundles
            .filter(bundle => filterType === 'all' || bundle.type === filterType)
            .sort((a, b) => {
              switch (sortBy) {
                case 'similarity':
                  return (b.similarityScore || 0) - (a.similarityScore || 0);
                case 'reach':
                  return b.metrics.totalReach - a.metrics.totalReach;
                case 'viewability':
                  return b.metrics.avgViewability - a.metrics.avgViewability;
                case 'recent':
                  return new Date(b.lastUpdated).getTime() - new Date(a.lastUpdated).getTime();
                default:
                  return 0;
              }
            })
            .map((bundle) => (
              <DomainBundleCard 
                key={bundle.id} 
                bundle={bundle}
              />
            ))}
        </AnimatePresence>
        <Transition appear show={!!showBundleDetails} as={Fragment}>
  <Dialog 
    as="div" 
    className="relative z-50"
    onClose={() => setShowBundleDetails(null)}
  >
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed inset-0 bg-black bg-opacity-25" />
    </Transition.Child>

    <div className="fixed inset-0 overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-4">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 shadow-xl transition-all">
            {showBundleDetails && (
              <BundleDetails 
                bundle={mockDomainBundles.find(b => b.id === showBundleDetails)!}
                onClose={() => setShowBundleDetails(null)}
              />
            )}
          </Dialog.Panel>
        </Transition.Child>
      </div>
    </div>
  </Dialog>
</Transition>

      </div>
      </div>

  );
  
};
// };

export default DomainBundleView;