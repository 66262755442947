import { Title } from '@tremor/react';
import { Card } from '../ui/Card';
import {
  RocketLaunchIcon,
  DocumentPlusIcon,
  UserGroupIcon,
  ChartBarSquareIcon
} from '@heroicons/react/24/outline';

const actions = [
  {
    name: 'Create Deal',
    description: 'Set up a new programmatic deal',
    icon: DocumentPlusIcon,
    color: 'text-blue-600',
    bgColor: 'bg-blue-50',
    href: '/deals/new'
  },
  {
    name: 'Build Audience',
    description: 'Create a custom audience segment',
    icon: UserGroupIcon,
    color: 'text-purple-600',
    bgColor: 'bg-purple-50',
    href: '/audiences/new'
  },
  {
    name: 'View Performance',
    description: 'Check campaign metrics',
    icon: ChartBarSquareIcon,
    color: 'text-green-600',
    bgColor: 'bg-green-50',
    href: '/performance'
  },
  {
    name: 'Get Recommendations',
    description: 'AI-powered optimization tips',
    icon: RocketLaunchIcon,
    color: 'text-orange-600',
    bgColor: 'bg-orange-50',
    href: '/intelligence'
  }
];

export function QuickActions() {
  return (
    <Card>
      <Title className="text-base font-medium mb-4">Quick Actions</Title>
      <div className="divide-y divide-gray-100">
        {actions.map((action, index) => (
          <button
            key={action.name}
            className={`flex items-start w-full p-3 text-left hover:bg-gray-50 rounded-lg group transition-colors
              ${index === 0 ? 'pt-0' : ''} 
              ${index === actions.length - 1 ? 'pb-0 border-b-0' : ''}`
            }
            onClick={() => {}}
          >
            <div className={`${action.bgColor} p-2 rounded-lg group-hover:ring-2 ring-gray-200 flex-shrink-0`}>
              <action.icon className={`h-5 w-5 ${action.color}`} />
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-900">{action.name}</p>
              <p className="text-sm text-gray-500 mt-0.5">{action.description}</p>
            </div>
          </button>
        ))}
      </div>
    </Card>
  );
}