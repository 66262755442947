import { useState } from 'react';
import { Card } from '../ui/Card';
import {
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  Badge,
  ProgressBar,
  AreaChart,
  DonutChart,
  LineChart,
  Text,
} from '@tremor/react';

interface CampaignData {
  id: string;
  name: string;
  advertiser: string;
  status: 'active' | 'completed' | 'paused';
  budget: number;
  spent: number;
  impressions: number;
  clicks: number;
  ctr: number;
  viewability: number;
  completion: number;
  performance: 'above' | 'on' | 'below';
}

const mockCampaigns: CampaignData[] = [
  {
    id: 'CAMP-001',
    name: 'Q1 Auto Awareness',
    advertiser: 'AutoMax Inc',
    status: 'active',
    budget: 50000,
    spent: 32500,
    impressions: 2500000,
    clicks: 3250,
    ctr: 0.13,
    viewability: 76,
    completion: 85,
    performance: 'above'
  },
  {
    id: 'CAMP-002',
    name: 'Retail Holiday Push',
    advertiser: 'ShopCo',
    status: 'active',
    budget: 75000,
    spent: 45000,
    impressions: 3800000,
    clicks: 4560,
    ctr: 0.12,
    viewability: 72,
    completion: 78,
    performance: 'on'
  },
  {
    id: 'CAMP-003',
    name: 'Tech Brand Campaign',
    advertiser: 'TechGiant',
    status: 'active',
    budget: 100000,
    spent: 58000,
    impressions: 4200000,
    clicks: 4620,
    ctr: 0.11,
    viewability: 68,
    completion: 72,
    performance: 'below'
  },
];

const performanceColors = {
  above: 'emerald',
  on: 'blue',
  below: 'red'
} as const;

const statusColors = {
  active: 'emerald',
  completed: 'gray',
  paused: 'yellow'
} as const;

const hourlyData = Array.from({ length: 24 }, (_, i) => ({
  hour: i,
  impressions: Math.floor(Math.random() * 50000) + 10000,
  clicks: Math.floor(Math.random() * 500) + 100,
  spend: Math.floor(Math.random() * 1000) + 200,
}));

const deviceData = [
  { device: 'Desktop', value: 35 },
  { device: 'Mobile', value: 45 },
  { device: 'Tablet', value: 15 },
  { device: 'CTV', value: 5 },
];

interface CampaignPerformanceProps {
  showDetailed?: boolean;
}

export function CampaignPerformance({ showDetailed = false }: CampaignPerformanceProps) {
  const [selectedCampaign, setSelectedCampaign] = useState<CampaignData | null>(null);

  return (
    <div className="space-y-6">
      {showDetailed && (
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
          {/* Hourly Performance */}
          <Card className="lg:col-span-2">
            <Text className="font-medium mb-4">Hourly Performance</Text>
            <LineChart
              data={hourlyData}
              index="hour"
              categories={["impressions", "clicks", "spend"]}
              colors={["blue", "emerald", "indigo"]}
              yAxisWidth={60}
              className="h-80"
            />
          </Card>

          {/* Device Distribution */}
          <Card>
            <Text className="font-medium mb-4">Device Distribution</Text>
            <DonutChart
              data={deviceData}
              category="value"
              index="device"
              colors={["blue", "cyan", "indigo", "violet"]}
              className="mt-6"
            />
          </Card>
        </div>
      )}

      {/* Campaigns Table */}
      <Card>
        <div className="overflow-x-auto">
          <Table>
            <TableHead>
              <TableRow>
                <TableHeaderCell>Campaign</TableHeaderCell>
                <TableHeaderCell>Status</TableHeaderCell>
                <TableHeaderCell>Budget Utilized</TableHeaderCell>
                <TableHeaderCell>Impressions</TableHeaderCell>
                <TableHeaderCell>CTR</TableHeaderCell>
                <TableHeaderCell>Viewability</TableHeaderCell>
                <TableHeaderCell>Performance</TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mockCampaigns.map((campaign) => (
                <TableRow 
                  key={campaign.id}
                  className="cursor-pointer hover:bg-gray-50"
                  onClick={() => setSelectedCampaign(campaign)}
                >
                  <TableCell>
                    <div>
                      <div className="font-medium text-gray-900">{campaign.name}</div>
                      <div className="text-sm text-gray-500">{campaign.advertiser}</div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <Badge color={statusColors[campaign.status]}>
                      {campaign.status.charAt(0).toUpperCase() + campaign.status.slice(1)}
                    </Badge>
                  </TableCell>
                  <TableCell>
                    <div className="space-y-1">
                      <div className="flex justify-between text-sm">
                        <span>${campaign.spent.toLocaleString()}</span>
                        <span className="text-gray-500">${campaign.budget.toLocaleString()}</span>
                      </div>
                      <ProgressBar
                        value={(campaign.spent / campaign.budget) * 100}
                        color="blue"
                        className="h-1.5"
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    {campaign.impressions.toLocaleString()}
                  </TableCell>
                  <TableCell>
                    {(campaign.ctr * 100).toFixed(2)}%
                  </TableCell>
                  <TableCell>
                    <div className="space-y-1">
                      <div className="flex justify-between text-sm">
                        <span>{campaign.viewability}%</span>
                      </div>
                      <ProgressBar
                        value={campaign.viewability}
                        color="emerald"
                        className="h-1.5"
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <Badge color={performanceColors[campaign.performance]}>
                      {campaign.performance === 'above' ? 'Above Target' :
                       campaign.performance === 'on' ? 'On Target' :
                       'Below Target'}
                    </Badge>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Card>
    </div>
  );
}