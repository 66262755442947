import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

interface GlobalSearchProps {
  onOpen: () => void;
}

export function GlobalSearch({ onOpen }: GlobalSearchProps) {
  return (
    <button
      type="button"
      className="flex flex-1 items-center gap-x-3 rounded-md bg-white px-3 py-2 text-sm leading-6 text-gray-400 ring-1 ring-gray-900/10 hover:ring-gray-300"
      onClick={onOpen}
    >
      <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
      <span>Search inventory, audiences, deals... (⌘K)</span>
    </button>
  );
}