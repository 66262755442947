import { createContext, useContext } from 'react';

type UserRoleContextType = {
  currentRole: string;
  setCurrentRole: (role: string) => void;
  availableRoles: string[];
};

export const UserRoleContext = createContext<UserRoleContextType>({
  currentRole: 'Sales',
  setCurrentRole: () => {},
  availableRoles: [],
});

export const useUserRole = () => {
  const context = useContext(UserRoleContext);
  if (!context) {
    throw new Error('useUserRole must be used within a UserRoleProvider');
  }
  return context;
};