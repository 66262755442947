// src/pages/Dashboard.tsx

import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import {
  SparklesIcon,
  ArrowTrendingUpIcon,
  RocketLaunchIcon,
  ClockIcon,
  ChartBarIcon,
  StarIcon,
  ArrowRightIcon,
  MagnifyingGlassIcon,
  UsersIcon,
  BoltIcon,
} from '@heroicons/react/24/outline';
import { Card, Text, Title, Badge, Metric } from '@tremor/react';
import { cn } from '../utils/cn';
import type { DashboardTile } from '../data/dashboardData';
import {
  quickActionTiles,
  trendingDealTiles,
  recentActivityTiles,
  recommendedTiles,
  topPerformingTiles,
  exploreNewTiles,
} from '../data/dashboardData';

interface TileRowProps {
  title: string;
  subtitle?: string;
  tiles: DashboardTile[];
  type: 'large' | 'medium' | 'small';
  className?: string;
}

const TileRow: FC<TileRowProps> = ({
  title,
  subtitle,
  tiles,
  type,
  className
}) => {
  const [hoveredTile, setHoveredTile] = useState<string | null>(null);

  const getTileHeight = () => {
    switch(type) {
      case 'large':
        return 'h-[420px]';
      case 'medium':
        return 'h-[320px]';
      case 'small':
        return 'h-[240px]';
    }
  };

  return (
    <section className={cn("mb-12", className)}>
      {/* Section Header */}
      <div className="flex items-center justify-between mb-6">
        <div>
          <Title className="text-xl font-semibold text-gray-900">{title}</Title>
          {subtitle && (
            <Text className="text-gray-500 mt-1">{subtitle}</Text>
          )}
        </div>
        <Link
          to={`/discover/${title.toLowerCase().replace(/\s+/g, '-')}`}
          className="flex items-center text-sm font-medium text-blue-600 hover:text-blue-700"
        >
          View All
          <ArrowRightIcon className="ml-1 h-4 w-4" />
        </Link>
      </div>
      
      {/* Tiles Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        <AnimatePresence>
          {tiles.map((tile) => (
            <motion.div
              key={tile.id}
              layout
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              whileHover={{ y: -4 }}
              className="group"
            >
              <Link to={tile.link}>
                <Card
                  className={cn(
                    getTileHeight(),
                    'relative overflow-hidden cursor-pointer transition-all duration-300',
                    'hover:shadow-lg hover:ring-1 hover:ring-blue-100',
                    tile.highlight && 'ring-2 ring-blue-500',
                  )}
                >
                  {/* Gradient Background */}
                  <div className={cn(
                    'absolute inset-0 opacity-50 transition-opacity group-hover:opacity-70',
                    'bg-gradient-to-br',
                    tile.gradient?.from || 'from-blue-50',
                    tile.gradient?.to || 'to-indigo-50'
                  )} />

                  {/* Content Container */}
                  <div className="relative z-10 h-full flex flex-col">
                    {/* Header */}
                    <div className="flex-1">
                      <div className="flex items-start justify-between">
                        <div className="flex-1">
                          {tile.icon && (
                            <tile.icon className="h-6 w-6 text-blue-600 mb-3" />
                          )}
                          <Title className="text-lg mb-2 line-clamp-2">
                            {tile.title}
                          </Title>
                          {tile.description && (
                            <Text className="text-gray-600 line-clamp-2">
                              {tile.description}
                            </Text>
                          )}
                        </div>
                        {tile.status && (
                          <Badge
                            className="ml-2 flex-shrink-0"
                            color={
                              tile.status === 'active' ? 'emerald' :
                              tile.status === 'pending' ? 'yellow' :
                              'gray'
                            }
                          >
                            {tile.status}
                          </Badge>
                        )}
                      </div>

                      {/* Metrics Grid */}
                      {tile.metrics && (
                        <div className="mt-6 grid grid-cols-2 gap-4">
                          {tile.metrics.map((metric, idx) => (
                            <div key={idx} className="space-y-1">
                              <Text className="text-sm text-gray-500">
                                {metric.label}
                              </Text>
                              <div className="flex items-baseline space-x-2">
                                <Text className="font-semibold text-gray-900">
                                  {metric.value}
                                </Text>
                                {metric.trend && (
                                  <span className={cn(
                                    'text-xs font-medium',
                                    metric.trend > 0 ? 'text-emerald-600' : 'text-red-600'
                                  )}>
                                    {metric.trend > 0 ? '+' : ''}{metric.trend}%
                                  </span>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>

                    {/* Footer */}
                    <div className="mt-4 pt-4 border-t border-gray-100">
                      {tile.tags && (
                        <div className="flex flex-wrap gap-2">
                          {tile.tags.map((tag) => (
                            <Badge
                              key={tag}
                              color="gray"
                              size="sm"
                            >
                              {tag}
                            </Badge>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Hover Overlay */}
                  <div className={cn(
                    'absolute inset-0 bg-gradient-to-t from-black/5 via-transparent to-transparent',
                    'opacity-0 group-hover:opacity-100 transition-opacity',
                    'flex items-end justify-end p-4'
                  )}>
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      className="text-blue-600 font-medium flex items-center"
                    >
                      Explore
                      <ArrowRightIcon className="ml-1 h-4 w-4" />
                    </motion.div>
                  </div>
                </Card>
              </Link>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </section>
  );
};

// Continue in the same file

const Dashboard: FC = () => {
    return (
      <div className="min-h-screen bg-gray-50">
        <div className="max-w-[1920px] mx-auto px-4 sm:px-6 lg:px-8 py-8">
          {/* Welcome Header */}
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            className="mb-12"
          >
            <div className="flex items-end justify-between">
              <div>
                <h1 className="text-3xl font-semibold text-gray-900">
                  Welcome back
                </h1>
                <p className="mt-2 text-gray-600">
                  Here's what's happening across your workspace
                </p>
              </div>
              <Link
                to="/dashboard/detailed"
                className="flex items-center text-sm font-medium text-blue-600 hover:text-blue-700"
              >
                <ChartBarIcon className="mr-1.5 h-5 w-5" />
                View Detailed Dashboard
              </Link>
            </div>
  
            {/* Quick Stats */}
            <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              {[
                { label: 'Active Deals', value: '24', trend: '+12%' },
                { label: 'Total Revenue', value: '$1.2M', trend: '+8.1%' },
                { label: 'Avg. Performance', value: '92%', trend: '+5.4%' },
                { label: 'Active Audiences', value: '18', trend: '+3.2%' },
              ].map((stat, idx) => (
                <Card key={idx} className="bg-white">
                  <Text>{stat.label}</Text>
                  <div className="flex items-baseline space-x-2 mt-2">
                    <Metric>{stat.value}</Metric>
                    <Text className="text-emerald-600">{stat.trend}</Text>
                  </div>
                </Card>
              ))}
            </div>
          </motion.div>
  
          {/* Main Content - Netflix Style Sections */}
          <div className="space-y-12">
            {/* Quick Actions */}
            <TileRow
              title="Quick Actions"
              subtitle="Start working on something new"
              tiles={quickActionTiles}
              type="small"
            />
  
            {/* Trending Deals */}
            <TileRow
              title="Trending Deals"
              subtitle="Popular and high-performing deals"
              tiles={trendingDealTiles}
              type="medium"
            />
  
            {/* Top Performing */}
            <TileRow
              title="Top Performing"
              subtitle="Your best performing campaigns"
              tiles={topPerformingTiles}
              type="medium"
              className="bg-gradient-to-br from-blue-50/50 to-indigo-50/50 py-8 -mx-8 px-8"
            />
  
            {/* Recent Activity */}
            <TileRow
              title="Recent Activity"
              subtitle="Your latest actions and updates"
              tiles={recentActivityTiles}
              type="small"
            />
  
            {/* Recommended */}
            <TileRow
              title="Recommended for You"
              subtitle="AI-powered suggestions based on your activity"
              tiles={recommendedTiles}
              type="medium"
            />
  
            {/* Explore New */}
            <TileRow
              title="Explore New"
              subtitle="Discover new opportunities"
              tiles={exploreNewTiles}
              type="medium"
            />
          </div>
  
          {/* Footer Navigation */}
          <div className="mt-16 text-center">
            <div className="inline-flex items-center space-x-4">
              <Link
                to="/discovery"
                className="text-blue-600 hover:text-blue-700 font-medium flex items-center"
              >
                <MagnifyingGlassIcon className="mr-1.5 h-5 w-5" />
                Explore All
              </Link>
              <span className="text-gray-300">|</span>
              <Link
                to="/dashboard/detailed"
                className="text-blue-600 hover:text-blue-700 font-medium flex items-center"
              >
                <ChartBarIcon className="mr-1.5 h-5 w-5" />
                View Analytics
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default Dashboard;