import { Dialog } from '@headlessui/react';
import {
  Card,
  Title,
  Text,
  Metric,
  Badge,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  AreaChart
} from '@tremor/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import type { EnhancedDeal } from '../../types/deals';
import { Button } from '../ui/Button';

interface DealAnalysisDialogProps {
  deal: EnhancedDeal;
  isOpen: boolean;
  onClose: () => void;
}

export function DealAnalysisDialog({ deal, isOpen, onClose }: DealAnalysisDialogProps) {
  return (
    <Dialog 
      open={isOpen} 
      onClose={onClose}
      className="fixed inset-0 z-50 overflow-y-auto"
    >
      <div className="flex min-h-screen items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
        
        <div className="relative bg-white w-full max-w-6xl rounded-xl shadow-xl p-6">
          {/* Header */}
          <div className="flex items-center justify-between mb-6">
            <div>
              <Dialog.Title className="text-xl font-semibold">
                Deal Analysis
              </Dialog.Title>
              <Text className="text-gray-500">
                {deal.name}
              </Text>
            </div>
            <Button variant="ghost" size="sm" onClick={onClose}>
              <XMarkIcon className="h-5 w-5" />
            </Button>
          </div>

          {/* Content */}
          <TabGroup>
            <TabList className="flex space-x-2 mb-6">
              <Tab className="ui-selected:bg-blue-500 ui-selected:text-white px-4 py-2 rounded-lg">
                Overview
              </Tab>
              <Tab className="ui-selected:bg-blue-500 ui-selected:text-white px-4 py-2 rounded-lg">
                Performance
              </Tab>
              <Tab className="ui-selected:bg-blue-500 ui-selected:text-white px-4 py-2 rounded-lg">
                Setup
              </Tab>
              <Tab className="ui-selected:bg-blue-500 ui-selected:text-white px-4 py-2 rounded-lg">
                History
              </Tab>
            </TabList>

            <TabPanels>
              {/* Overview Panel */}
              <TabPanel>
                <div className="grid grid-cols-3 gap-6 mb-6">
                  <Card>
                    <div className="p-4">
                      <Text className="text-gray-600">Health Score</Text>
                      <div className="flex items-center space-x-2">
                        <Metric>{deal.health.score}</Metric>
                        <Badge 
                          color={
                            deal.health.status === 'healthy' ? 'green' :
                            deal.health.status === 'warning' ? 'yellow' : 'red'
                          }
                        >
                          {deal.health.status}
                        </Badge>
                      </div>
                    </div>
                  </Card>
                  <Card>
                    <div className="p-4">
                      <Text className="text-gray-600">Win Rate</Text>
                      <div className="flex items-center space-x-2">
                        <Metric>{deal.performance.winRate}%</Metric>
                        <Badge color={deal.performance.winRate > 20 ? 'green' : 'red'}>
                          Target: 20%
                        </Badge>
                      </div>
                    </div>
                  </Card>
                  <Card>
                    <div className="p-4">
                      <Text className="text-gray-600">Viewability</Text>
                      <div className="flex items-center space-x-2">
                        <Metric>{deal.performance.viewability}%</Metric>
                        <Badge color={deal.performance.viewability > 70 ? 'green' : 'yellow'}>
                          Target: 70%
                        </Badge>
                      </div>
                    </div>
                  </Card>
                </div>

                <div className="grid grid-cols-2 gap-6">
                  {/* Brand & Agency Info */}
                  <Card>
                    <div className="p-4">
                      <Title>Brand & Agency</Title>
                      <div className="mt-4 space-y-4">
                        <div className="grid grid-cols-2 gap-2">
                          <Text className="text-gray-600">Brand</Text>
                          <Text>{deal.brand.name}</Text>
                        </div>
                        <div className="grid grid-cols-2 gap-2">
                          <Text className="text-gray-600">Agency</Text>
                          <Text>{deal.brand.agency}</Text>
                        </div>
                        <div className="grid grid-cols-2 gap-2">
                          <Text className="text-gray-600">Vertical</Text>
                          <Text>{deal.brand.vertical}</Text>
                        </div>
                        <div className="grid grid-cols-2 gap-2">
                          <Text className="text-gray-600">Region</Text>
                          <Text>{deal.brand.region}</Text>
                        </div>
                      </div>
                    </div>
                  </Card>

                  {/* Deal Setup Info */}
                  <Card>
                    <div className="p-4">
                      <Title>Deal Setup</Title>
                      <div className="mt-4 space-y-4">
                        <div className="grid grid-cols-2 gap-2">
                          <Text className="text-gray-600">Type</Text>
                          <Text>{deal.type}</Text>
                        </div>
                        <div className="grid grid-cols-2 gap-2">
                          <Text className="text-gray-600">Formats</Text>
                          <div className="flex flex-wrap gap-1">
                            {deal.setup.formats.map(format => (
                              <Badge key={format} color="gray">
                                {format}
                              </Badge>
                            ))}
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-2">
                          <Text className="text-gray-600">Audiences</Text>
                          <div className="flex flex-wrap gap-1">
                            {deal.setup.audiences.map(audience => (
                              <Badge key={audience} color="blue">
                                {audience}
                              </Badge>
                            ))}
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-2">
                          <Text className="text-gray-600">Target CPM</Text>
                          <Text>${deal.performance.targetCpm.toFixed(2)}</Text>
                        </div>
                      </div>
                    </div>
                  </Card>

                  {/* Health Issues */}
                  {deal.health.issues.length > 0 && (
                    <Card className="col-span-2">
                      <div className="p-4">
                        <Title>Health Issues</Title>
                        <div className="mt-4 space-y-4">
                          {deal.health.issues.map((issue, index) => (
                            <div 
                              key={index}
                              className="p-4 rounded-lg bg-red-50 border border-red-100"
                            >
                              <div className="flex items-start justify-between">
                                <div>
                                  <Text className="font-medium">{issue.type}</Text>
                                  <Text className="text-sm text-gray-600">
                                    {issue.message}
                                  </Text>
                                </div>
                                <Badge 
                                  color={
                                    issue.severity === 'high' ? 'red' :
                                    issue.severity === 'medium' ? 'yellow' : 'blue'
                                  }
                                >
                                  {issue.severity} severity
                                </Badge>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Card>
                  )}
                </div>
              </TabPanel>

              {/* Performance Panel */}
              <TabPanel>
                <div className="space-y-6">
                  {/* Performance Trends */}
                  <Card>
                    <div className="p-4">
                      <Title>Performance Trends</Title>
                      <div className="h-80 mt-4">
                        <AreaChart
                          className="h-72"
                          data={deal.trends.performance.map((value, index) => ({
                            date: `Day ${index + 1}`,
                            'Win Rate': deal.trends.performance[index],
                            'Health Score': deal.trends.health[index]
                          }))}
                          index="date"
                          categories={['Win Rate', 'Health Score']}
                          colors={['blue', 'green']}
                          valueFormatter={(number: number) => `${number.toFixed(1)}%`}
                          showLegend={true}
                        />
                      </div>
                    </div>
                  </Card>

                  {/* Detailed Metrics */}
                  <div className="grid grid-cols-3 gap-6">
                    <Card>
                      <div className="p-4">
                        <Text className="text-gray-600">Bid Responses</Text>
                        <div className="mt-2">
                          <Metric>{deal.performance.bidResponses}%</Metric>
                          <Text className="text-sm text-gray-500">
                            of total requests
                          </Text>
                        </div>
                      </div>
                    </Card>
                    <Card>
                      <div className="p-4">
                        <Text className="text-gray-600">CPM</Text>
                        <div className="mt-2">
                          <div className="flex items-baseline space-x-2">
                            <Metric>${deal.performance.actualCpm.toFixed(2)}</Metric>
                            <Text className="text-sm text-gray-500">
                              vs ${deal.performance.targetCpm.toFixed(2)} target
                            </Text>
                          </div>
                        </div>
                      </div>
                    </Card>
                    <Card>
                      <div className="p-4">
                        <Text className="text-gray-600">Last Optimized</Text>
                        <div className="mt-2">
                          <Text className="text-lg font-medium">
                            {deal.optimization.lastOptimized ? 
                              new Date(deal.optimization.lastOptimized).toLocaleDateString() :
                              'Never'
                            }
                          </Text>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              </TabPanel>

              {/* Setup Panel */}
              <TabPanel>
                <div className="grid grid-cols-2 gap-6">
                  {/* Targeting Setup */}
                  <Card>
                    <div className="p-4">
                      <Title>Targeting Configuration</Title>
                      <div className="mt-4 space-y-4">
                        <div>
                          <Text className="font-medium">Geographic Targeting</Text>
                          <div className="flex flex-wrap gap-1 mt-2">
                          {deal.setup.targeting.geo.map((geo: string) => (
                            <Badge key={geo} color="gray">
                                {geo}
                            </Badge>
                            ))}

                          </div>
                        </div>
                        <div>
                          <Text className="font-medium">Device Targeting</Text>
                          <div className="flex flex-wrap gap-1 mt-2">
                            {deal.setup.targeting.devices.map((device: string) => (
                              <Badge key={device} color="gray">
                                {device}
                              </Badge>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>

                  {/* Domain Setup */}
                  <Card>
                    <div className="p-4">
                      <Title>Domain Configuration</Title>
                      <div className="mt-4 space-y-4">
                        <div className="flex flex-wrap gap-1">
                          {deal.setup.domains.map(domain => (
                            <Badge key={domain} color="blue">
                              {domain}
                            </Badge>
                          ))}
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </TabPanel>

              {/* History Panel */}
              <TabPanel>
                <Card>
                  <div className="p-4">
                    <Title>Recent Activity</Title>
                    <div className="mt-4 space-y-4">
                      {deal.recentActivity.map((activity, index) => (
                        <div 
                          key={index}
                          className="p-4 rounded-lg border border-gray-200"
                        >
                          <div className="flex items-center justify-between">
                            <div>
                              <Text className="font-medium">
                                {activity.type}
                              </Text>
                              <Text className="text-sm text-gray-600">
                                {activity.message}
                              </Text>
                            </div>
                            <Text className="text-sm text-gray-500">
                              {new Date(activity.timestamp).toLocaleString()}
                            </Text>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </Card>
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </div>
      </div>
    </Dialog>
  );
}