import { Fragment, useState } from 'react';
import { Dialog, Transition, RadioGroup } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Card } from '../ui/Card';
import { ProgressBar } from '@tremor/react';

interface AudienceBuilderProps {
  open: boolean;
  onClose: () => void;
}

const audienceTypes = [
  {
    id: 'custom',
    name: 'Custom Audience',
    description: 'Build a custom audience by combining multiple data sources and rules',
    icon: '🎯'
  },
  {
    id: 'lookalike',
    name: 'Lookalike Audience',
    description: 'Create an audience similar to your best performing segments',
    icon: '👥'
  },
  {
    id: 'contextual',
    name: 'Contextual Audience',
    description: 'Target users based on content and context signals',
    icon: '🔍'
  }
];

const dataSources = [
  { id: 'first-party', name: 'First Party Data' },
  { id: 'third-party', name: 'Third Party Data' },
  { id: 'behavioral', name: 'Behavioral Signals' },
  { id: 'demographic', name: 'Demographic Data' },
  { id: 'interest', name: 'Interest-Based' },
  { id: 'purchase', name: 'Purchase Intent' }
];

export function AudienceBuilder({ open, onClose }: AudienceBuilderProps) {
  const [selectedType, setSelectedType] = useState(audienceTypes[0]);
  const [selectedSources, setSelectedSources] = useState<string[]>([]);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission
    console.log({ selectedType, selectedSources, name, description });
    onClose();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" />
                  </button>
                </div>

                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    <Dialog.Title as="h3" className="text-lg font-semibold leading-6 text-gray-900">
                      Create New Audience
                    </Dialog.Title>

                    <form onSubmit={handleSubmit} className="mt-6">
                      {/* Basic Information */}
                      <div className="space-y-6">
                        <div>
                          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                            Audience Name
                          </label>
                          <input
                            type="text"
                            id="name"
                            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                          />
                        </div>

                        <div>
                          <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                            Description
                          </label>
                          <textarea
                            id="description"
                            rows={3}
                            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </div>

                        {/* Audience Type Selection */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-4">
                            Audience Type
                          </label>
                          <RadioGroup value={selectedType} onChange={setSelectedType}>
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                            {audienceTypes.map((type) => (
                                <RadioGroup.Option
                                  key={type.id}
                                  value={type}
                                  className={({ checked, active }) =>
                                    `${
                                      checked ? 'border-transparent' : 'border-gray-300'
                                    } ${
                                      active ? 'ring-2 ring-blue-600' : ''
                                    } relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none`
                                  }
                                >
                                  {({ checked, active }) => (
                                    <>
                                      <div className="flex flex-1">
                                        <div className="flex flex-col">
                                          <div className="flex items-center">
                                            <span className="mr-3 text-xl">{type.icon}</span>
                                            <RadioGroup.Label
                                              as="span"
                                              className="block text-sm font-medium text-gray-900"
                                            >
                                              {type.name}
                                            </RadioGroup.Label>
                                          </div>
                                          <RadioGroup.Description
                                            as="span"
                                            className="mt-1 flex items-center text-sm text-gray-500"
                                          >
                                            {type.description}
                                          </RadioGroup.Description>
                                        </div>
                                      </div>
                                      <div
                                        className={`${
                                          active ? 'border' : 'border-2'
                                        } ${
                                          checked ? 'border-blue-600' : 'border-transparent'
                                        } absolute -inset-px rounded-lg pointer-events-none`}
                                        aria-hidden="true"
                                      />
                                    </>
                                  )}
                                </RadioGroup.Option>
                              ))}
                            </div>
                          </RadioGroup>
                        </div>

                        {/* Data Sources */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-4">
                            Data Sources
                          </label>
                          <div className="space-y-2">
                            {dataSources.map((source) => (
                              <div key={source.id} className="flex items-center">
                                <input
                                  type="checkbox"
                                  id={source.id}
                                  checked={selectedSources.includes(source.id)}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSelectedSources([...selectedSources, source.id]);
                                    } else {
                                      setSelectedSources(selectedSources.filter(id => id !== source.id));
                                    }
                                  }}
                                  className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                />
                                <label
                                  htmlFor={source.id}
                                  className="ml-3 block text-sm font-medium text-gray-700"
                                >
                                  {source.name}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>

                        {/* Estimated Reach */}
                        <Card>
                          <div className="space-y-4">
                            <h4 className="text-sm font-medium text-gray-900">Estimated Reach</h4>
                            <div className="flex items-center justify-between">
                              <span className="text-2xl font-bold">2.4M</span>
                              <span className="text-sm text-gray-500">users</span>
                            </div>
                            <div className="space-y-1">
                              <div className="flex justify-between text-sm">
                                <span>Match Rate</span>
                                <span>86%</span>
                              </div>
                              <ProgressBar value={86} color="blue" />
                            </div>
                          </div>
                        </Card>
                      </div>

                      {/* Form Actions */}
                      <div className="mt-6 sm:mt-8 sm:flex sm:flex-row-reverse">
                        <button
                          type="submit"
                          className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:ml-3 sm:w-auto"
                        >
                          Create Audience
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                          onClick={onClose}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}