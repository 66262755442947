import { useState } from 'react';
import { Card } from '../ui/Card';
import {
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  ProgressBar,
  AreaChart,
  DonutChart,
  Text,
  Badge,
} from '@tremor/react';
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/20/solid';

interface AudienceSegment {
  id: string;
  name: string;
  type: string;
  size: number;
  growth: number;
  ctr: number;
  conversionRate: number;
  costPerUser: number;
  performance: 'above' | 'on' | 'below';
}

const mockSegments: AudienceSegment[] = [
  {
    id: '1',
    name: 'Auto Intenders',
    type: 'First Party',
    size: 2500000,
    growth: 12,
    ctr: 0.15,
    conversionRate: 2.8,
    costPerUser: 4.25,
    performance: 'above'
  },
  {
    id: '2',
    name: 'Tech Enthusiasts',
    type: 'Third Party',
    size: 1800000,
    growth: 8,
    ctr: 0.12,
    conversionRate: 2.1,
    costPerUser: 3.75,
    performance: 'on'
  },
  {
    id: '3',
    name: 'Luxury Shoppers',
    type: 'Custom',
    size: 900000,
    growth: -2,
    ctr: 0.08,
    conversionRate: 1.5,
    costPerUser: 5.20,
    performance: 'below'
  }
];

const deviceDistribution = [
  { device: 'Desktop', value: 45 },
  { device: 'Mobile', value: 40 },
  { device: 'Tablet', value: 10 },
  { device: 'CTV', value: 5 },
];

const performanceData = [
  {
    date: '2024-01',
    'CTR': 0.12,
    'Conversion Rate': 2.1,
    'Cost per User': 4.0
  },
  {
    date: '2024-02',
    'CTR': 0.14,
    'Conversion Rate': 2.4,
    'Cost per User': 3.8
  },
  {
    date: '2024-03',
    'CTR': 0.15,
    'Conversion Rate': 2.8,
    'Cost per User': 3.6
  }
];

const performanceColors = {
  above: 'emerald',
  on: 'blue',
  below: 'red'
} as const;

export function AudienceInsights() {
  const [selectedSegment, setSelectedSegment] = useState<AudienceSegment | null>(null);

  return (
    <div className="space-y-6">
      {/* Overview Cards */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <Card>
          <Text>Total Reach</Text>
          <div className="mt-2">
            <div className="text-2xl font-bold">5.2M</div>
            <div className="flex items-center mt-1">
              <ArrowUpIcon className="h-4 w-4 text-green-500" />
              <span className="text-sm text-green-500 ml-1">+8.2% vs last month</span>
            </div>
          </div>
        </Card>
        
        <Card>
          <Text>Average Conversion Rate</Text>
          <div className="mt-2">
            <div className="text-2xl font-bold">2.4%</div>
            <div className="flex items-center mt-1">
              <ArrowUpIcon className="h-4 w-4 text-green-500" />
              <span className="text-sm text-green-500 ml-1">+0.3% vs last month</span>
            </div>
          </div>
        </Card>

        <Card>
          <Text>Cost per User</Text>
          <div className="mt-2">
            <div className="text-2xl font-bold">$4.10</div>
            <div className="flex items-center mt-1">
              <ArrowDownIcon className="h-4 w-4 text-green-500" />
              <span className="text-sm text-green-500 ml-1">-$0.20 vs last month</span>
            </div>
          </div>
        </Card>
      </div>

      {/* Performance Charts */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <Card>
          <Text className="font-medium mb-4">Performance Trends</Text>
          <AreaChart
            data={performanceData}
            index="date"
            categories={['CTR', 'Conversion Rate', 'Cost per User']}
            colors={['blue', 'emerald', 'purple']}
            className="h-72"
          />
        </Card>

        <Card>
          <Text className="font-medium mb-4">Device Distribution</Text>
          <DonutChart
            data={deviceDistribution}
            category="value"
            index="device"
            valueFormatter={(value) => `${value}%`}
            colors={['blue', 'cyan', 'indigo', 'violet']}
            className="h-72"
          />
        </Card>
      </div>

      {/* Segments Table */}
      <Card>
        <Text className="font-medium mb-4">Audience Segments</Text>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeaderCell>Segment</TableHeaderCell>
              <TableHeaderCell>Type</TableHeaderCell>
              <TableHeaderCell>Size</TableHeaderCell>
              <TableHeaderCell>Growth</TableHeaderCell>
              <TableHeaderCell>CTR</TableHeaderCell>
              <TableHeaderCell>Conv. Rate</TableHeaderCell>
              <TableHeaderCell>Cost/User</TableHeaderCell>
              <TableHeaderCell>Performance</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mockSegments.map((segment) => (
              <TableRow 
                key={segment.id}
                className="cursor-pointer hover:bg-gray-50"
                onClick={() => setSelectedSegment(segment)}
              >
                <TableCell>
                  <Text className="font-medium">{segment.name}</Text>
                </TableCell>
                <TableCell>{segment.type}</TableCell>
                <TableCell>{segment.size.toLocaleString()}</TableCell>
                <TableCell>
                  <div className="flex items-center">
                    {segment.growth > 0 ? (
                      <ArrowUpIcon className="h-4 w-4 text-green-500 mr-1" />
                    ) : (
                      <ArrowDownIcon className="h-4 w-4 text-red-500 mr-1" />
                    )}
                    {Math.abs(segment.growth)}%
                  </div>
                </TableCell>
                <TableCell>{(segment.ctr * 100).toFixed(2)}%</TableCell>
                <TableCell>{segment.conversionRate.toFixed(1)}%</TableCell>
                <TableCell>${segment.costPerUser.toFixed(2)}</TableCell>
                <TableCell>
                  <Badge color={performanceColors[segment.performance]}>
                    {segment.performance === 'above' ? 'Above Target' :
                     segment.performance === 'on' ? 'On Target' :
                     'Below Target'}
                  </Badge>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </div>
  );
}