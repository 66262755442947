// src/types/dealTypes.ts

// Basic type definitions
export type DealType = 'PMP' | 'Preferred' | 'Programmatic Guaranteed';
export type AudienceType = 'first_party' | 'third_party' | 'contextual' | 'lookalike';
export type InventoryType = 'premium' | 'performance' | 'reach';
export type PricingModelType = 'CPM' | 'CPC' | 'CPCV';
export type BrandSafetyLevel = 'strict' | 'standard' | 'custom';
export type PacingStrategy = 'even' | 'front_loaded' | 'back_loaded';
export type KPIType = 'viewability' | 'ctr' | 'completion' | 'conversion';


// Performance Metrics
export interface PerformanceMetrics {
  impressions: number;
  clicks: number;
  conversions: number;
  viewability: number;
  ctr: number;
  completion_rate?: number;
  avgCpm: number;
}

// Audience related types
export interface FrequencyCaps {
  daily: number | null;
  weekly: number | null;
  total: number | null;
}

export interface AudienceSegment {
    id: string;
    name: string;
    type: AudienceType;
    size: number;
    coverage: number;
    performance: {
      viewability: number;
      ctr: number;
      completion?: number;
      avgCpm: number;
    };
    composition: {
      demographics: Record<string, number>;
      interests: Record<string, number>;
      behaviors: Record<string, number>;
    };
    affinity: number;
    recency: number;
    refreshFrequency: 'daily' | 'weekly' | 'monthly';
  }

export interface AudienceTargeting {
  geography: string[];
  devices: string[];
  dayparting: Record<string, boolean>;
  frequency_caps: FrequencyCaps;
  expansion?: 'none' | 'conservative' | 'moderate' | 'aggressive';
}

export interface AudienceConfiguration {
  segments: AudienceSegment[];
  targeting: AudienceTargeting;
}

// Inventory related types
export interface Domain {
  url: string;
  category: string[];
  quality_score: number;
  brand_safety_score: number;
  performance: PerformanceMetrics;
}

export interface InventoryPackage {
  id: string;
  name: string;
  type: InventoryType;
  description: string;
  domains: Domain[];
  metrics: PerformanceMetrics & {
    totalReach: number;
    uniqueUsers: number;
    averageFrequency: number;
  };
  pricing: {
    floorPrice: number;
    recommendedBid: number;
    historicalClearing: number;
  };
  format_support: string[];
  targeting_capabilities: string[];
  availabilityScore: number;
  qualityScore: number;
  brandSafetyScore: number;
  recommendations?: {  // Added recommendations
    type: string;
    message: string;
    impact: string;
  }[];
}

export interface BrandSafety {
  level: BrandSafetyLevel;
  blocks: string[];
  allow_list?: string[];
  custom_rules?: Array<{
    type: string;
    value: string;
    operator: string;
  }>;
}

export interface InventoryConfiguration {
  packages: InventoryPackage[];
  custom_domains?: string[];
  format_restrictions?: string[];
  brand_safety: BrandSafety;
}

// Budget and pricing types
export interface PricingConfiguration {
  model: PricingModel;
  rate: number;
  floor?: number;
  ceiling?: number;
}

export interface BudgetConfiguration {
  total: number;
  daily: number;
  pacing_strategy: PacingStrategy;
  pricing: PricingConfiguration;
}

// Goals and KPIs
export interface KPI {
  kpi: 'viewability' | 'ctr' | 'completion' | 'conversion';
  target: number;
  min_acceptable?: number;
}

export interface GoalsConfiguration {
  primary: KPI;
  secondary: KPI[];
  pacing: {
    type: PacingStrategy;
    frequency_caps: FrequencyCaps;
  };
}

// Basic deal information
export interface DealBasicInfo {
  name: string;
  advertiser: string;
  type: DealType;
  start_date: string;
  end_date: string;
  objectives: string[];
  description?: string;
}

// Main configuration interface
export interface DealConfiguration {
    basic: DealBasicInfo;
    audience: {
      segments: AudienceSegment[];
      targeting: AudienceTargeting;
    };
    inventory: {
      packages: InventoryPackage[];
      brand_safety: {
        level: BrandSafetyLevel;
        blocks: string[];
        custom_rules: Array<{
          type: string;
          value: string;
          operator: string;
        }>;
      };
    };
    budget: {
      total: number;
      daily: number;
      pacing_strategy: PacingStrategy;
      pricing: PricingModel;
    };
    goals: {
      primary: {
        kpi: 'viewability' | 'ctr' | 'completion' | 'conversion';
        target: number;
      };
      secondary: Array<{
        kpi: 'viewability' | 'ctr' | 'completion' | 'conversion';
        target: number;
      }>;
      pacing: {
        type: PacingStrategy;
        frequency_caps: FrequencyCaps;
      };
    };
  }

// Component props
export interface StepComponentProps {
  configuration: DealConfiguration;
  onUpdate: (updates: Partial<DealConfiguration>) => void;
  onNext?: () => void;
  onBack?: () => void;
  onLaunch?: () => void;
}

// Validation interfaces
export interface ValidationMessage {
  type: 'error' | 'warning' | 'info';
  message: string;
  field?: string;
}

export interface StepValidation {
  isValid: boolean;
  messages: ValidationMessage[];
}

export interface DealBasicInfo {
    name: string;
    advertiser: string;
    type: DealType;
    start_date: string;
    end_date: string;
    objectives: string[];
    description?: string;
  }
  
  export interface FrequencyCaps {
    daily: number | null;
    weekly: number | null;
    total: number | null;
  }
  
  export interface AudienceTargeting {
    geography: string[];
    devices: string[];
    dayparting: Record<string, boolean>;
    frequency_caps: FrequencyCaps;
    expansion?: 'none' | 'conservative' | 'moderate' | 'aggressive';
  }
  
export interface PricingModel {
  model: PricingModelType;
  rate: number;
  floor?: number;
  ceiling?: number;
}

export function createDaypartingRecord(): Record<string, boolean> {
    const record: Record<string, boolean> = {};
    for (let day = 0; day < 7; day++) {
      for (let hour = 0; hour < 24; hour++) {
        record[`${day}-${hour}`] = false;
      }
    }
    return record;
  }
  
  export const initialConfiguration: DealConfiguration = {
    basic: {
      name: '',
      advertiser: '',
      type: 'PMP',
      start_date: '',
      end_date: '',
      objectives: []
    },
    audience: {
      segments: [],
      targeting: {
        geography: [],
        devices: [],
        dayparting: createDaypartingRecord(),
        frequency_caps: {
          daily: null,
          weekly: null,
          total: null
        }
      }
    },
    inventory: {
      packages: [],
      brand_safety: {
        level: 'standard',
        blocks: [],
        custom_rules: []
      }
    },
    budget: {
      total: 0,
      daily: 0,
      pacing_strategy: 'even',
      pricing: {
        model: 'CPM',
        rate: 0,
        floor: 0,
        ceiling: 0
      }
    },
    goals: {
      primary: {
        kpi: 'viewability',
        target: 70
      },
      secondary: [],
      pacing: {
        type: 'even',
        frequency_caps: {
          daily: null,
          weekly: null,
          total: null
        }
      }
    }
  };