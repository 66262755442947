import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import {
  Card,
  Title,
  Text,
  Badge,
  ProgressBar,
  Metric,
  TabGroup,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Select,
  SelectItem,
} from '@tremor/react';
import {
  SparklesIcon,
  AdjustmentsHorizontalIcon,
  ChartBarIcon,
  GlobeAltIcon,
  UsersIcon,
  CurrencyDollarIcon,
  ArrowPathIcon,
  CheckCircleIcon,
  BoltIcon,
  ScaleIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import { Button } from '../../ui/Button';

interface OptimizationOption {
  id: string;
  type: string;
  name: string;
  currentValue: string | number;
  suggestedValue: string | number;
  customValue?: string | number;
  impact: {
    reach?: number;
    performance?: number;
    revenue?: number;
    confidence: number;
  };
  alternatives?: Array<{
    value: string | number;
    impact: {
      reach?: number;
      performance?: number;
      revenue?: number;
    };
  }>;
  reasoning: string;
  risks?: string[];
  dependencies?: string[];
}

interface OptimizationCategory {
  id: string;
  title: string;
  description: string;
  icon: any;
  metrics: Array<{
    name: string;
    value: number;
    target: number;
    trend: number;
  }>;
  options: OptimizationOption[];
  priority: 'high' | 'medium' | 'low';
  status: 'pending' | 'in_progress' | 'completed';
}

const optimizationCategories: OptimizationCategory[] = [
  {
    id: 'audience',
    title: 'Audience Optimization',
    description: 'Optimize audience targeting and segments',
    icon: UsersIcon,
    priority: 'high',
    status: 'pending',
    metrics: [
      {
        name: 'Audience Reach',
        value: 1200000,
        target: 2000000,
        trend: -5
      },
      {
        name: 'Segment Performance',
        value: 75,
        target: 85,
        trend: -2
      }
    ],
    options: [
      {
        id: 'segment-expansion',
        type: 'audience',
        name: 'Segment Expansion',
        currentValue: '2 segments',
        suggestedValue: '4 segments',
        impact: {
          reach: 45,
          performance: 15,
          confidence: 92
        },
        reasoning: 'Adding high-performing look-alike segments can increase reach while maintaining quality',
        alternatives: [
          {
            value: '3 segments',
            impact: {
              reach: 25,
              performance: 10
            }
          },
          {
            value: '5 segments',
            impact: {
              reach: 60,
              performance: 8
            }
          }
        ],
        risks: [
          'Potential dilution of audience quality',
          'Initial performance variance'
        ]
      },
      {
        id: 'targeting-adjustment',
        type: 'audience',
        name: 'Targeting Precision',
        currentValue: 'Broad',
        suggestedValue: 'Balanced',
        impact: {
          performance: 25,
          confidence: 88
        },
        reasoning: 'Current targeting is too broad, leading to suboptimal performance',
        alternatives: [
          {
            value: 'Narrow',
            impact: {
              performance: 35,
              reach: -20
            }
          }
        ]
      }
    ]
  },
  {
    id: 'inventory',
    title: 'Inventory Optimization',
    description: 'Optimize domain targeting and format mix',
    icon: GlobeAltIcon,
    priority: 'medium',
    status: 'pending',
    metrics: [
      {
        name: 'Domain Coverage',
        value: 65,
        target: 85,
        trend: 5
      },
      {
        name: 'Format Efficiency',
        value: 78,
        target: 90,
        trend: -3
      }
    ],
    options: [
      {
        id: 'domain-expansion',
        type: 'inventory',
        name: 'Domain Coverage',
        currentValue: '150 domains',
        suggestedValue: '250 domains',
        impact: {
          reach: 35,
          revenue: 25,
          confidence: 85
        },
        reasoning: 'Additional premium domains available with strong performance history',
        alternatives: [
          {
            value: '200 domains',
            impact: {
              reach: 25,
              revenue: 18
            }
          }
        ],
        dependencies: ['price-adjustment']
      },
      {
        id: 'format-mix',
        type: 'inventory',
        name: 'Format Distribution',
        currentValue: '2 formats',
        suggestedValue: '4 formats',
        impact: {
          reach: 30,
          performance: 15,
          confidence: 90
        },
        reasoning: 'Adding high-performing formats can increase reach and engagement'
      }
    ]
  },
  {
    id: 'pricing',
    title: 'Pricing Strategy',
    description: 'Optimize bid prices and floor rates',
    icon: CurrencyDollarIcon,
    priority: 'high',
    status: 'pending',
    metrics: [
      {
        name: 'Win Rate',
        value: 25,
        target: 40,
        trend: -8
      },
      {
        name: 'Revenue Impact',
        value: 85,
        target: 100,
        trend: -5
      }
    ],
    options: [
      {
        id: 'floor-adjustment',
        type: 'pricing',
        name: 'Floor Price',
        currentValue: '$12.50',
        suggestedValue: '$10.80',
        impact: {
          performance: 35,
          revenue: 15,
          confidence: 94
        },
        reasoning: 'Current floor price is above market clearing rate for similar inventory',
        alternatives: [
          {
            value: '$11.20',
            impact: {
              performance: 25,
              revenue: 10
            }
          },
          {
            value: '$10.00',
            impact: {
              performance: 40,
              revenue: 5
            }
          }
        ]
      }
    ]
  }
];

interface DealOptimizationWizardProps {
  dealId: string | null;
  onClose: () => void;
}

export function DealOptimizationWizard({ dealId, onClose }: DealOptimizationWizardProps) {
  const [activeCategory, setActiveCategory] = useState<string>(optimizationCategories[0].id);
  const [selectedOptions, setSelectedOptions] = useState<Record<string, any>>({});
  const [customValues, setCustomValues] = useState<Record<string, any>>({});
  const [previewMode, setPreviewMode] = useState<boolean>(false);
  const [isAnalyzing, setIsAnalyzing] = useState<boolean>(false);

  // Calculate total impact of selected optimizations
  const calculateTotalImpact = () => {
    let totalImpact = {
      reach: 0,
      performance: 0,
      revenue: 0
    };

    Object.entries(selectedOptions).forEach(([optionId, isSelected]) => {
      if (isSelected) {
        const option = optimizationCategories
          .flatMap(cat => cat.options)
          .find(opt => opt.id === optionId);

        if (option?.impact) {
          totalImpact.reach += option.impact.reach || 0;
          totalImpact.performance += option.impact.performance || 0;
          totalImpact.revenue += option.impact.revenue || 0;
        }
      }
    });

    return totalImpact;
  };

  // Render impact badge
  const ImpactBadge = ({ value, type }: { value: number; type: string }) => (
    <Badge
      color={value > 25 ? 'green' : value > 10 ? 'yellow' : 'red'}
      icon={ChartBarIcon}
    >
      +{value}% {type}
    </Badge>
  );

  // Render optimization option card
  const OptimizationOptionCard = ({ option }: { option: OptimizationOption }) => (
    <Card className="p-4">
      <div className="flex items-start justify-between mb-4">
        <div className="flex-1">
          <div className="flex items-center space-x-2">
            <Text className="font-medium">{option.name}</Text>
            <Badge color={option.impact.confidence > 85 ? 'green' : 'yellow'}>
              {option.impact.confidence}% confidence
            </Badge>
          </div>
          <Text className="text-sm text-gray-600 mt-1">
            {option.reasoning}
          </Text>
        </div>
        <input
          type="checkbox"
          checked={selectedOptions[option.id] || false}
          onChange={(e) => setSelectedOptions(prev => ({
            ...prev,
            [option.id]: e.target.checked
          }))}
          className="h-4 w-4 rounded border-gray-300"
        />
      </div>

      {selectedOptions[option.id] && (
        <div className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <Text className="text-sm text-gray-600">Current</Text>
              <Text className="font-medium">{option.currentValue}</Text>
            </div>
            <div>
              <Text className="text-sm text-gray-600">Suggested</Text>
              <Text className="font-medium">{option.suggestedValue}</Text>
            </div>
          </div>

          {option.alternatives && (
            <div>
              <Text className="text-sm text-gray-600 mb-2">Optimization Level</Text>
              <Select
                value={customValues[option.id] || option.suggestedValue}
                onValueChange={(value) => setCustomValues(prev => ({
                  ...prev,
                  [option.id]: value
                }))}
              >
                <SelectItem value={String(option.suggestedValue)}>
                  Recommended
                </SelectItem>

                {option.alternatives.map((alt, index) => (
                  <SelectItem key={index} value={alt.value.toString()}>
                    {typeof alt.value === 'number' ? 
                      `Custom Level ${index + 1}` : 
                      alt.value}
                  </SelectItem>
                ))}
              </Select>
            </div>
          )}

          <div className="flex flex-wrap gap-2">
            {option.impact.reach && (
              <ImpactBadge value={option.impact.reach} type="reach" />
            )}
            {option.impact.performance && (
              <ImpactBadge value={option.impact.performance} type="performance" />
            )}
            {option.impact.revenue && (
              <ImpactBadge value={option.impact.revenue} type="revenue" />
            )}
          </div>

          {option.risks && (
            <div className="mt-4">
              <Text className="text-sm text-gray-600 mb-2">Potential Risks</Text>
              <ul className="list-disc list-inside space-y-1">
                {option.risks.map((risk, index) => (
                  <li key={index} className="text-sm text-gray-600">{risk}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </Card>
  );

  return (
    <Dialog 
      open={true} 
      onClose={onClose}
      className="fixed inset-0 z-50 overflow-y-auto"
    >
      <div className="flex min-h-screen items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />

        <div className="relative bg-white w-full max-w-5xl rounded-xl shadow-xl p-6">
          {/* Header */}
          <div className="flex items-center justify-between mb-6">
            <div>
              <Dialog.Title className="text-xl font-semibold">
                Advanced Deal Optimization
              </Dialog.Title>
              <Text className="text-gray-500">
                Customize and preview optimization strategies
              </Text>
            </div>
            <Button variant="ghost" size="sm" onClick={onClose}>
              <XMarkIcon className="h-5 w-5" />
            </Button>
          </div>

          {/* Main Content */}
          <div className="grid grid-cols-12 gap-6">
            {/* Left Sidebar - Categories */}
            <div className="col-span-3 space-y-2">
              {optimizationCategories.map(category => (
                <button
                  key={category.id}
                  onClick={() => setActiveCategory(category.id)}
                  className={`w-full text-left p-3 rounded-lg transition-all ${
                    activeCategory === category.id
                      ? 'bg-blue-50 border-blue-200 border'
                      : 'hover:bg-gray-50'
                  }`}
                >
                  <div className="flex items-center space-x-3">
                    <category.icon className={`h-5 w-5 ${
                      activeCategory === category.id
                        ? 'text-blue-500'
                        : 'text-gray-400'
                    }`} />
                    <div>
                      <Text className="font-medium">{category.title}</Text>
                      <Badge 
                        color={
                          category.priority === 'high' ? 'red' :
                          category.priority === 'medium' ? 'yellow' : 'gray'
                        }
                        className="mt-1"
                      >
                        {category.priority} priority
                      </Badge>
                    </div>
                  </div>
                </button>
              ))}
            </div>

            {/* Main Content Area */}
            <div className="col-span-9">
              <TabGroup>
                <TabList className="flex space-x-2 mb-6">
                  <Tab className="ui-selected:bg-blue-500 ui-selected:text-white px-4 py-2 rounded-lg">
                    Optimization Options
                  </Tab>
                  <Tab className="ui-selected:bg-blue-500 ui-selected:text-white px-4 py-2 rounded-lg">
                    Impact Analysis
                  </Tab>
                  <Tab className="ui-selected:bg-blue-500 ui-selected:text-white px-4 py-2 rounded-lg">
                    Dependencies
                  </Tab>
                </TabList>

                <TabPanels>
                  {/* Optimization Options Panel */}
                  <TabPanel>
                    <div className="space-y-4">
                      {optimizationCategories
                        .find(cat => cat.id === activeCategory)
                        ?.options.map(option => (
                          <OptimizationOptionCard 
                            key={option.id} 
                            option={option}
                          />
                        ))}
                        </div>
                      </TabPanel>
    
                      {/* Impact Analysis Panel */}
                      <TabPanel>
                        <div className="space-y-6">
                          {/* Overall Impact Summary */}
                          <Card className="bg-blue-50">
                            <div className="p-4">
                              <Text className="font-medium mb-4">
                                Projected Impact Analysis
                              </Text>
                              <div className="grid grid-cols-3 gap-4">
                                <div>
                                  <Text className="text-sm text-gray-600">Reach Impact</Text>
                                  <div className="flex items-baseline space-x-2">
                                    <Metric>+{calculateTotalImpact().reach}%</Metric>
                                    <Text className="text-sm text-gray-600">estimated</Text>
                                  </div>
                                </div>
                                <div>
                                  <Text className="text-sm text-gray-600">Performance Gain</Text>
                                  <div className="flex items-baseline space-x-2">
                                    <Metric>+{calculateTotalImpact().performance}%</Metric>
                                    <Text className="text-sm text-gray-600">projected</Text>
                                  </div>
                                </div>
                                <div>
                                  <Text className="text-sm text-gray-600">Revenue Impact</Text>
                                  <div className="flex items-baseline space-x-2">
                                    <Metric>+{calculateTotalImpact().revenue}%</Metric>
                                    <Text className="text-sm text-gray-600">potential</Text>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card>
    
                          {/* Detailed Metrics Impact */}
                          <div className="space-y-4">
                            {Object.entries(selectedOptions)
                              .filter(([_, isSelected]) => isSelected)
                              .map(([optionId]) => {
                                const option = optimizationCategories
                                  .flatMap(cat => cat.options)
                                  .find(opt => opt.id === optionId);
                                
                                if (!option) return null;
    
                                return (
                                  <Card key={optionId} className="p-4">
                                    <div className="space-y-4">
                                      <div className="flex items-center justify-between">
                                        <Text className="font-medium">{option.name}</Text>
                                        <Badge color="blue">
                                          {option.impact.confidence}% confidence
                                        </Badge>
                                      </div>
    
                                      <div className="grid grid-cols-2 gap-4">
                                        <div>
                                          <Text className="text-sm text-gray-600 mb-2">Current State</Text>
                                          <div className="p-3 bg-gray-50 rounded-lg">
                                            <Text>{option.currentValue}</Text>
                                          </div>
                                        </div>
                                        <div>
                                          <Text className="text-sm text-gray-600 mb-2">
                                            {customValues[optionId] ? 'Custom Value' : 'Recommended Value'}
                                          </Text>
                                          <div className="p-3 bg-blue-50 rounded-lg">
                                            <Text>{customValues[optionId] || option.suggestedValue}</Text>
                                          </div>
                                        </div>
                                      </div>
    
                                      {/* Impact Metrics */}
                                      <div>
                                        <Text className="text-sm text-gray-600 mb-2">Projected Impact</Text>
                                        <div className="grid grid-cols-3 gap-4">
                                          {option.impact.reach && (
                                            <div className="p-3 bg-gray-50 rounded-lg">
                                              <Text className="text-sm text-gray-600">Reach</Text>
                                              <Text className="font-medium text-green-600">
                                                +{option.impact.reach}%
                                              </Text>
                                            </div>
                                          )}
                                          {option.impact.performance && (
                                            <div className="p-3 bg-gray-50 rounded-lg">
                                              <Text className="text-sm text-gray-600">Performance</Text>
                                              <Text className="font-medium text-green-600">
                                                +{option.impact.performance}%
                                              </Text>
                                            </div>
                                          )}
                                          {option.impact.revenue && (
                                            <div className="p-3 bg-gray-50 rounded-lg">
                                              <Text className="text-sm text-gray-600">Revenue</Text>
                                              <Text className="font-medium text-green-600">
                                                +{option.impact.revenue}%
                                              </Text>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </Card>
                                );
                            })}
                          </div>
                        </div>
                      </TabPanel>
    
                      {/* Dependencies Panel */}
                      <TabPanel>
                        <Card className="p-4">
                          <div className="space-y-4">
                            <Text className="font-medium">Optimization Dependencies</Text>
                            {Object.entries(selectedOptions)
                              .filter(([_, isSelected]) => isSelected)
                              .map(([optionId]) => {
                                const option = optimizationCategories
                                  .flatMap(cat => cat.options)
                                  .find(opt => opt.id === optionId);
                                
                                if (!option?.dependencies?.length) return null;
    
                                return (
                                  <div key={optionId} className="p-4 border rounded-lg">
                                    <Text className="font-medium mb-2">{option.name}</Text>
                                    <div className="space-y-2">
                                      {option.dependencies.map((dep, index) => {
                                        const depOption = optimizationCategories
                                          .flatMap(cat => cat.options)
                                          .find(opt => opt.id === dep);
    
                                        return (
                                          <div key={index} className="flex items-center space-x-2">
                                            <div className="h-2 w-2 rounded-full bg-blue-500" />
                                            <Text className="text-sm">
                                              Requires: {depOption?.name || dep}
                                            </Text>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                );
                            })}
                          </div>
                        </Card>
                      </TabPanel>
                    </TabPanels>
                  </TabGroup>
                </div>
              </div>
    
              {/* Footer Actions */}
              <div className="flex justify-between mt-6 pt-6 border-t">
                <Button
                  variant="secondary"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <div className="flex space-x-2">
                  <Button
                    variant="secondary"
                    onClick={() => setPreviewMode(!previewMode)}
                  >
                    {previewMode ? 'Edit Optimizations' : 'Preview Changes'}
                  </Button>
                  <Button
                    variant="primary"
                    disabled={Object.values(selectedOptions).filter(Boolean).length === 0}
                    onClick={async () => {
                      setIsAnalyzing(true);
                      // Simulate API call
                      await new Promise(resolve => setTimeout(resolve, 2000));
                      setIsAnalyzing(false);
                      // Handle optimization implementation
                    }}
                  >
                    {isAnalyzing ? (
                      <>
                        <ArrowPathIcon className="h-4 w-4 mr-2 animate-spin" />
                        Analyzing...
                      </>
                    ) : (
                      'Apply Optimizations'
                    )}
                  </Button>
                </div>
              </div>
    
              {/* Preview Modal */}
              {previewMode && (
                <Dialog
                  open={previewMode}
                  onClose={() => setPreviewMode(false)}
                  className="fixed inset-0 z-50 overflow-y-auto"
                >
                  <div className="flex items-center justify-center min-h-screen">
                    <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
                    <div className="relative bg-white rounded-xl shadow-xl p-6 max-w-2xl w-full">
                      <div className="space-y-4">
                        <Dialog.Title className="text-lg font-medium">
                          Preview Optimization Changes
                        </Dialog.Title>
                        {/* Add preview content */}
                      </div>
                    </div>
                  </div>
                </Dialog>
              )}
            </div>
          </div>
        </Dialog>
      );
    }