import { Card } from '../ui/Card';
import { Title, AreaChart, DonutChart } from '@tremor/react';
import { BoltIcon, ChartBarIcon, ArrowTrendingUpIcon } from '@heroicons/react/24/outline';

const predictedPerformance = [
  {
    date: '2024-01',
    'Predicted CTR': 0.12,
    'Predicted VCR': 0.75,
    'Confidence': 0.85
  },
  {
    date: '2024-02',
    'Predicted CTR': 0.15,
    'Predicted VCR': 0.78,
    'Confidence': 0.82
  },
  {
    date: '2024-03',
    'Predicted CTR': 0.18,
    'Predicted VCR': 0.82,
    'Confidence': 0.79
  },
  {
    date: '2024-04',
    'Predicted CTR': 0.21,
    'Predicted VCR': 0.85,
    'Confidence': 0.75
  }
];

const recommendations = [
  {
    id: '1',
    title: 'Increase bid for premium inventory',
    impact: '+15% predicted CTR',
    confidence: 92
  },
  {
    id: '2',
    title: 'Expand to similar audiences',
    impact: '+25% predicted reach',
    confidence: 88
  },
  {
    id: '3',
    title: 'Optimize for viewability',
    impact: '+10% predicted VCR',
    confidence: 85
  }
];

export function PerformancePredictor() {
  return (
    <Card>
      <div className="space-y-6">
        <div className="flex items-start justify-between">
          <div>
            <Title className="text-base font-medium">Performance Predictor</Title>
            <p className="text-sm text-gray-500 mt-1">
              AI-powered performance forecasting and recommendations
            </p>
          </div>
          <span className="inline-flex items-center rounded-full bg-blue-50 px-2.5 py-0.5 text-xs font-medium text-blue-700">
            AI Powered
          </span>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* Performance Chart */}
          <div>
            <h4 className="text-sm font-medium text-gray-900 mb-4">Predicted Performance</h4>
            <AreaChart
              className="h-72"
              data={predictedPerformance}
              index="date"
              categories={['Predicted CTR', 'Predicted VCR']}
              colors={['blue', 'purple']}
              showLegend={true}
              showGridLines={false}
            />
          </div>

          {/* Recommendations */}
          <div>
            <h4 className="text-sm font-medium text-gray-900 mb-4">Optimization Recommendations</h4>
            <div className="space-y-4">
              {recommendations.map((rec) => (
                <div
                  key={rec.id}
                  className="flex items-start gap-3 p-3 rounded-lg bg-gray-50"
                >
                  <div className="p-2 rounded-lg bg-blue-50 text-blue-700">
                    <BoltIcon className="h-5 w-5" />
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-900">{rec.title}</p>
                    <div className="flex items-center gap-2 mt-1">
                      <span className="text-sm text-green-600 font-medium">
                        {rec.impact}
                      </span>
                      <span className="text-xs text-gray-500">
                        {rec.confidence}% confidence
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="border-t border-gray-100 pt-4">
          <button className="inline-flex items-center text-sm font-medium text-blue-600 hover:text-blue-700">
            <ArrowTrendingUpIcon className="h-4 w-4 mr-1" />
            View Detailed Forecast
          </button>
        </div>
      </div>
    </Card>
  );
}