import { Card } from '../ui/Card';
import { Title } from '@tremor/react';
import { 
  SparklesIcon, 
  ArrowTrendingUpIcon, 
  BoltIcon,
  ChartBarIcon 
} from '@heroicons/react/24/outline';

interface Insight {
  id: string;
  type: 'opportunity' | 'trend' | 'action' | 'performance';
  title: string;
  description: string;
  timestamp: string;
  impact: 'high' | 'medium' | 'low';
  category: string;
}

const insights: Insight[] = [
  {
    id: '1',
    type: 'opportunity',
    title: 'New audience segment detected',
    description: 'High-value users showing strong engagement in automotive category',
    timestamp: '2 hours ago',
    impact: 'high',
    category: 'Audiences'
  },
  {
    id: '2',
    type: 'trend',
    title: 'Rising CTR in tech vertical',
    description: 'Tech-focused inventory showing 25% higher CTR than average',
    timestamp: '4 hours ago',
    impact: 'medium',
    category: 'Performance'
  },
  {
    id: '3',
    type: 'action',
    title: 'Optimization opportunity',
    description: 'Adjust bid strategy for premium inventory to improve win rate',
    timestamp: '6 hours ago',
    impact: 'high',
    category: 'Optimization'
  }
];

const typeIcons = {
  opportunity: SparklesIcon,
  trend: ArrowTrendingUpIcon,
  action: BoltIcon,
  performance: ChartBarIcon
};

const impactColors = {
  high: 'text-green-700 bg-green-50',
  medium: 'text-yellow-700 bg-yellow-50',
  low: 'text-gray-700 bg-gray-50'
};

export function InsightsFeed() {
  return (
    <Card>
      <div className="flex items-center justify-between mb-4">
        <div>
          <Title className="text-base font-medium">Latest Insights</Title>
          <p className="text-sm text-gray-500 mt-1">AI-generated insights based on your data</p>
        </div>
      </div>

      <div className="space-y-4">
        {insights.map((insight) => {
          const Icon = typeIcons[insight.type];
          
          return (
            <div
              key={insight.id}
              className="flex gap-4 p-4 rounded-lg bg-gray-50 hover:bg-gray-100 transition-colors cursor-pointer"
            >
              <div className={`${impactColors[insight.impact]} p-2 rounded-lg h-fit`}>
                <Icon className="h-5 w-5" />
              </div>
              
              <div className="flex-1 min-w-0">
                <div className="flex items-start justify-between mb-1">
                  <p className="text-sm font-medium text-gray-900">
                    {insight.title}
                  </p>
                  <span className="ml-2 text-xs text-gray-500">
                    {insight.timestamp}
                  </span>
                </div>
                <p className="text-sm text-gray-600 mb-2">
                  {insight.description}
                </p>
                <div className="flex items-center gap-2">
                  <span className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-medium ${impactColors[insight.impact]}`}>
                    {insight.impact} impact
                  </span>
                  <span className="text-xs text-gray-500">
                    {insight.category}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <button className="w-full mt-4 text-sm text-blue-600 hover:text-blue-700 font-medium">
        View All Insights
      </button>
    </Card>
  );
}