import { Card } from '../ui/Card';
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/20/solid';

export interface MetricCardProps {
  title: string;
  value: string;
  change: string;
  changeType: 'increase' | 'decrease' | 'neutral';
  description: string;
  icon: React.ComponentType<{ className?: string }>;
}

export function MetricCard({
  title,
  value,
  change,
  changeType,
  description,
  icon: Icon
}: MetricCardProps) {
  const getChangeColor = () => {
    switch (changeType) {
      case 'increase':
        return 'text-green-600 bg-green-50';
      case 'decrease':
        return 'text-red-600 bg-red-50';
      default:
        return 'text-gray-600 bg-gray-50';
    }
  };

  return (
    <Card>
      <div className="flex items-start justify-between">
        <div>
          <p className="text-sm font-medium text-gray-500">{title}</p>
          <div className="mt-2 flex items-baseline gap-2">
            <p className="text-2xl font-semibold text-gray-900">{value}</p>
            <span className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium ${getChangeColor()}`}>
              {changeType === 'increase' && <ArrowUpIcon className="mr-1 h-3 w-3" />}
              {changeType === 'decrease' && <ArrowDownIcon className="mr-1 h-3 w-3" />}
              {change}
            </span>
          </div>
          <p className="mt-1 text-sm text-gray-500">{description}</p>
        </div>
        <div className={`p-3 rounded-lg ${getChangeColor()}`}>
          <Icon className="h-5 w-5" />
        </div>
      </div>
    </Card>
  );
}