import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import {
  Card,
  Title,
  Text,
  Badge,
  ProgressBar,
  Metric,
  Flex,
  TabGroup,
  TabList,
  Tab,
  TabPanels,
  TabPanel
} from '@tremor/react';
import {
  ArrowPathIcon,
  CheckCircleIcon,
  XMarkIcon,
  ExclamationTriangleIcon,
  ChartBarIcon,
  SignalIcon,
  CpuChipIcon,
  GlobeAltIcon,
  CurrencyDollarIcon
} from '@heroicons/react/24/outline';
import { Button } from '../../ui/Button';

interface HealthCheckMetric {
  name: string;
  value: number;
  target: number;
  status: 'success' | 'warning' | 'error';
  trend: number;
  history: Array<{ date: string; value: number }>;
}

interface HealthCheckStep {
  id: string;
  title: string;
  description: string;
  status: 'pending' | 'in_progress' | 'completed' | 'failed';
  metrics: HealthCheckMetric[];
  insights: Array<{
    type: 'issue' | 'recommendation' | 'info';
    message: string;
    impact: 'high' | 'medium' | 'low';
    action?: string;
    reasoning?: string;
  }>;
}

const healthCheckSteps: HealthCheckStep[] = [
  {
    id: 'bid-request',
    title: 'Bid Request Analysis',
    description: 'Analyzing bid request patterns and volume',
    status: 'pending',
    metrics: [
      {
        name: 'Request Volume',
        value: 15000,
        target: 20000,
        status: 'warning',
        trend: -5,
        history: [
          { date: '2024-01-15', value: 18000 },
          { date: '2024-01-16', value: 16500 },
          { date: '2024-01-17', value: 15000 }
        ]
      },
      {
        name: 'Request Quality',
        value: 85,
        target: 90,
        status: 'warning',
        trend: -2,
        history: [
          { date: '2024-01-15', value: 88 },
          { date: '2024-01-16', value: 87 },
          { date: '2024-01-17', value: 85 }
        ]
      }
    ],
    insights: [
      {
        type: 'issue',
        message: 'Bid request volume below target threshold',
        impact: 'high',
        reasoning: 'Current volume (15K) is 25% below expected level (20K), limiting potential reach',
        action: 'Review targeting settings and domain restrictions'
      },
      {
        type: 'recommendation',
        message: 'Consider expanding geographic targeting',
        impact: 'medium',
        reasoning: '35% of premium inventory available in unexplored regions',
        action: 'Add recommended geos to targeting'
      }
    ]
  },
  {
    id: 'bid-response',
    title: 'Bid Response Evaluation',
    description: 'Checking DSP response patterns and bid rates',
    status: 'pending',
    metrics: [
      {
        name: 'Response Rate',
        value: 65,
        target: 80,
        status: 'error',
        trend: -8,
        history: [
          { date: '2024-01-15', value: 75 },
          { date: '2024-01-16', value: 70 },
          { date: '2024-01-17', value: 65 }
        ]
      },
      {
        name: 'Bid Price Delta',
        value: -15,
        target: 0,
        status: 'error',
        trend: -5,
        history: [
          { date: '2024-01-15', value: -8 },
          { date: '2024-01-16', value: -12 },
          { date: '2024-01-17', value: -15 }
        ]
      }
    ],
    insights: [
      {
        type: 'issue',
        message: 'Low bid response rate indicating setup issues',
        impact: 'high',
        reasoning: 'Response rate dropped 15% in last 3 days, DSP targeting may be too restrictive',
        action: 'Verify DSP targeting alignment'
      },
      {
        type: 'issue',
        message: 'Bid prices consistently below floor price',
        impact: 'high',
        reasoning: 'Average bid price is 15% below floor, leading to low win rate',
        action: 'Review pricing strategy'
      }
    ]
  },
  {
    id: 'win-rate',
    title: 'Win Rate Analysis',
    description: 'Analyzing auction win patterns and pricing',
    status: 'pending',
    metrics: [
      {
        name: 'Win Rate',
        value: 22,
        target: 35,
        status: 'warning',
        trend: -3,
        history: [
          { date: '2024-01-15', value: 28 },
          { date: '2024-01-16', value: 25 },
          { date: '2024-01-17', value: 22 }
        ]
      }
    ],
    insights: [
      {
        type: 'recommendation',
        message: 'Adjust floor price to improve win rate',
        impact: 'high',
        reasoning: 'Analysis shows optimal floor price range between $8-10 CPM',
        action: 'Update floor price'
      }
    ]
  }
];

interface DealHealthWizardProps {
  dealId: string | null;
  onClose: () => void;
}

export function DealHealthWizard({ dealId, onClose }: DealHealthWizardProps) {
  const [currentStep, setCurrentStep] = useState(0);
  const [results, setResults] = useState<Record<string, any>>({});
  const [isChecking, setIsChecking] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  // Run health check for current step
  const runHealthCheck = async () => {
    setIsChecking(true);
    await new Promise(resolve => setTimeout(resolve, 2000));
    
    const step = healthCheckSteps[currentStep];
    setResults(prev => ({
      ...prev,
      [step.id]: {
        metrics: step.metrics,
        insights: step.insights
      }
    }));
    
    setIsChecking(false);
  };

  // Render metric card with detailed info
  const MetricCard = ({ metric }: { metric: HealthCheckMetric }) => (
    <Card className="p-4">
      <Text className="text-sm text-gray-600">{metric.name}</Text>
      <div className="mt-2 space-y-2">
        <div className="flex items-end justify-between">
          <Metric>{metric.value}</Metric>
          <Badge 
            color={metric.status === 'success' ? 'green' : 
                  metric.status === 'warning' ? 'yellow' : 'red'}
            icon={metric.trend > 0 ? ChartBarIcon : undefined}
          >
            {metric.trend > 0 ? `+${metric.trend}%` : `${metric.trend}%`}
          </Badge>
        </div>
        <ProgressBar
          value={(metric.value / metric.target) * 100}
          color={metric.status === 'success' ? 'green' : 
                metric.status === 'warning' ? 'yellow' : 'red'}
        />
        <Text className="text-xs text-gray-500">
          Target: {metric.target}
        </Text>
      </div>
    </Card>
  );

  // Render insight card with actions
  const InsightCard = ({ insight }: { insight: any }) => (
    <Card className={`p-4 ${
      insight.type === 'issue' ? 'bg-red-50' :
      insight.type === 'recommendation' ? 'bg-blue-50' : 'bg-gray-50'
    }`}>
      <div className="flex items-start space-x-3">
        {insight.type === 'issue' ? (
          <ExclamationTriangleIcon className="h-5 w-5 text-red-500 mt-0.5" />
        ) : (
          <CheckCircleIcon className="h-5 w-5 text-blue-500 mt-0.5" />
        )}
        <div className="flex-1">
          <Text className="font-medium">{insight.message}</Text>
          {insight.reasoning && (
            <Text className="text-sm text-gray-600 mt-1">
              {insight.reasoning}
            </Text>
          )}
          {insight.action && (
            <div className="mt-3">
              <Button
                variant="secondary"
                size="sm"
                className="text-sm"
              >
                {insight.action}
              </Button>
            </div>
          )}
        </div>
        <Badge
          color={
            insight.impact === 'high' ? 'red' :
            insight.impact === 'medium' ? 'yellow' : 'blue'
          }
        >
          {insight.impact} impact
        </Badge>
      </div>
    </Card>
  );

  return (
    <Dialog 
      open={true} 
      onClose={onClose}
      className="fixed inset-0 z-50 overflow-y-auto"
    >
      <div className="flex min-h-screen items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />

        <div className="relative bg-white w-full max-w-4xl rounded-xl shadow-xl p-6">
          {/* Header */}
          <div className="flex items-center justify-between mb-6">
            <div>
              <Dialog.Title className="text-xl font-semibold">
                Comprehensive Health Check
              </Dialog.Title>
              <Text className="text-gray-500">
                Step {currentStep + 1} of {healthCheckSteps.length}:
                {' '}{healthCheckSteps[currentStep].title}
              </Text>
            </div>
            <Button variant="ghost" size="sm" onClick={onClose}>
              <XMarkIcon className="h-5 w-5" />
            </Button>
          </div>

          {/* Progress Indicator */}
          <div className="relative mb-8">
            <div className="absolute top-0 left-0 w-full h-1 bg-gray-200 rounded">
              <div
                className="absolute h-full bg-blue-600 rounded transition-all duration-500"
                style={{
                  width: `${((currentStep + 1) / healthCheckSteps.length) * 100}%`
                }}
              />
            </div>
          </div>

          {/* Main Content */}
          <div className="space-y-6">
            {/* Current Step Info */}
            <div className="bg-blue-50 rounded-lg p-4">
              <Text className="text-blue-800">
                {healthCheckSteps[currentStep].description}
              </Text>
            </div>

            {/* Results Section */}
            {results[healthCheckSteps[currentStep].id] ? (
              <div className="space-y-6">
                <TabGroup>
                  <TabList className="flex space-x-2">
                    <Tab className="ui-selected:bg-blue-500 ui-selected:text-white px-4 py-2 rounded-lg">
                      Metrics
                    </Tab>
                    <Tab className="ui-selected:bg-blue-500 ui-selected:text-white px-4 py-2 rounded-lg">
                      Insights
                    </Tab>
                    <Tab className="ui-selected:bg-blue-500 ui-selected:text-white px-4 py-2 rounded-lg">
                      History
                    </Tab>
                  </TabList>

                  <TabPanels>
                    {/* Metrics Panel */}
                    <TabPanel>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                        {results[healthCheckSteps[currentStep].id].metrics.map((metric: HealthCheckMetric, index: number) => (
                          <MetricCard key={index} metric={metric} />
                        ))}
                      </div>
                    </TabPanel>

                    {/* Insights Panel */}
                    <TabPanel>
                      <div className="space-y-4 mt-4">
                        {results[healthCheckSteps[currentStep].id].insights.map((insight: any, index: number) => (
                          <InsightCard key={index} insight={insight} />
                        ))}
                      </div>
                    </TabPanel>

                    {/* History Panel */}
                    <TabPanel>
                      {/* Add historical trend visualization here */}
                    </TabPanel>
                  </TabPanels>
                </TabGroup>
              </div>
            ) : (
              <div className="text-center py-12">
                <Button
                  variant="primary"
                  onClick={runHealthCheck}
                  disabled={isChecking}
                >
                  {isChecking ? (
                    <>
                      <ArrowPathIcon className="h-5 w-5 mr-2 animate-spin" />
                      Running Checks...
                    </>
                  ) : (
                    'Start Health Check'
                  )}
                </Button>
              </div>
            )}

            {/* Actions */}
            <div className="flex justify-between pt-6 border-t">
              <Button
                variant="secondary"
                onClick={onClose}
                disabled={isChecking}
              >
                Cancel
              </Button>
              <div className="flex space-x-2">
                <Button
                  variant="secondary"
                  onClick={() => setCurrentStep(prev => prev - 1)}
                  disabled={currentStep === 0 || isChecking}
                >
                  Previous
                </Button>
                <Button
                  variant="primary"
                  onClick={() => setCurrentStep(prev => prev + 1)}
                  disabled={
                    currentStep === healthCheckSteps.length - 1 || 
                    isChecking || 
                    !results[healthCheckSteps[currentStep].id]
                  }
                >
                  Next Step
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}