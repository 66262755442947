import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useState } from 'react';
import { UserRoleContext } from './context/UserRoleContext';
import MainLayout from './layouts/MainLayout';
import { routes } from './config/routes';
import { AuthProvider } from './auth/AuthContext';
import { ProtectedRoute } from './auth/ProtectedRoute';
import { Login } from './pages/Login';

const roles = ['Sales', 'Account Management', 'Deal Operations', 'DSP Teams', 'Strategic Partnerships'];

export default function App() {
  const [currentRole, setCurrentRole] = useState(roles[0]);

  return (
    <BrowserRouter>
      <AuthProvider>
        <UserRoleContext.Provider value={{ currentRole, setCurrentRole, availableRoles: roles }}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/*"
              element={
                <ProtectedRoute>
                  <div className="min-h-screen bg-gray-50">
                    <MainLayout>
                      <Routes>
                        {routes.map((route) => (
                          <Route
                            key={route.path}
                            path={route.path}
                            element={route.element}
                          />
                        ))}
                      </Routes>
                    </MainLayout>
                  </div>
                </ProtectedRoute>
              }
            />
          </Routes>
        </UserRoleContext.Provider>
      </AuthProvider>
    </BrowserRouter>
  );
}