import { useState } from 'react';
import { Card } from '../ui/Card';
import {
  AreaChart,
  LineChart,
  BarChart,
  Text,
  Badge,
  ProgressBar,
  Tab,
  TabList,
  TabGroup,
  TabPanel,
  TabPanels,
} from '@tremor/react';
import {
  ArrowUpIcon,
  ArrowDownIcon,
} from '@heroicons/react/20/solid';

const performanceData = [
  { date: '2024-01', Revenue: 125000, CTR: 0.12, CPM: 3.5, Viewability: 72 },
  { date: '2024-02', Revenue: 142000, CTR: 0.14, CPM: 3.8, Viewability: 75 },
  { date: '2024-03', Revenue: 158000, CTR: 0.15, CPM: 3.6, Viewability: 78 },
  { date: '2024-04', Revenue: 175000, CTR: 0.16, CPM: 3.4, Viewability: 82 }
];

const categoryTrends = [
  {
    category: 'Automotive',
    growth: 28,
    spend: 450000,
    performance: 'up',
    ctr: 0.15,
    viewability: 82
  },
  {
    category: 'Technology',
    growth: 35,
    spend: 380000,
    performance: 'up',
    ctr: 0.18,
    viewability: 85
  },
  {
    category: 'Retail',
    growth: -5,
    spend: 290000,
    performance: 'down',
    ctr: 0.11,
    viewability: 71
  },
  {
    category: 'Finance',
    growth: 12,
    spend: 520000,
    performance: 'up',
    ctr: 0.14,
    viewability: 78
  }
];

const deviceTrends = [
  { device: 'Desktop', value: 42, trend: 'up', change: 5 },
  { device: 'Mobile', value: 38, trend: 'up', change: 8 },
  { device: 'Tablet', value: 12, trend: 'down', change: -2 },
  { device: 'CTV', value: 8, trend: 'up', change: 3 }
];

export function TrendAnalysis() {
  return (
    <div className="space-y-6">
      {/* Primary Metrics */}
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
        <Card>
          <Text>Revenue Trend</Text>
          <div className="mt-2">
            <div className="text-2xl font-bold">$175K</div>
            <div className="flex items-center mt-1">
              <ArrowUpIcon className="h-4 w-4 text-green-500" />
              <span className="text-sm text-green-500 ml-1">+12% vs last period</span>
            </div>
          </div>
        </Card>

        <Card>
          <Text>CTR Trend</Text>
          <div className="mt-2">
            <div className="text-2xl font-bold">0.16%</div>
            <div className="flex items-center mt-1">
              <ArrowUpIcon className="h-4 w-4 text-green-500" />
              <span className="text-sm text-green-500 ml-1">+0.02% vs last period</span>
            </div>
          </div>
        </Card>

        <Card>
          <Text>CPM Trend</Text>
          <div className="mt-2">
            <div className="text-2xl font-bold">$3.40</div>
            <div className="flex items-center mt-1">
              <ArrowDownIcon className="h-4 w-4 text-green-500" />
              <span className="text-sm text-green-500 ml-1">-$0.20 vs last period</span>
            </div>
          </div>
        </Card>

        <Card>
          <Text>Viewability Trend</Text>
          <div className="mt-2">
            <div className="text-2xl font-bold">82%</div>
            <div className="flex items-center mt-1">
              <ArrowUpIcon className="h-4 w-4 text-green-500" />
              <span className="text-sm text-green-500 ml-1">+4% vs last period</span>
            </div>
          </div>
        </Card>
      </div>

      {/* Trend Charts */}
      <TabGroup>
        <TabList className="mb-4">
          <Tab>Performance</Tab>
          <Tab>Categories</Tab>
          <Tab>Devices</Tab>
        </TabList>

        <TabPanels>
          {/* Performance Trends */}
          <TabPanel>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <Card>
                <Text className="font-medium mb-4">Revenue & CTR Trends</Text>
                <LineChart
                  data={performanceData}
                  index="date"
                  categories={["Revenue", "CTR"]}
                  colors={["emerald", "blue"]}
                  className="h-72"
                />
              </Card>

              <Card>
                <Text className="font-medium mb-4">CPM & Viewability Trends</Text>
                <LineChart
                  data={performanceData}
                  index="date"
                  categories={["CPM", "Viewability"]}
                  colors={["purple", "amber"]}
                  className="h-72"
                />
              </Card>
            </div>
          </TabPanel>

          {/* Category Trends */}
          <TabPanel>
            <Card>
              <Text className="font-medium mb-4">Category Performance Trends</Text>
              <div className="space-y-6">
                {categoryTrends.map((category) => (
                  <div key={category.category} className="space-y-2">
                    <div className="flex items-center justify-between">
                      <Text className="font-medium">{category.category}</Text>
                      <div className="flex items-center">
                        {category.performance === 'up' ? (
                          <ArrowUpIcon className="h-4 w-4 text-green-500" />
                        ) : (
                          <ArrowDownIcon className="h-4 w-4 text-red-500" />
                        )}
                        <span className={`ml-1 text-sm ${
                          category.performance === 'up' ? 'text-green-500' : 'text-red-500'
                        }`}>
                          {category.growth}%
                        </span>
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4">
                      <div>
                        <Text className="text-sm text-gray-500">Spend</Text>
                        <Text className="font-medium">${category.spend.toLocaleString()}</Text>
                      </div>
                      <div>
                        <Text className="text-sm text-gray-500">CTR</Text>
                        <Text className="font-medium">{(category.ctr * 100).toFixed(2)}%</Text>
                      </div>
                      <div>
                        <Text className="text-sm text-gray-500">Viewability</Text>
                        <Text className="font-medium">{category.viewability}%</Text>
                      </div>
                    </div>
                    <ProgressBar value={category.viewability} color="blue" className="mt-2" />
                  </div>
                ))}
              </div>
            </Card>
          </TabPanel>

          {/* Device Trends */}
          <TabPanel>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <Card>
                <Text className="font-medium mb-4">Device Distribution Trends</Text>
                <BarChart
                  data={deviceTrends}
                  index="device"
                  categories={["value"]}
                  colors={["blue"]}
                  className="h-72"
                  valueFormatter={(value) => `${value}%`}
                />
              </Card>

              <Card>
                <Text className="font-medium mb-4">Device Performance Changes</Text>
                <div className="space-y-6">
                  {deviceTrends.map((device) => (
                    <div key={device.device} className="space-y-2">
                      <div className="flex items-center justify-between">
                        <Text className="font-medium">{device.device}</Text>
                        <div className="flex items-center">
                          {device.trend === 'up' ? (
                            <ArrowUpIcon className="h-4 w-4 text-green-500" />
                          ) : (
                            <ArrowDownIcon className="h-4 w-4 text-red-500" />
                          )}
                          <span className={`ml-1 text-sm ${
                            device.trend === 'up' ? 'text-green-500' : 'text-red-500'
                          }`}>
                            {device.change > 0 ? '+' : ''}{device.change}%
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center justify-between">
                        <Text className="text-sm text-gray-500">Share of Traffic</Text>
                        <Text className="font-medium">{device.value}%</Text>
                      </div>
                      <ProgressBar value={device.value} color="blue" />
                    </div>
                  ))}
                </div>
              </Card>
            </div>
          </TabPanel>
        </TabPanels>
      </TabGroup>

      {/* Additional Insights */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <Card>
          <Text className="font-medium mb-4">Top Growing Categories</Text>
          <div className="space-y-4">
            {categoryTrends
              .sort((a, b) => b.growth - a.growth)
              .slice(0, 3)
              .map((category) => (
                <div key={category.category} className="flex items-center justify-between">
                  <div>
                    <Text>{category.category}</Text>
                    <Text className="text-sm text-gray-500">
                      ${category.spend.toLocaleString()} spend
                    </Text>
                  </div>
                  <Badge color="emerald">+{category.growth}%</Badge>
                </div>
              ))}
          </div>
        </Card>

        <Card>
          <Text className="font-medium mb-4">Performance Anomalies</Text>
          <div className="space-y-4">
            <div className="p-3 bg-yellow-50 rounded-lg">
              <Text className="font-medium text-yellow-800">CTR Spike Detected</Text>
              <Text className="text-sm text-yellow-600">
                +45% increase in Technology vertical
              </Text>
            </div>
            <div className="p-3 bg-red-50 rounded-lg">
              <Text className="font-medium text-red-800">Viewability Drop</Text>
              <Text className="text-sm text-red-600">
                -12% decrease in Retail category
              </Text>
            </div>
          </div>
        </Card>

        <Card>
          <Text className="font-medium mb-4">Forecasted Trends</Text>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <Text>Expected Q2 Growth</Text>
              <Badge color="blue">+15%</Badge>
            </div>
            <div className="flex items-center justify-between">
              <Text>Projected CPM</Text>
              <Badge color="emerald">-8%</Badge>
            </div>
            <div className="flex items-center justify-between">
              <Text>Viewability Target</Text>
              <Badge color="purple">85%</Badge>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}