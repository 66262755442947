import { Title, Text } from '@tremor/react';
import { Card } from '../ui/Card';
import { ClockIcon, ArrowPathIcon, StarIcon } from '@heroicons/react/24/outline';

interface RecentSearch {
  id: string;
  query: string;
  type: 'inventory' | 'audience' | 'deal';
  timestamp: string;
  saved?: boolean;
  results: number;
}

const mockSearches: RecentSearch[] = [
  {
    id: '1',
    query: 'Premium news sites with >80% viewability',
    type: 'inventory',
    timestamp: '2 hours ago',
    results: 156,
    saved: true
  },
  {
    id: '2',
    query: 'Auto intender audiences in California',
    type: 'audience',
    timestamp: '3 hours ago',
    results: 23
  },
  {
    id: '3',
    query: 'High performing video deals',
    type: 'deal',
    timestamp: '1 day ago',
    results: 45,
    saved: true
  }
];

const typeColors = {
  inventory: 'text-blue-600 bg-blue-50',
  audience: 'text-purple-600 bg-purple-50',
  deal: 'text-green-600 bg-green-50'
};

export function RecentSearches() {
  return (
    <Card className="w-full">
      <div className="flex items-center justify-between mb-4">
        <Title className="text-base font-medium">Recent Searches</Title>
        <button className="text-sm text-blue-600 hover:text-blue-700">
          Clear All
        </button>
      </div>

      <div className="divide-y divide-gray-100">
        {mockSearches.map((search) => (
          <button
            key={search.id}
            className="w-full text-left group block py-3 first:pt-0 last:pb-0"
            onClick={() => {}}
          >
            <div className="relative flex items-start space-x-3">
              <div className="flex-shrink-0 mt-1">
                <ClockIcon className="h-4 w-4 text-gray-400" />
              </div>
              
              <div className="flex-1">
                <div className="flex items-start justify-between mb-1">
                  <Text className="font-medium text-sm text-gray-900 leading-snug break-normal">
                    {search.query}
                  </Text>
                  {search.saved && (
                    <StarIcon className="h-4 w-4 text-yellow-400 ml-2 flex-shrink-0" />
                  )}
                </div>
                
                <div className="flex flex-wrap gap-2 items-center text-xs text-gray-500">
                  <span className={`inline-flex items-center px-2 py-0.5 rounded-full font-medium ${typeColors[search.type]}`}>
                    {search.type}
                  </span>
                  <span className="inline-flex items-center">
                    {search.results} results
                  </span>
                  <span className="inline-flex items-center">
                    {search.timestamp}
                  </span>
                </div>
              </div>

              <div className="flex-shrink-0 self-center opacity-0 group-hover:opacity-100 transition-opacity">
                <ArrowPathIcon className="h-4 w-4 text-gray-400" />
              </div>
            </div>
          </button>
        ))}
      </div>
    </Card>
  );
}