import { Card } from '../components/ui/Card';
import { MetricCard } from '../components/intelligence/MetricCard';
import { InsightsFeed } from '../components/intelligence/InsightsFeed';
import { TrendingTopics } from '../components/intelligence/TrendingTopics';
import { AnomalyDetection } from '../components/intelligence/AnomalyDetection';
import { PerformancePredictor } from '../components/intelligence/PerformancePredictor';
import {
  ArrowTrendingUpIcon,
  ChartBarIcon,
  CurrencyDollarIcon,
  UserGroupIcon
} from '@heroicons/react/24/outline';

// Import the type from MetricCard component
import type { MetricCardProps } from '../components/intelligence/MetricCard';

// Define metrics with proper typing
const metrics: Omit<MetricCardProps, 'key'>[] = [
  {
    title: 'Revenue Potential',
    value: '$2.4M',
    change: '+12.3%',
    changeType: 'increase',
    description: 'Projected for next 30 days',
    icon: CurrencyDollarIcon
  },
  {
    title: 'Optimization Score',
    value: '86',
    change: '+5.2%',
    changeType: 'increase',
    description: 'Based on current setup',
    icon: ChartBarIcon
  },
  {
    title: 'Audience Growth',
    value: '2.1M',
    change: '+8.1%',
    changeType: 'increase',
    description: 'Addressable users',
    icon: UserGroupIcon
  },
  {
    title: 'Market Trends',
    value: '3',
    change: 'New',
    changeType: 'neutral',
    description: 'Emerging opportunities',
    icon: ArrowTrendingUpIcon
  }
];

export default function Intelligence() {
  return (
    <div className="min-h-screen bg-gray-50 pb-12">
      <div className="max-w-[1920px] mx-auto p-4 sm:p-6 lg:p-8">
        {/* Header */}
        <div className="mb-6">
          <h1 className="text-2xl font-semibold text-gray-900">Intelligence</h1>
          <p className="mt-1 text-sm text-gray-500">
            AI-powered insights and recommendations for your campaigns
          </p>
        </div>

        {/* Metrics Overview */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
          {metrics.map((metric) => (
            <MetricCard key={metric.title} {...metric} />
          ))}
        </div>

        {/* Main Content */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Left Column */}
          <div className="lg:col-span-2 space-y-6">
            <AnomalyDetection />
            <PerformancePredictor />
          </div>

          {/* Right Column */}
          <div className="space-y-6">
            <InsightsFeed />
            <TrendingTopics />
          </div>
        </div>
      </div>
    </div>
  );
}