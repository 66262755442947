// src/data/mockDealsData.ts

import type { EnhancedDeal } from '../types/deals';

export const mockDeals: EnhancedDeal[] = [
    {
      id: 'DEAL-001',
      name: 'Premium Auto Campaign',
      advertiser: 'AutoMax Inc',
      type: 'PMP',
      status: 'active',
      health: {
        score: 65,
        status: 'warning',
        lastChecked: '2024-01-20T10:00:00Z',
        issues: [
          {
            type: 'bid_response',
            severity: 'high',
            message: 'Low bid response rate detected'
          }
        ]
      },
      performance: {
        bidResponses: 45,
        winRate: 15,
        viewability: 82,
        targetCpm: 12.50,
        actualCpm: 10.80,
        targetSpend: 100000,
        actualSpend: 85000,
        pacing: {
          daily: 92,
          overall: 85,
          trend: [80, 82, 85, 88, 90, 92, 85]
        }
      },
      setup: {
        audiences: ['Auto Intenders', 'High Income'],
        domains: ['premium-news.com', 'auto-enthusiast.com'],
        formats: ['Display', 'Native'],
        targeting: {
          geo: ['US'],
          devices: ['Desktop', 'Mobile']
        }
      },
      optimization: {
        lastOptimized: '2024-01-15T08:00:00Z',
        recommendations: [
          {
            type: 'audience_expansion',
            impact: 25,
            confidence: 85,
            message: 'Add similar auto-interest segments'
          }
        ]
      },
      owner: {
        id: 'USER-001',
        name: 'John Smith',
        email: 'john.smith@company.com',
        team: 'Deal Operations',
        role: 'Deal Manager'
      },
      brand: {
        id: 'BRAND-001',
        name: 'AutoMax Inc',
        agency: 'MediaCom',
        vertical: 'Automotive',
        region: 'North America',
        spendTarget: 1000000
      },
      recentActivity: [
        {
          type: 'optimization',
          message: 'Automated optimization completed',
          timestamp: '2024-01-20T09:00:00Z'
        }
      ],
      trends: {
        performance: [75, 78, 82, 80, 85, 83, 88],
        revenue: [100000, 120000, 115000, 125000, 130000, 128000, 135000],
        health: [70, 72, 75, 73, 78, 76, 80]
      }
    },
    {
      id: 'DEAL-002',
      name: 'Finance Professional Network',
      advertiser: 'Global Banking Corp',
      type: 'Programmatic Guaranteed',
      status: 'active',
      health: {
        score: 92,
        status: 'healthy',
        lastChecked: '2024-01-20T11:00:00Z',
        issues: []
      },
      performance: {
        bidResponses: 85,
        winRate: 35,
        viewability: 90,
        targetCpm: 25.00,
        actualCpm: 24.50,
        targetSpend: 250000,
        actualSpend: 275000,
        pacing: {
          daily: 115,
          overall: 110,
          trend: [100, 105, 108, 112, 115, 118, 115]
        }
      },
      setup: {
        audiences: ['Finance Professionals', 'Investment Decision Makers'],
        domains: ['finance-news.com', 'investment-daily.com'],
        formats: ['Display'],
        targeting: {
          geo: ['US', 'UK', 'HK'],
          devices: ['Desktop']
        }
      },
      optimization: {
        lastOptimized: '2024-01-18T10:00:00Z',
        recommendations: []
      },
      owner: {
        id: 'USER-002',
        name: 'Sarah Johnson',
        email: 'sarah.j@company.com',
        team: 'Strategic Accounts',
        role: 'Senior Account Manager'
      },
      brand: {
        id: 'BRAND-002',
        name: 'Global Banking Corp',
        agency: 'Mindshare',
        vertical: 'Finance',
        region: 'Global',
        spendTarget: 2500000
      },
      recentActivity: [
        {
          type: 'health_check',
          message: 'Health check completed successfully',
          timestamp: '2024-01-20T11:00:00Z'
        }
      ],
      trends: {
        performance: [88, 90, 89, 92, 91, 93, 92],
        revenue: [200000, 210000, 215000, 225000, 220000, 228000, 235000],
        health: [90, 91, 90, 92, 91, 93, 92]
      }
    },
    {
      id: 'DEAL-003',
      name: 'Retail Performance Bundle',
      advertiser: 'MegaMart Stores',
      type: 'PMP',
      status: 'critical',
      health: {
        score: 45,
        status: 'critical',
        lastChecked: '2024-01-20T09:00:00Z',
        issues: [
          {
            type: 'no_bids',
            severity: 'high',
            message: 'No bid responses in last 24 hours'
          },
          {
            type: 'setup_issue',
            severity: 'high',
            message: 'DSP targeting mismatch detected'
          }
        ]
      },
      performance: {
        bidResponses: 0,
        winRate: 0,
        viewability: 0,
        targetCpm: 15.00,
        actualCpm: 0,
        targetSpend: 80000,
        actualSpend: 35000,
        pacing: {
          daily: 45,
          overall: 43.75,
          trend: [60, 55, 50, 48, 45, 44, 43]
        }
      },
  
      setup: {
        audiences: ['Shopping Intenders', 'Retail Consumers'],
        domains: ['retail-sites.com', 'shopping-network.com'],
        formats: ['Display', 'Native', 'Video'],
        targeting: {
          geo: ['US'],
          devices: ['All']
        }
      },
      optimization: {
        lastOptimized: null,
        recommendations: [
          {
            type: 'dsp_setup',
            impact: 100,
            confidence: 95,
            message: 'Review DSP targeting configuration'
          }
        ]
      },
      owner: {
        id: 'USER-003',
        name: 'Mike Chen',
        email: 'mike.c@company.com',
        team: 'Deal Operations',
        role: 'Technical Specialist'
      },
      brand: {
        id: 'BRAND-003',
        name: 'MegaMart Stores',
        agency: 'GroupM',
        vertical: 'Retail',
        region: 'US',
        spendTarget: 800000
      },
      recentActivity: [
        {
          type: 'alert',
          message: 'Critical health alert: No bid responses',
          timestamp: '2024-01-20T09:00:00Z'
        }
      ],
      trends: {
        performance: [65, 60, 55, 45, 30, 15, 0],
        revenue: [80000, 75000, 70000, 50000, 30000, 10000, 0],
        health: [70, 65, 60, 55, 50, 45, 45]
      }
    }
    // Add more mock deals as needed
  ];