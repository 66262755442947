import { Card } from '../ui/Card';
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/20/solid';
import {
  UsersIcon,
  ChartBarIcon,
  SignalIcon,
  CursorArrowRaysIcon
} from '@heroicons/react/24/outline';

const metrics = [
  {
    name: 'Total Reach',
    value: '45.2M',
    change: '+12.3%',
    changeType: 'increase',
    icon: UsersIcon,
  },
  {
    name: 'Active Segments',
    value: '342',
    change: '+5.4%',
    changeType: 'increase',
    icon: SignalIcon,
  },
  {
    name: 'Avg. Performance',
    value: '+32%',
    change: '+8.7%',
    changeType: 'increase',
    icon: ChartBarIcon,
  },
  {
    name: 'Match Rate',
    value: '84%',
    change: '-2.1%',
    changeType: 'decrease',
    icon: CursorArrowRaysIcon,
  }
];

export function AudienceMetrics() {
  return (
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
      {metrics.map((metric) => (
        <Card key={metric.name}>
          <div className="flex items-start justify-between">
            <div>
              <p className="text-sm font-medium text-gray-500">{metric.name}</p>
              <div className="mt-2 flex items-baseline">
                <p className="text-2xl font-semibold text-gray-900">{metric.value}</p>
                <span
                  className={`ml-2 inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium
                    ${metric.changeType === 'increase' 
                      ? 'bg-green-100 text-green-800'
                      : 'bg-red-100 text-red-800'
                    }`}
                >
                  {metric.changeType === 'increase' ? (
                    <ArrowUpIcon className="mr-1 h-4 w-4 flex-shrink-0" aria-hidden="true" />
                  ) : (
                    <ArrowDownIcon className="mr-1 h-4 w-4 flex-shrink-0" aria-hidden="true" />
                  )}
                  {metric.change}
                </span>
              </div>
            </div>
            <div className="rounded-md bg-gray-50 p-2">
              <metric.icon className="h-6 w-6 text-gray-400" aria-hidden="true" />
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
}