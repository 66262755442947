// import { useState } from 'react';
// import {
//   Table,
//   TableHead,
//   TableRow,
//   TableHeaderCell,
//   TableBody,
//   TableCell,
//   Badge,
//   Text,
//   Flex,
//   Button,
//   Card,
//   ProgressBar
// } from '@tremor/react';
// import { UsersIcon, SignalIcon, ArrowsPointingOutIcon, GlobeAltIcon } from '@heroicons/react/24/outline';

// interface AudienceSegment {
//   id: string;
//   name: string;
//   category: string;
//   type: 'First Party' | 'Third Party' | 'Contextual';
//   provider: string;
//   reachSize: number;
//   reachPercentage: number;
//   activeDeals: number;
//   cpm: number;
//   performance: 'High' | 'Medium' | 'Low';
//   refreshDate: string;
// }

// const mockAudiences: AudienceSegment[] = [
//   {
//     id: '1',
//     name: 'Auto Intenders',
//     category: 'Automotive',
//     type: 'Third Party',
//     provider: 'Oracle',
//     reachSize: 2500000,
//     reachPercentage: 85,
//     activeDeals: 12,
//     cpm: 3.50,
//     performance: 'High',
//     refreshDate: '2024-01-15'
//   },
//   {
//     id: '2',
//     name: 'Luxury Shoppers',
//     category: 'Retail',
//     type: 'First Party',
//     provider: 'OpenX',
//     reachSize: 1800000,
//     reachPercentage: 72,
//     activeDeals: 8,
//     cpm: 4.20,
//     performance: 'High',
//     refreshDate: '2024-01-16'
//   },
//   {
//     id: '3',
//     name: 'Tech Enthusiasts',
//     category: 'Technology',
//     type: 'Contextual',
//     provider: 'OpenX',
//     reachSize: 3200000,
//     reachPercentage: 92,
//     activeDeals: 15,
//     cpm: 2.80,
//     performance: 'Medium',
//     refreshDate: '2024-01-14'
//   }
// ];

// export function AudienceView() {
//   const [selectedSegments, setSelectedSegments] = useState<string[]>([]);

//   const getPerformanceColor = (performance: string) => {
//     switch (performance) {
//       case 'High':
//         return 'emerald';
//       case 'Medium':
//         return 'yellow';
//       case 'Low':
//         return 'red';
//       default:
//         return 'gray';
//     }
//   };

//   const getTypeIcon = (type: string) => {
//     switch (type) {
//       case 'First Party':
//         return <SignalIcon className="h-5 w-5 text-purple-500" />;
//       case 'Third Party':
//         return <GlobeAltIcon className="h-5 w-5 text-blue-500" />;
//       case 'Contextual':
//         return <ArrowsPointingOutIcon className="h-5 w-5 text-green-500" />;
//       default:
//         return null;
//     }
//   };

//   return (
//     <div className="w-full overflow-hidden"> {/* Added overflow-hidden */}
//       <div className="mb-6">
//         <Flex className="flex-col md:flex-row gap-4 md:items-center justify-between">
//           <div className="flex flex-col md:flex-row gap-4 items-start md:items-center">
//             <Text className="font-medium whitespace-nowrap">
//               {mockAudiences.length} segments found
//             </Text>
//             <div className="flex flex-wrap gap-2">
//               <Badge color="purple" icon={SignalIcon}>First Party</Badge>
//               <Badge color="blue" icon={GlobeAltIcon}>Third Party</Badge>
//               <Badge color="green" icon={ArrowsPointingOutIcon}>Contextual</Badge>
//             </div>
//           </div>
//           {selectedSegments.length > 0 && (
//             <div className="flex flex-wrap gap-2">
//               <Button size="xs" variant="secondary" icon={UsersIcon}>
//                 Analyze Selected
//               </Button>
//               <Button size="xs" variant="primary">
//                 Create Segment ({selectedSegments.length})
//               </Button>
//             </div>
//           )}
//         </Flex>
//       </div>

//       <div className="overflow-x-auto">
//         <Table className="min-w-full">
//           <TableHead>
//             <TableRow>
//               <TableHeaderCell className="whitespace-nowrap">Segment</TableHeaderCell>
//               <TableHeaderCell className="whitespace-nowrap">Type</TableHeaderCell>
//               <TableHeaderCell className="whitespace-nowrap">Category</TableHeaderCell>
//               <TableHeaderCell className="whitespace-nowrap">Provider</TableHeaderCell>
//               <TableHeaderCell className="whitespace-nowrap">Reach</TableHeaderCell>
//               <TableHeaderCell className="whitespace-nowrap">Active Deals</TableHeaderCell>
//               <TableHeaderCell className="whitespace-nowrap">CPM</TableHeaderCell>
//               <TableHeaderCell className="whitespace-nowrap">Performance</TableHeaderCell>
//             </TableRow>
//           </TableHead>
//         <TableBody>
//           {mockAudiences.map((segment) => (
//             <TableRow 
//               key={segment.id}
//               className={`cursor-pointer ${
//                 selectedSegments.includes(segment.id) ? 'bg-blue-50' : ''
//               }`}
//             >
//               <TableCell>
//                 <div className="flex items-center space-x-2">
//                   <input
//                     type="checkbox"
//                     checked={selectedSegments.includes(segment.id)}
//                     onChange={() => {
//                       setSelectedSegments(prev => 
//                         prev.includes(segment.id)
//                           ? prev.filter(id => id !== segment.id)
//                           : [...prev, segment.id]
//                       );
//                     }}
//                     className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
//                   />
//                   <div>
//                     <Text className="font-medium">{segment.name}</Text>
//                     <Text className="text-xs text-gray-500">
//                       Updated {segment.refreshDate}
//                     </Text>
//                   </div>
//                 </div>
//               </TableCell>
//               <TableCell>
//                 <div className="flex items-center space-x-2">
//                   {getTypeIcon(segment.type)}
//                   <Text>{segment.type}</Text>
//                 </div>
//               </TableCell>
//               <TableCell>{segment.category}</TableCell>
//               <TableCell>{segment.provider}</TableCell>
//               <TableCell>
//                 <div className="space-y-1">
//                   <Text>{new Intl.NumberFormat().format(segment.reachSize)}</Text>
//                   <ProgressBar value={segment.reachPercentage} color="blue" />
//                 </div>
//               </TableCell>
//               <TableCell>{segment.activeDeals}</TableCell>
//               <TableCell>${segment.cpm.toFixed(2)}</TableCell>
//               <TableCell>
//                 <Badge color={getPerformanceColor(segment.performance)}>
//                   {segment.performance}
//                 </Badge>
//               </TableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </div>
//     </div>    
//   );
// }


// src/components/discovery/AudienceView.tsx

import { FC, useState, Fragment } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Dialog, Transition } from '@headlessui/react';

import {
  Card,
  Badge,
  Text,
  Title,
  ProgressBar,
  Select,
  SelectItem,
  Metric,
  DonutChart,
} from '@tremor/react';
import { Button } from '../ui/Button';
import {
  UsersIcon,
  ChartBarIcon,
  SparklesIcon,
  ArrowTrendingUpIcon,
  GlobeAltIcon,
  DeviceTabletIcon,
  ShoppingBagIcon,
  UserGroupIcon,
  AdjustmentsHorizontalIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import type { SmartDiscoveryQuery } from '../../types/discovery';

export interface AudienceViewProps {
  query: SmartDiscoveryQuery | null;
}

interface DemographicData {
  ageGroup: string;
  gender: string;
  percentage: number;
}

interface InterestData {
  category: string;
  affinity: number;
  recentEngagement: number;
}

interface BehaviorData {
  type: string;
  frequency: number;
  recency: number;
}

interface AudienceSegment {
  id: string;
  name: string;
  type: 'first-party' | 'third-party' | 'lookalike' | 'custom';
  description: string;
  status: 'active' | 'draft' | 'archived';
  provider: string;
  size: number;
  reachScore: number;
  similarityScore: number;
  demographics: DemographicData[];
  interests: InterestData[];
  behaviors: BehaviorData[];
  performance: {
    ctr: number;
    viewability: number;
    completion: number;
    conversion: number;
  };
  trending: {
    growth: number;
    direction: 'up' | 'down' | 'stable';
    periodDays: number;
  };
  deviceDistribution: {
    desktop: number;
    mobile: number;
    tablet: number;
    ctv: number;
  };
  geography: {
    region: string;
    percentage: number;
  }[];
  lastUpdated: string;
  refreshFrequency: 'daily' | 'weekly' | 'monthly';
  tags: string[];
}

// Rich example data with multiple audience segments
const mockAudiences: AudienceSegment[] = [
  {
    id: 'AUD-001',
    name: 'Premium Sports Enthusiasts',
    type: 'first-party',
    description: 'High-value users actively engaging with premium sports content',
    status: 'active',
    provider: 'OpenX',
    size: 2500000,
    reachScore: 85,
    similarityScore: 92,
    demographics: [
      { ageGroup: '25-34', gender: 'M', percentage: 35 },
      { ageGroup: '35-44', gender: 'M', percentage: 28 },
      { ageGroup: '25-34', gender: 'F', percentage: 22 },
      { ageGroup: '35-44', gender: 'F', percentage: 15 },
    ],
    interests: [
      { category: 'Sports', affinity: 92, recentEngagement: 88 },
      { category: 'Fitness', affinity: 78, recentEngagement: 72 },
      { category: 'Luxury Cars', affinity: 65, recentEngagement: 58 },
    ],
    behaviors: [
      { type: 'Content Consumption', frequency: 8.5, recency: 1 },
      { type: 'Ticket Purchase', frequency: 3.2, recency: 7 },
      { type: 'App Usage', frequency: 12.4, recency: 1 },
    ],
    performance: {
      ctr: 0.15,
      viewability: 82,
      completion: 76,
      conversion: 2.8
    },
    trending: {
      growth: 12.5,
      direction: 'up',
      periodDays: 30
    },
    deviceDistribution: {
      desktop: 35,
      mobile: 45,
      tablet: 15,
      ctv: 5
    },
    geography: [
      { region: 'New York', percentage: 25 },
      { region: 'California', percentage: 20 },
      { region: 'Texas', percentage: 15 },
      { region: 'Florida', percentage: 12 },
    ],
    lastUpdated: '2024-01-15',
    refreshFrequency: 'daily',
    tags: ['Sports', 'High Value', 'Mobile First', 'Premium']
  },
  {
    id: 'AUD-002',
    name: 'Tech Early Adopters',
    type: 'third-party',
    description: 'Technology enthusiasts with high disposable income',
    status: 'active',
    provider: 'Oracle',
    size: 1800000,
    reachScore: 78,
    similarityScore: 88,
    demographics: [
      { ageGroup: '25-34', gender: 'M', percentage: 40 },
      { ageGroup: '35-44', gender: 'M', percentage: 25 },
      { ageGroup: '25-34', gender: 'F', percentage: 20 },
      { ageGroup: '35-44', gender: 'F', percentage: 15 },
    ],
    interests: [
      { category: 'Technology', affinity: 95, recentEngagement: 92 },
      { category: 'Gadgets', affinity: 88, recentEngagement: 85 },
      { category: 'Gaming', affinity: 72, recentEngagement: 68 },
    ],
    behaviors: [
      { type: 'Online Shopping', frequency: 6.8, recency: 2 },
      { type: 'Product Research', frequency: 15.3, recency: 1 },
      { type: 'Review Reading', frequency: 18.7, recency: 1 },
    ],
    performance: {
      ctr: 0.18,
      viewability: 85,
      completion: 82,
      conversion: 3.2
    },
    trending: {
      growth: 15.8,
      direction: 'up',
      periodDays: 30
    },
    deviceDistribution: {
      desktop: 45,
      mobile: 40,
      tablet: 12,
      ctv: 3
    },
    geography: [
      { region: 'California', percentage: 30 },
      { region: 'Washington', percentage: 18 },
      { region: 'Massachusetts', percentage: 15 },
      { region: 'Texas', percentage: 12 },
    ],
    lastUpdated: '2024-01-16',
    refreshFrequency: 'daily',
    tags: ['Technology', 'Early Adopters', 'High Income', 'Digital Native']
  },
  {
    id: 'AUD-003',
    name: 'Luxury Auto Intenders',
    type: 'lookalike',
    description: 'Users showing strong signals for luxury vehicle purchase',
    status: 'active',
    provider: 'OpenX',
    size: 1200000,
    reachScore: 72,
    similarityScore: 85,
    demographics: [
      { ageGroup: '35-44', gender: 'M', percentage: 35 },
      { ageGroup: '45-54', gender: 'M', percentage: 30 },
      { ageGroup: '35-44', gender: 'F', percentage: 20 },
      { ageGroup: '45-54', gender: 'F', percentage: 15 },
    ],
    interests: [
      { category: 'Luxury Cars', affinity: 90, recentEngagement: 85 },
      { category: 'Premium Lifestyle', affinity: 82, recentEngagement: 78 },
      { category: 'Financial Services', affinity: 75, recentEngagement: 70 },
    ],
    behaviors: [
      { type: 'Dealer Website Visits', frequency: 4.2, recency: 3 },
      { type: 'Price Comparison', frequency: 8.5, recency: 2 },
      { type: 'Video Views', frequency: 12.3, recency: 1 },
    ],
    performance: {
      ctr: 0.12,
      viewability: 78,
      completion: 72,
      conversion: 1.8
    },
    trending: {
      growth: 8.5,
      direction: 'up',
      periodDays: 30
    },
    deviceDistribution: {
      desktop: 40,
      mobile: 35,
      tablet: 20,
      ctv: 5
    },
    geography: [
      { region: 'California', percentage: 28 },
      { region: 'Florida', percentage: 22 },
      { region: 'New York', percentage: 18 },
      { region: 'Texas', percentage: 15 },
    ],
    lastUpdated: '2024-01-14',
    refreshFrequency: 'daily',
    tags: ['Automotive', 'Luxury', 'High Intent', 'Affluent']
  }
];

export const AudienceView: FC<AudienceViewProps> = ({ query }) => {
    const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
    const [selectedAudiences, setSelectedAudiences] = useState<string[]>([]);
    const [sortBy, setSortBy] = useState<string>('similarity');
    const [filterType, setFilterType] = useState<string>('all');
    const [showDetails, setShowDetails] = useState<string | null>(null);
  
    // Audience Card Component
    const AudienceCard: FC<{ audience: AudienceSegment }> = ({ audience }) => (
      <motion.div
        layout
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
      >
        <Card className={`cursor-pointer transition-all duration-200 ${
          selectedAudiences.includes(audience.id) ? 'ring-2 ring-blue-500' : ''
        }`}>
          {/* Score Badge */}
          <div className="absolute top-3 right-3">
            <Badge
              color={audience.similarityScore > 90 ? 'emerald' : 'blue'}
              size="lg"
            >
              {audience.similarityScore}% Match
            </Badge>
          </div>
  
          {/* Main Content */}
          <div className="space-y-4 pt-8">
            {/* Header */}
            <div>
              <div className="flex items-start justify-between">
                <div>
                  <Title>{audience.name}</Title>
                  <Text className="text-gray-500">{audience.provider}</Text>
                </div>
                <Badge color={
                  audience.type === 'first-party' ? 'blue' :
                  audience.type === 'third-party' ? 'purple' :
                  audience.type === 'lookalike' ? 'emerald' : 'orange'
                }>
                  {audience.type}
                </Badge>
              </div>
              <Text className="mt-2 text-sm text-gray-600">{audience.description}</Text>
            </div>
  
            {/* Key Metrics */}
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Text className="text-sm text-gray-500">Audience Size</Text>
                <div className="flex items-baseline space-x-2">
                  <Text className="font-medium">
                    {(audience.size / 1000000).toFixed(1)}M
                  </Text>
                  {audience.trending.direction === 'up' && (
                    <Text className="text-sm text-green-600">
                      +{audience.trending.growth}%
                    </Text>
                  )}
                </div>
              </div>
              <div className="space-y-2">
                <Text className="text-sm text-gray-500">Reach Score</Text>
                <div>
                  <Text className="font-medium">{audience.reachScore}%</Text>
                </div>
              </div>
            </div>
  
            {/* Performance Metrics */}
            <div>
              <Text className="text-sm font-medium mb-2">Performance</Text>
              <div className="space-y-2">
                <div className="flex justify-between items-center">
                  <Text className="text-sm">CTR</Text>
                  <Text className="text-sm font-medium">
                    {(audience.performance.ctr * 100).toFixed(2)}%
                  </Text>
                </div>
                <ProgressBar value={audience.performance.ctr * 100} color="blue" />
                
                <div className="flex justify-between items-center">
                  <Text className="text-sm">Viewability</Text>
                  <Text className="text-sm font-medium">
                    {audience.performance.viewability}%
                  </Text>
                </div>
                <ProgressBar value={audience.performance.viewability} color="emerald" />
              </div>
            </div>
  
            {/* Device Distribution */}
            <div>
              <Text className="text-sm font-medium mb-2">Device Distribution</Text>
              <DonutChart
                data={[
                  { name: 'Desktop', value: audience.deviceDistribution.desktop },
                  { name: 'Mobile', value: audience.deviceDistribution.mobile },
                  { name: 'Tablet', value: audience.deviceDistribution.tablet },
                  { name: 'CTV', value: audience.deviceDistribution.ctv },
                ]}
                category="value"
                index="name"
                colors={['blue', 'emerald', 'violet', 'amber']}
                className="h-32"
              />
            </div>
  
            {/* Tags */}
            <div className="flex flex-wrap gap-2 pt-2 border-t">
              {audience.tags.map((tag) => (
                <Badge key={tag} color="gray" size="sm">
                  {tag}
                </Badge>
              ))}
            </div>
  
            {/* Actions */}
            <div className="flex justify-between items-center pt-2">
              <Text className="text-xs text-gray-500">
                Updated {audience.lastUpdated}
              </Text>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setShowDetails(audience.id)}
              >
                View Details
              </Button>
            </div>
          </div>
        </Card>
      </motion.div>
    );
  
    return (
      <div className="space-y-6">
        {/* Metrics Overview */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          <Card>
            <Text>Total Reach</Text>
            <Metric>
            {(mockAudiences.reduce((acc, aud) => acc + aud.size, 0) / 1000000).toFixed(1)}M
          </Metric>
          <Text className="text-sm text-gray-500">Across all segments</Text>
        </Card>
        <Card>
          <Text>Avg. Performance</Text>
          <Metric>
            {(mockAudiences.reduce((acc, aud) => acc + aud.performance.viewability, 0) / mockAudiences.length).toFixed(1)}%
          </Metric>
          <Text className="text-sm text-gray-500">Viewability rate</Text>
        </Card>
        <Card>
          <Text>Active Segments</Text>
          <Metric>
            {mockAudiences.filter(a => a.status === 'active').length}
          </Metric>
          <Text className="text-sm text-gray-500">Out of {mockAudiences.length} total</Text>
        </Card>
        <Card>
          <Text>Avg. Growth</Text>
          <Metric>
            {(mockAudiences.reduce((acc, aud) => acc + aud.trending.growth, 0) / mockAudiences.length).toFixed(1)}%
          </Metric>
          <Text className="text-sm text-gray-500">Last 30 days</Text>
        </Card>
      </div>

      {/* Controls */}
      <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
        <div className="flex items-center space-x-4">
          <Select 
            value={viewMode} 
            onValueChange={(value: string) => setViewMode(value as 'grid' | 'list')}
          >
            <SelectItem value="grid">Grid View</SelectItem>
            <SelectItem value="list">List View</SelectItem>
          </Select>

          <Select 
            value={sortBy} 
            onValueChange={(value: string) => setSortBy(value)}
          >
            <SelectItem value="similarity">Sort by Match</SelectItem>
            <SelectItem value="size">Sort by Size</SelectItem>
            <SelectItem value="performance">Sort by Performance</SelectItem>
            <SelectItem value="growth">Sort by Growth</SelectItem>
          </Select>

          <Select 
            value={filterType} 
            onValueChange={(value: string) => setFilterType(value)}
          >
            <SelectItem value="all">All Types</SelectItem>
            <SelectItem value="first-party">First Party</SelectItem>
            <SelectItem value="third-party">Third Party</SelectItem>
            <SelectItem value="lookalike">Lookalike</SelectItem>
          </Select>
        </div>

        <div className="flex items-center space-x-4">
          {selectedAudiences.length > 0 && (
            <>
              <Button variant="secondary" size="sm">
                <ChartBarIcon className="h-5 w-5 mr-2" />
                Compare ({selectedAudiences.length})
              </Button>
              <Button variant="secondary" size="sm">
                <SparklesIcon className="h-5 w-5 mr-2" />
                Create Lookalike
              </Button>
            </>
          )}
          <Button variant="primary" size="sm">
            <UserGroupIcon className="h-5 w-5 mr-2" />
            Create Segment
          </Button>
        </div>
      </div>

      {/* Search Query Indicator */}
      {query && (
        <div className="bg-blue-50 rounded-lg p-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              <UsersIcon className="h-5 w-5 text-blue-500" />
              <div>
                <Text className="font-medium text-blue-900">
                  Searching for audiences matching:
                </Text>
                <Text className="text-blue-700">
                  {query.keywords.join(' ')}
                </Text>
              </div>
            </div>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => setSelectedAudiences([])}
            >
              Clear Selection
            </Button>
          </div>
        </div>
      )}

      {/* Audiences Grid/List */}
      <div className={
        viewMode === 'grid'
          ? 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'
          : 'space-y-4'
      }>
        <AnimatePresence>
          {mockAudiences
            .filter(audience => filterType === 'all' || audience.type === filterType)
            .sort((a, b) => {
              switch (sortBy) {
                case 'similarity':
                  return b.similarityScore - a.similarityScore;
                case 'size':
                  return b.size - a.size;
                case 'performance':
                  return b.performance.viewability - a.performance.viewability;
                case 'growth':
                  return b.trending.growth - a.trending.growth;
                default:
                  return 0;
              }
            })
            .map((audience) => (
              <AudienceCard 
                key={audience.id} 
                audience={audience}
              />
            ))}
        </AnimatePresence>
      </div>

      {/* Audience Details Modal */}
      {showDetails && (
        <Dialog
          open={!!showDetails}
          onClose={() => setShowDetails(null)}
          className="fixed inset-0 z-50 overflow-y-auto"
        >
          <div className="flex min-h-full items-center justify-center p-4">
            <Dialog.Panel className="w-full max-w-4xl bg-white rounded-xl shadow-xl">
              <div className="p-6">
                <div className="flex items-center justify-between mb-6">
                  <Dialog.Title className="text-xl font-semibold">
                    Audience Details
                  </Dialog.Title>
                  <Button variant="ghost" onClick={() => setShowDetails(null)}>
                    Close
                  </Button>
                </div>

                {/* Audience Details Content */}
                {showDetails && (
                  <AudienceDetails 
                    audience={mockAudiences.find(a => a.id === showDetails)!}
                  />
                )}
              </div>
            </Dialog.Panel>
          </div>
        </Dialog>
      )}
    </div>
  );
};

// Audience Details Component
const AudienceDetails: FC<{ audience: AudienceSegment }> = ({ audience }) => (
  <div className="space-y-6">
    {/* Basic Info */}
    <div className="grid grid-cols-2 gap-6">
      <Card>
        <Title>Overview</Title>
        <div className="mt-4 space-y-4">
          <div>
            <Text className="text-sm text-gray-500">Size</Text>
            <Text className="text-lg font-medium">
              {(audience.size / 1000000).toFixed(1)}M Users
            </Text>
          </div>
          <div>
            <Text className="text-sm text-gray-500">Type</Text>
            <Badge color={
              audience.type === 'first-party' ? 'blue' :
              audience.type === 'third-party' ? 'purple' :
              'emerald'
            }>
              {audience.type}
            </Badge>
          </div>
          <div>
            <Text className="text-sm text-gray-500">Refresh Frequency</Text>
            <Text>{audience.refreshFrequency}</Text>
          </div>
        </div>
      </Card>

      <Card>
        <Title>Performance</Title>
        <div className="mt-4 space-y-4">
          <div>
            <Text className="text-sm text-gray-500">CTR</Text>
            <Text className="text-lg font-medium">
              {(audience.performance.ctr * 100).toFixed(2)}%
            </Text>
          </div>
          <div>
            <Text className="text-sm text-gray-500">Viewability</Text>
            <Text className="text-lg font-medium">
              {audience.performance.viewability}%
            </Text>
          </div>
          <div>
            <Text className="text-sm text-gray-500">Conversion Rate</Text>
            <Text className="text-lg font-medium">
              {audience.performance.conversion}%
            </Text>
          </div>
        </div>
      </Card>
    </div>

    {/* Demographics */}
    <Card>
      <Title>Demographics</Title>
      <div className="mt-4">
        <div className="grid grid-cols-2 gap-4">
          {audience.demographics.map((demo, index) => (
            <div key={index} className="flex justify-between items-center">
              <Text>{demo.ageGroup} ({demo.gender})</Text>
              <div className="w-1/2">
                <ProgressBar value={demo.percentage} color="blue" />
              </div>
              <Text>{demo.percentage}%</Text>
            </div>
          ))}
        </div>
      </div>
    </Card>

    {/* Interests & Behaviors */}
    <div className="grid grid-cols-2 gap-6">
      <Card>
        <Title>Top Interests</Title>
        <div className="mt-4 space-y-4">
          {audience.interests.map((interest, index) => (
            <div key={index} className="space-y-2">
              <div className="flex justify-between">
                <Text>{interest.category}</Text>
                <Text>{interest.affinity}% affinity</Text>
              </div>
              <ProgressBar value={interest.affinity} color="emerald" />
            </div>
          ))}
        </div>
      </Card>

      <Card>
        <Title>Behaviors</Title>
        <div className="mt-4 space-y-4">
          {audience.behaviors.map((behavior, index) => (
            <div key={index} className="space-y-1">
              <Text>{behavior.type}</Text>
              <div className="flex items-center justify-between text-sm">
                <Text>Frequency: {behavior.frequency}/week</Text>
                <Text>Last active: {behavior.recency}d ago</Text>
              </div>
            </div>
          ))}
        </div>
      </Card>
    </div>
  </div>
);