import { useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  Badge,
  Text,
  Card,
  Select,
  SelectItem,
  MultiSelect,
  MultiSelectItem,
  AreaChart,
  TabGroup,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Title,
  Metric,
  ProgressBar,
  List,
  ListItem,
} from '@tremor/react';
import { Dialog } from '@headlessui/react';
import {
  ChartBarIcon,
  PencilIcon,
  EllipsisHorizontalIcon,
  ArrowPathIcon,
  DocumentMagnifyingGlassIcon,
  SparklesIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  BuildingOfficeIcon,
  FunnelIcon,
  ArrowTrendingUpIcon,
  ExclamationTriangleIcon,
  CheckCircleIcon,
  BanknotesIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import { Button } from '../ui/Button';
import type { Deal, DealFilter } from '../../types/deals';
import { mockDeals } from '../../data/mockDealsData';
import { DealAnalysisDialog } from './DealAnalysisDialog';

interface DealTableProps {
  onHealthCheck: (dealId: string) => void;
  onOptimize: (dealId: string) => void;
}

interface DealOwner {
  id: string;
  name: string;
  email: string;
  team: string;
  role: string;
}

interface DealBrand {
  id: string;
  name: string;
  agency: string;
  vertical: string;
  region: string;
  spendTarget?: number;
}

interface EnhancedDeal extends Deal {
  owner: DealOwner;
  brand: DealBrand;
  recentActivity: Array<{
    type: string;
    message: string;
    timestamp: string;
  }>;
  trends: {
    performance: number[];
    revenue: number[];
    health: number[];
  };
}

// Filter options
const filterOptions = {
  status: [
    { value: 'active', label: 'Active' },
    { value: 'paused', label: 'Paused' },
    { value: 'pending', label: 'Pending' },
    { value: 'completed', label: 'Completed' }
  ],
  health: [
    { value: 'healthy', label: 'Healthy' },
    { value: 'warning', label: 'Warning' },
    { value: 'critical', label: 'Critical' }
  ],
  type: [
    { value: 'PMP', label: 'Private Marketplace' },
    { value: 'Preferred', label: 'Preferred Deals' },
    { value: 'Programmatic Guaranteed', label: 'Programmatic Guaranteed' }
  ],
  vertical: [
    { value: 'automotive', label: 'Automotive' },
    { value: 'finance', label: 'Finance' },
    { value: 'retail', label: 'Retail' },
    { value: 'tech', label: 'Technology' }
  ]
};

// Sort options
const sortOptions = [
  { value: 'health', label: 'Health Score' },
  { value: 'performance', label: 'Performance' },
  { value: 'revenue', label: 'Revenue' },
  { value: 'activity', label: 'Recent Activity' }
];

export function DealsTable({ onHealthCheck, onOptimize }: DealTableProps) {
  // State management
  const [selectedDeals, setSelectedDeals] = useState<string[]>([]);
  const [filters, setFilters] = useState<Record<string, string[]>>({});
  const [sortBy, setSortBy] = useState('health');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [showFilters, setShowFilters] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState<EnhancedDeal | null>(null);
  const [showDealDetails, setShowDealDetails] = useState(false);
  const [bulkAction, setBulkAction] = useState<string | null>(null);
  const [showAnalysis, setShowAnalysis] = useState(false);
  
  // Action handlers
  const handleBulkAction = async (action: string) => {
    if (selectedDeals.length === 0) return;

    switch (action) {
      case 'health':
        selectedDeals.forEach(id => onHealthCheck(id));
        break;
      case 'optimize':
        selectedDeals.forEach(id => onOptimize(id));
        break;
      case 'export':
        // Handle export
        break;
      default:
        break;
    }
    setBulkAction(null);
  };

  const handleEditDeal = (deal: EnhancedDeal) => {
    setSelectedDeal(deal);
    // Implement edit workflow
  };

  const handleAnalyzeDeal = (deal: EnhancedDeal) => {
    setSelectedDeal(deal);
    setShowAnalysis(true);
  };

  // Table components
  const HealthIndicator = ({ status, score }: { status: string; score: number }) => (
    <div className="flex items-center space-x-2">
      <div
        className={`h-2.5 w-2.5 rounded-full ${
          status === 'healthy' ? 'bg-green-500' :
          status === 'warning' ? 'bg-yellow-500' :
          'bg-red-500'
        }`}
      />
      <span className="font-medium">{score}</span>
      {status !== 'healthy' && (
        <Badge
          color={status === 'warning' ? 'yellow' : 'red'}
          icon={ExclamationTriangleIcon}
        >
          {status === 'warning' ? 'Needs Attention' : 'Critical'}
        </Badge>
      )}
    </div>
  );

  const PerformanceMetrics = ({ deal }: { deal: EnhancedDeal }) => {
    // Add fallbacks for possibly undefined values
    const actualSpend = deal.performance.actualSpend ?? 0;
    const targetSpend = deal.performance.targetSpend ?? 100000; // Using default target
    const pacingPercentage = targetSpend > 0 ? (actualSpend / targetSpend) * 100 : 0;
    const isPacingBehind = pacingPercentage < 90;
    const isPacingAhead = pacingPercentage > 110;
    
    return (
      <div className="space-y-3">
        {/* Win Rate */}
        <div className="flex items-center justify-between">
          <Text className="text-sm">Win Rate</Text>
          <div className="flex items-center space-x-1">
            <Text className="font-medium">{deal.performance.winRate}%</Text>
            <Badge color={deal.performance.winRate > 20 ? 'green' : 'red'}>
              {deal.trends.performance[deal.trends.performance.length - 1] > 
               deal.trends.performance[deal.trends.performance.length - 2] ? '↑' : '↓'}
            </Badge>
          </div>
        </div>

        {/* Spend */}
        <div className="flex items-center justify-between">
          <Text className="text-sm">Spend</Text>
          <div className="flex items-center space-x-1">
            <Text className="font-medium">
              ${(deal.performance.actualSpend || 0).toLocaleString()}
            </Text>
            <Text className="text-sm text-gray-500">
              /${(deal.performance.targetSpend || 0).toLocaleString()}
            </Text>
          </div>
        </div>

        {/* Pacing Indicator */}
        <div>
          <div className="flex items-center justify-between mb-1">
            <Text className="text-sm">Pacing</Text>
            <Badge
              color={
                isPacingBehind ? 'red' :
                isPacingAhead ? 'yellow' : 'green'
              }
            >
              {pacingPercentage.toFixed(0)}%
            </Badge>
          </div>
          <div className="h-1.5 w-full bg-gray-200 rounded-full overflow-hidden">
            <div
              className={`h-full ${
                isPacingBehind ? 'bg-red-500' :
                isPacingAhead ? 'bg-yellow-500' : 'bg-green-500'
              }`}
              style={{ width: `${Math.min(pacingPercentage, 100)}%` }}
            />
          </div>
        </div>
      </div>
    );
  };

  const BrandInfo = ({ brand }: { brand: DealBrand }) => (
    <div className="flex items-start space-x-3">
      <div className="flex-shrink-0">
        <div className="h-8 w-8 rounded-full bg-gray-100 flex items-center justify-center">
          <BuildingOfficeIcon className="h-4 w-4 text-gray-600" />
        </div>
      </div>
      <div>
        <Text className="font-medium">{brand.name}</Text>
        <div className="flex flex-col space-y-1">
          <Text className="text-sm text-gray-500">{brand.agency}</Text>
          <div className="flex items-center space-x-2">
            <Badge color="gray">{brand.vertical}</Badge>
            <Badge color="gray">{brand.region}</Badge>
          </div>
        </div>
      </div>
    </div>
  );

  const OwnerInfo = ({ owner }: { owner: DealOwner }) => (
    <div className="flex items-start space-x-3">
      <div className="flex-shrink-0">
        <div className="h-8 w-8 rounded-full bg-gray-100 flex items-center justify-center">
          <UserGroupIcon className="h-4 w-4 text-gray-600" />
        </div>
      </div>
      <div>
        <Text className="font-medium">{owner.name}</Text>
        <div className="flex flex-col space-y-1">
          <Text className="text-sm text-gray-500">{owner.team}</Text>
          <Badge color="gray">{owner.role}</Badge>
        </div>
      </div>
    </div>
  );

  const ActionMenu = ({ deal }: { deal: EnhancedDeal }) => (
    <div className="flex items-center space-x-2">
      <Button
        variant="secondary"
        size="sm"
        onClick={() => handleAnalyzeDeal(deal)}
      >
        <ChartBarIcon className="h-4 w-4 mr-1" />
        Analyze
      </Button>
      <Button
        variant="secondary"
        size="sm"
        onClick={() => {/* Do nothing */}}
      >
        <ShieldCheckIcon className="h-4 w-4 mr-1" />
        Health
      </Button>
      <Button
        variant="primary"
        size="sm"
        onClick={() => {/* Do nothing */}}
      >
        <SparklesIcon className="h-4 w-4 mr-1" />
        Optimize
      </Button>
    </div>
  );

  // Filter panel
  const FilterPanel = () => (
    <Card className="mb-4">
      <div className="p-4 space-y-4">
        <div className="flex items-center justify-between">
          <Text className="font-medium">Filters</Text>
          <Button
            variant="ghost"
            size="sm"
            onClick={() => setFilters({})}
          >
            Clear All
          </Button>
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
          {Object.entries(filterOptions).map(([key, options]) => (
            <div key={key}>
              <Text className="mb-2 capitalize">{key}</Text>
              <MultiSelect
                value={filters[key] || []}
                onValueChange={(value) => setFilters(prev => ({
                  ...prev,
                  [key]: value
                }))}
                placeholder={`Select ${key}`}
              >
                {options.map(option => (
                  <MultiSelectItem key={option.value} value={option.value}>
                    {option.label}
                  </MultiSelectItem>
                ))}
              </MultiSelect>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );

  return (
    <div className="space-y-4">
      {/* Table Actions */}
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <Button
            variant="secondary"
            size="sm"
            onClick={() => setShowFilters(!showFilters)}
          >
            <FunnelIcon className="h-4 w-4 mr-2" />
            Filters
            {Object.values(filters).flat().length > 0 && (
              <Badge color="blue" className="ml-2">
                {Object.values(filters).flat().length}
              </Badge>
            )}
          </Button>
          <Select
            value={sortBy}
            onValueChange={setSortBy}
            className="w-48"
          >
            {sortOptions.map(option => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </Select>
        </div>
        <div className="flex items-center space-x-2">
          {selectedDeals.length > 0 && (
            <Select
              value={bulkAction || ''}
              onValueChange={(value) => handleBulkAction(value)}
              placeholder="Bulk Actions"
              className="w-40"
            >
              <SelectItem value="health">Health Check</SelectItem>
              <SelectItem value="optimize">Optimize</SelectItem>
              <SelectItem value="export">Export</SelectItem>
            </Select>
          )}
          <Button variant="secondary" size="sm">
            <ArrowPathIcon className="h-4 w-4 mr-2" />
            Refresh
          </Button>
          <Button variant="secondary" size="sm">
            <DocumentMagnifyingGlassIcon className="h-4 w-4 mr-2" />
            Export
          </Button>
        </div>
      </div>

      {/* Filters Panel */}
      {showFilters && <FilterPanel />}

      {/* Main Table */}
      <Card>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeaderCell>
                <input
                  type="checkbox"
                  checked={selectedDeals.length === mockDeals.length}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedDeals(mockDeals.map(d => d.id));
                    } else {
                      setSelectedDeals([]);
                    }
                  }}
                  className="h-4 w-4 rounded border-gray-300"
                />
              </TableHeaderCell>
              <TableHeaderCell>Deal Details</TableHeaderCell>
              <TableHeaderCell>Brand & Agency</TableHeaderCell>
              <TableHeaderCell>Owner</TableHeaderCell>
              <TableHeaderCell>Health</TableHeaderCell>
              <TableHeaderCell>Performance</TableHeaderCell>
              <TableHeaderCell>Actions</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mockDeals.map((deal: EnhancedDeal) => (
              <TableRow key={deal.id}>
                <TableCell>
                  <input
                    type="checkbox"
                    checked={selectedDeals.includes(deal.id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedDeals([...selectedDeals, deal.id]);
                      } else {
                        setSelectedDeals(selectedDeals.filter(id => id !== deal.id));
                      }
                    }}
                    className="h-4 w-4 rounded border-gray-300"
                  />
                </TableCell>
                <TableCell>
                  <div className="space-y-1">
                    <Text className="font-medium">{deal.name}</Text>
                    <Badge color="gray">{deal.type}</Badge>
                    <Text className="text-sm text-gray-500">
                      Created {new Date(deal.health.lastChecked).toLocaleDateString()}
                    </Text>
                  </div>
                </TableCell>
                <TableCell>
                  <BrandInfo brand={deal.brand} />
                </TableCell>
                <TableCell>
                  <OwnerInfo owner={deal.owner} />
                </TableCell>
                <TableCell>
                  <HealthIndicator 
                    status={deal.health.status} 
                    score={deal.health.score}
                  />
                </TableCell>
                <TableCell>
                  <PerformanceMetrics deal={deal} />
                </TableCell>
                <TableCell>
                  <ActionMenu 
                  deal={deal}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {/* Pagination */}
        <div className="p-4 border-t flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <Text className="text-sm text-gray-600">
              Showing {mockDeals.length} of {mockDeals.length} deals
            </Text>
            {selectedDeals.length > 0 && (
              <Badge color="blue">
                {selectedDeals.length} selected
              </Badge>
            )}
          </div>
          <div className="flex items-center space-x-2">
            <Button
              variant="secondary"
              size="sm"
              disabled={true}
            >
              Previous
            </Button>
            <div className="flex items-center space-x-1">
              <Button
                variant="primary"
                size="sm"
              >
                1
              </Button>
              <Button
                variant="secondary"
                size="sm"
              >
                2
              </Button>
              <Button
                variant="secondary"
                size="sm"
              >
                3
              </Button>
            </div>
            <Button
              variant="secondary"
              size="sm"
            >
              Next
            </Button>
          </div>
        </div>
      </Card>

      {/* Analysis Dialog */}
      {selectedDeal && (
        <DealAnalysisDialog
          deal={selectedDeal}
          isOpen={showAnalysis}
          onClose={() => {
            setShowAnalysis(false);
            setSelectedDeal(null);
          }}
        />
      )}


      {/* Deal Details Modal */}
          {selectedDeal && (
      <Dialog
        open={showDealDetails}
        onClose={() => setShowDealDetails(false)}
      >
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-screen items-center justify-center">
            <Dialog.Panel className="relative bg-white w-full max-w-4xl rounded-xl shadow-xl p-6">
              <div className="space-y-6">
                {/* Header */}
                <div className="flex items-center justify-between">
                  <div>
                    <Dialog.Title className="text-xl font-semibold">
                      Deal Details
                    </Dialog.Title>
                    <Text className="text-gray-500">
                      {selectedDeal.name}
                    </Text>
                  </div>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => setShowDealDetails(false)}
                  >
                    <XMarkIcon className="h-5 w-5" />
                  </Button>
                </div>

                {/* Deal Information Tabs */}
                <TabGroup>
                  <TabList className="flex space-x-2">
                    <Tab className="ui-selected:bg-blue-500 ui-selected:text-white px-4 py-2 rounded-lg">
                      Overview
                    </Tab>
                    <Tab className="ui-selected:bg-blue-500 ui-selected:text-white px-4 py-2 rounded-lg">
                      Performance
                    </Tab>
                    <Tab className="ui-selected:bg-blue-500 ui-selected:text-white px-4 py-2 rounded-lg">
                      History
                    </Tab>
                    <Tab className="ui-selected:bg-blue-500 ui-selected:text-white px-4 py-2 rounded-lg">
                      Settings
                    </Tab>
                  </TabList>

                  <TabPanels>
                    {/* Overview Panel */}
                    <TabPanel>
                      <div className="grid grid-cols-2 gap-6">
                        <Card>
                          <div className="p-4 space-y-4">
                            <Title>Deal Information</Title>
                            <div className="space-y-2">
                              <div className="grid grid-cols-2 gap-2">
                                <Text className="text-gray-600">Type</Text>
                                <Text>{selectedDeal.type}</Text>
                              </div>
                              <div className="grid grid-cols-2 gap-2">
                                <Text className="text-gray-600">Status</Text>
                                <Badge color={selectedDeal.status === 'active' ? 'green' : 'yellow'}>
                                  {selectedDeal.status}
                                </Badge>
                              </div>
                              <div className="grid grid-cols-2 gap-2">
                                <Text className="text-gray-600">Created</Text>
                                <Text>{new Date(selectedDeal.health.lastChecked).toLocaleDateString()}</Text>
                              </div>
                            </div>
                          </div>
                        </Card>

                        <Card>
                          <div className="p-4 space-y-4">
                            <Title>Performance Metrics</Title>
                            <PerformanceMetrics deal={selectedDeal} />
                          </div>
                        </Card>

                        <Card>
                          <div className="p-4 space-y-4">
                            <Title>Brand & Agency</Title>
                            <BrandInfo brand={selectedDeal.brand} />
                          </div>
                        </Card>

                        <Card>
                          <div className="p-4 space-y-4">
                            <Title>Owner & Team</Title>
                            <OwnerInfo owner={selectedDeal.owner} />
                          </div>
                        </Card>
                      </div>
                    </TabPanel>

                    {/* Performance Panel */}
                    <TabPanel>
                      <div className="space-y-6">
                        {/* Performance Trends */}
                        <Card>
                          <div className="p-4 space-y-4">
                            <Title>Performance Trends</Title>
                            <div className="h-64">
                              <AreaChart
                                data={[...Array(30)].map((_, i) => ({
                                  date: i.toString(),
                                  Performance: selectedDeal.trends.performance[i] || 0,
                                  Revenue: selectedDeal.trends.revenue[i] || 0,
                                  Health: selectedDeal.trends.health[i] || 0
                                }))}
                                index="date"
                                categories={['Performance', 'Revenue', 'Health']}
                                colors={['blue', 'green', 'yellow']}
                              />
                            </div>
                          </div>
                        </Card>

                        {/* Key Metrics */}
                        <div className="grid grid-cols-3 gap-6">
                          <Card>
                            <div className="p-4">
                              <Text className="text-gray-600">Win Rate</Text>
                              <Metric>{selectedDeal.performance.winRate}%</Metric>
                              <ProgressBar
                                value={selectedDeal.performance.winRate}
                                color="blue"
                                className="mt-2"
                              />
                            </div>
                          </Card>
                          <Card>
                            <div className="p-4">
                              <Text className="text-gray-600">Viewability</Text>
                              <Metric>{selectedDeal.performance.viewability}%</Metric>
                              <ProgressBar
                                value={selectedDeal.performance.viewability}
                                color="green"
                                className="mt-2"
                              />
                            </div>
                          </Card>
                          <Card>
                            <div className="p-4">
                              <Text className="text-gray-600">Health Score</Text>
                              <Metric>{selectedDeal.health.score}</Metric>
                              <ProgressBar
                                value={selectedDeal.health.score}
                                color={
                                  selectedDeal.health.status === 'healthy' ? 'green' :
                                  selectedDeal.health.status === 'warning' ? 'yellow' : 'red'
                                }
                                className="mt-2"
                              />
                            </div>
                          </Card>
                        </div>
                      </div>
                    </TabPanel>

                    {/* History Panel */}
                    <TabPanel>
                      <Card>
                        <div className="p-4">
                          <Title>Recent Activity</Title>
                          <List className="mt-4">
                            {selectedDeal.recentActivity.map((activity, index) => (
                              <ListItem key={index}>
                                <div className="flex justify-between items-center">
                                  <div>
                                    <Text className="font-medium">{activity.type}</Text>
                                    <Text className="text-sm text-gray-600">
                                      {activity.message}
                                    </Text>
                                  </div>
                                  <Text className="text-sm text-gray-500">
                                    {new Date(activity.timestamp).toLocaleString()}
                                  </Text>
                                </div>
                              </ListItem>
                            ))}
                          </List>
                        </div>
                      </Card>
                    </TabPanel>

                    {/* Settings Panel */}
                    <TabPanel>
                      <div className="space-y-6">
                        <Card>
                          <div className="p-4">
                            <Title>Deal Configuration</Title>
                            {/* Add settings form */}
                          </div>
                        </Card>
                      </div>
                    </TabPanel>
                  </TabPanels>
                </TabGroup>

                {/* Footer Actions */}
                <div className="flex justify-between pt-6 border-t">
                  <Button
                    variant="secondary"
                    onClick={() => setShowDealDetails(false)}
                  >
                    Close
                  </Button>
                  <div className="flex space-x-2">
                    <Button
                      variant="secondary"
                      onClick={() => onHealthCheck(selectedDeal.id)}
                    >
                      <ShieldCheckIcon className="h-4 w-4 mr-2" />
                      Check Health
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => onOptimize(selectedDeal.id)}
                    >
                      <SparklesIcon className="h-4 w-4 mr-2" />
                      Optimize
                    </Button>
                  </div>
                </div>
              </div>
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
}