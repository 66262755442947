import { useState } from 'react';
import type { ReactNode } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  Badge,
  Text,
  Card,
  Tab,
  TabGroup,
  TabList,
  MultiSelect,
  MultiSelectItem
} from '@tremor/react';
import {
  FunnelIcon,
  ChartBarIcon,
  ExclamationTriangleIcon,
  CheckCircleIcon,
  XMarkIcon,
  TableCellsIcon,
  Squares2X2Icon
} from '@heroicons/react/24/outline';
import { Button } from '../ui/Button';
import { mockDeals } from '../../data/dealMockData';
import type { Deal } from '../../types/deals';


// Type definitions
interface DealListProps {
    mode?: 'standard' | 'optimization' | 'summary';
    onHealthCheck?: (dealId: string) => void;
    onOptimize?: (dealId: string) => void;
  }
  
  export interface FilterOption {
    value: string;
    label: string;
    count?: number;
  }
  
  export type FilterOptionKey = 'status' | 'health' | 'type' | 'performance' | 'optimizationStatus';
  
  interface FilterState {
    [key: string]: string[];
  }

  interface TabProps {
    selected: boolean;
  }
  

//   interface FilterState {
//     status: string[];
//     health: string[];
//     type: string[];
//     performance: string[];
//     optimizationStatus: string[];
//   }

// Filter definitions
const filterOptions: Record<FilterOptionKey | 'quickFilters', FilterOption[]> = {
  status: [
    { value: 'active', label: 'Active' },
    { value: 'paused', label: 'Paused' },
    { value: 'critical', label: 'Critical' },
    { value: 'pending', label: 'Pending Review' }
  ],
  health: [
    { value: 'healthy', label: 'Healthy' },
    { value: 'warning', label: 'Needs Attention' },
    { value: 'critical', label: 'Critical' }
  ],
  type: [
    { value: 'PMP', label: 'Private Marketplace' },
    { value: 'Preferred', label: 'Preferred Deals' },
    { value: 'Programmatic Guaranteed', label: 'Programmatic Guaranteed' }
  ],
  performance: [
    { value: 'above_target', label: 'Above Target' },
    { value: 'at_target', label: 'At Target' },
    { value: 'below_target', label: 'Below Target' },
    { value: 'at_risk', label: 'At Risk' }
  ],
  optimizationStatus: [
    { value: 'never_optimized', label: 'Never Optimized' },
    { value: 'recently_optimized', label: 'Recently Optimized' },
    { value: 'needs_optimization', label: 'Needs Optimization' }
  ],
  quickFilters: [
    { value: 'critical_health', label: 'Critical Health Issues', count: 5 },
    { value: 'no_bids', label: 'No Bid Response', count: 3 },
    { value: 'low_win_rate', label: 'Low Win Rate', count: 8 },
    { value: 'optimization_ready', label: 'Ready for Optimization', count: 12 }
  ]
} 

interface TabClassNameProps {
    selected: boolean;
  }
  

export function DealList({ mode = 'standard', onHealthCheck, onOptimize }: DealListProps) {
  // State management with proper typing
  const [selectedDeals, setSelectedDeals] = useState<string[]>([]);
  const [showFilters, setShowFilters] = useState(false);
  const [activeView, setActiveView] = useState<'table' | 'cards'>('table');
  const [filters, setFilters] = useState<FilterState>({
    status: [],
    health: [],
    type: [],
    performance: [],
    optimizationStatus: []
  });
  const [quickFilter, setQuickFilter] = useState<string | null>(null);

  const [sortBy, setSortBy] = useState('health');
  const [sortOrder, setSortOrder] = useState('desc');

    // Define the className function with proper typing
    const getTabClassName = ({ selected }: TabProps): string => 
        `flex items-center px-3 py-2 rounded-md text-sm ${
            selected ? 'bg-white shadow' : 'text-gray-600 hover:text-gray-900'
        }`;

    
    // Reset filters function
    const resetFilters = () => {
        setFilters({
          status: [],
          health: [],
          type: [],
          performance: [],
          optimizationStatus: []
        });
        setQuickFilter(null);
      };

      
  // Filter update function with type safety
  const updateFilter = (key: keyof FilterState, value: string[]) => {
    setFilters(prev => ({
      ...prev,
      [key]: value
    }));
  };

  // Filter panel component
  const FilterPanel = (): JSX.Element  => (
    <Card className="mb-4">
      <div className="p-4">
        <div className="flex items-center justify-between mb-4">
          <Text className="font-medium">Filters</Text>
          <Button 
            variant="ghost" 
            size="sm" 
            onClick={resetFilters}
          >
            Clear All
          </Button>
        </div>

        
        {/* Quick Filters */}
        <div className="mb-6">
          <Text className="mb-2">Quick Filters</Text>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
            {filterOptions.quickFilters.map(filter => (
              <Button
                key={filter.value}
                variant={quickFilter === filter.value ? 'primary' : 'secondary'}
                size="sm"
                onClick={() => setQuickFilter(
                  quickFilter === filter.value ? null : filter.value
                )}
                className="justify-between"
              >
                <span>{filter.label}</span>
                <Badge color="blue">{filter.count}</Badge>
              </Button>
            ))}
          </div>
        </div>

        {/* Advanced Filters */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {Object.entries(filterOptions)
            .filter(([key]) => key !== 'quickFilters')
            .map(([key, options]) => (
              <div key={key}>
                <Text className="mb-2">{key.charAt(0).toUpperCase() + key.slice(1)}</Text>
                <MultiSelect
                  value={filters[key]}
                  onValueChange={(value: string[]) => updateFilter(key as keyof FilterState, value)}
                  placeholder={`Select ${key}`}
                >
                  {options.map(option => (
                    <MultiSelectItem key={option.value} value={option.value}>
                      {option.label}
                    </MultiSelectItem>
                  ))}
                </MultiSelect>
              </div>
            ))}
            </div>
          </div>
        </Card>
      );



  // Helper function to get color for health status
  const getHealthColor = (status: string): "green" | "yellow" | "red" | "gray" => {
    switch (status) {
      case 'healthy': return 'green';
      case 'warning': return 'yellow';
      case 'critical': return 'red';
      default: return 'gray';
    }
  };


  // Render table view
  const TableView = () => (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeaderCell>
            <input
              type="checkbox"
              checked={selectedDeals.length === mockDeals.length}
              onChange={(e) => setSelectedDeals(
                e.target.checked ? mockDeals.map(d => d.id) : []
              )}
              className="h-4 w-4 rounded border-gray-300"
            />
          </TableHeaderCell>
          <TableHeaderCell>Deal Details</TableHeaderCell>
          <TableHeaderCell>Health Status</TableHeaderCell>
          <TableHeaderCell>Performance</TableHeaderCell>
          <TableHeaderCell>Last Updated</TableHeaderCell>
          <TableHeaderCell>Actions</TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {mockDeals.map((deal) => (
          <TableRow key={deal.id}>
            <TableCell>
              <input
                type="checkbox"
                checked={selectedDeals.includes(deal.id)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedDeals([...selectedDeals, deal.id]);
                  } else {
                    setSelectedDeals(selectedDeals.filter(id => id !== deal.id));
                  }
                }}
                className="h-4 w-4 rounded border-gray-300"
              />
            </TableCell>
            <TableCell>
              <div className="flex flex-col">
                <Text className="font-medium">{deal.name}</Text>
                <Text className="text-sm text-gray-500">{deal.advertiser}</Text>
                <Badge color="gray" className="mt-1 w-fit">
                  {deal.type}
                </Badge>
              </div>
            </TableCell>
            <TableCell>
              <div className="flex items-center space-x-2">
                <Badge
                  color={getHealthColor(deal.health.status)}
                  icon={deal.health.status === 'healthy' ? CheckCircleIcon : ExclamationTriangleIcon}
                >
                  {deal.health.score}
                </Badge>
                {deal.health.issues.length > 0 && (
                  <Text className="text-sm text-gray-500">
                    {deal.health.issues[0].message}
                  </Text>
                )}
              </div>
            </TableCell>
            <TableCell>
              <div className="space-y-1">
                <div className="flex items-center justify-between">
                  <Text className="text-sm">Win Rate</Text>
                  <Text className="text-sm font-medium">{deal.performance.winRate}%</Text>
                </div>
                <div className="flex items-center justify-between">
                  <Text className="text-sm">Viewability</Text>
                  <Text className="text-sm font-medium">{deal.performance.viewability}%</Text>
                </div>
              </div>
            </TableCell>
            <TableCell>
              <Text className="text-sm">{deal.health.lastChecked}</Text>
            </TableCell>
            <TableCell>
              <div className="flex space-x-2">
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => onHealthCheck?.(deal.id)}
                >
                  Check Health
                </Button>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => onOptimize?.(deal.id)}
                >
                  Optimize
                </Button>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  
  // Render card view
  const CardView = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {mockDeals.map((deal) => (
        <Card key={deal.id} className="p-4">
          <div className="flex justify-between items-start mb-4">
            <div>
              <Text className="font-medium">{deal.name}</Text>
              <Text className="text-sm text-gray-500">{deal.advertiser}</Text>
            </div>
            <Badge color={getHealthColor(deal.health.status)}>
              {deal.health.score}
            </Badge>
          </div>
          
          <div className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Text className="text-sm text-gray-500">Win Rate</Text>
                <Text className="font-medium">{deal.performance.winRate}%</Text>
              </div>
              <div>
                <Text className="text-sm text-gray-500">Viewability</Text>
                <Text className="font-medium">{deal.performance.viewability}%</Text>
              </div>
            </div>

            {deal.health.issues.length > 0 && (
              <div className="bg-amber-50 p-3 rounded-lg">
                <Text className="text-sm text-amber-800">
                  {deal.health.issues[0].message}
                </Text>
              </div>
            )}

            <div className="flex space-x-2">
              <Button
                variant="secondary"
                size="sm"
                className="flex-1"
                onClick={() => onHealthCheck?.(deal.id)}
              >
                Check Health
              </Button>
              <Button
                variant="primary"
                size="sm"
                className="flex-1"
                onClick={() => onOptimize?.(deal.id)}
              >
                Optimize
              </Button>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );

  
  return (
    <div className="space-y-4">
      {/* Header */}
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <Text className="font-medium">
            {mockDeals.length} deals
          </Text>
          <Button
            variant="secondary"
            size="sm"
            onClick={() => setShowFilters(!showFilters)}
          >
            <FunnelIcon className="h-4 w-4 mr-2" />
            Filters
          </Button>
        </div>
        

        {/* View Toggle */}
        <div className="flex items-center space-x-2">
        <div className="flex space-x-1 rounded-lg bg-gray-100 p-1">
          <button
            onClick={() => setActiveView('table')}
            className={`flex items-center px-3 py-2 rounded-md text-sm ${
              activeView === 'table' 
                ? 'bg-white shadow text-blue-600' 
                : 'text-gray-600 hover:text-gray-900'
            }`}
          >
            <TableCellsIcon className="h-4 w-4 mr-2" />
            Table
          </button>
          <button
            onClick={() => setActiveView('cards')}
            className={`flex items-center px-3 py-2 rounded-md text-sm ${
              activeView === 'cards' 
                ? 'bg-white shadow text-blue-600' 
                : 'text-gray-600 hover:text-gray-900'
            }`}
          >
            <Squares2X2Icon className="h-4 w-4 mr-2" />
            Cards
          </button>
        </div>
          
          {selectedDeals.length > 0 && (
            <div className="flex items-center space-x-2">
              <Badge color="blue">
                {selectedDeals.length} selected
              </Badge>
              <Button variant="secondary" size="sm">
                Bulk Actions
              </Button>
            </div>
          )}
        </div>
      </div>

      {/* Active Filters Display */}
      {(Object.values(filters).some(arr => arr.length > 0) || quickFilter) && (
        <div className="flex flex-wrap gap-2 p-2 bg-gray-50 rounded-lg">
          {quickFilter && (
            <Badge
              color="blue"
              className="flex items-center"
            >
              {filterOptions.quickFilters.find(f => f.value === quickFilter)?.label}
              <XMarkIcon
                className="h-4 w-4 ml-2 cursor-pointer"
                onClick={() => setQuickFilter(null)}
              />
            </Badge>
          )}
          {Object.entries(filters).map(([key, values]) =>
            values.map(value => (
              <Badge
                key={`${key}-${value}`}
                color="gray"
                className="flex items-center"
              >
                {filterOptions[key as FilterOptionKey].find(f => f.value === value)?.label}
                <XMarkIcon
                  className="h-4 w-4 ml-2 cursor-pointer"
                  onClick={() => {
                    setFilters(prev => ({
                      ...prev,
                      [key]: prev[key].filter(v => v !== value)
                    }));
                  }}
                />
              </Badge>
            ))
          )}
          <Button
            variant="ghost"
            size="sm"
            onClick={() => {
              setFilters({
                status: [],
                health: [],
                type: [],
                performance: [],
                optimizationStatus: []
              });
              setQuickFilter(null);
            }}
          >
            Clear all filters
          </Button>
        </div>
      )}

      {/* Filters Panel */}
      {showFilters && <FilterPanel />}

      {/* Intelligence Summary */}
      <Card className="bg-blue-50 border-blue-100">
        <div className="p-4">
          <div className="flex items-center space-x-2 mb-2">
            <ChartBarIcon className="h-5 w-5 text-blue-600" />
            <Text className="font-medium">Deal Intelligence Summary</Text>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <Text className="text-sm text-gray-600">Critical Issues</Text>
              <div className="flex items-center space-x-2 mt-1">
                <Text className="text-2xl font-bold text-red-600">5</Text>
                <Text className="text-sm text-gray-500">deals need immediate attention</Text>
              </div>
            </div>
            <div>
              <Text className="text-sm text-gray-600">Optimization Ready</Text>
              <div className="flex items-center space-x-2 mt-1">
                <Text className="text-2xl font-bold text-amber-600">12</Text>
                <Text className="text-sm text-gray-500">deals can be optimized</Text>
              </div>
            </div>
            <div>
              <Text className="text-sm text-gray-600">Healthy Deals</Text>
              <div className="flex items-center space-x-2 mt-1">
                <Text className="text-2xl font-bold text-green-600">83%</Text>
                <Text className="text-sm text-gray-500">of active deals performing well</Text>
              </div>
            </div>
          </div>
        </div>
      </Card>

      {/* Main Content */}
      <div className="bg-white rounded-lg border">
        {activeView === 'table' ? <TableView /> : <CardView />}
      </div>

      {/* Bulk Action Modal */}
      {selectedDeals.length > 0 && (
        <div className="fixed bottom-0 inset-x-0 p-4 bg-white border-t shadow-lg transform translate-y-0 transition-transform duration-300">
          <div className="max-w-7xl mx-auto flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <Text className="font-medium">
                {selectedDeals.length} deals selected
              </Text>
              <Badge color="blue">
                {mockDeals.filter(d => selectedDeals.includes(d.id) && d.health.status === 'critical').length} critical
              </Badge>
              <Badge color="yellow">
                {mockDeals.filter(d => selectedDeals.includes(d.id) && d.health.status === 'warning').length} warning
              </Badge>
            </div>
            <div className="flex items-center space-x-2">
              <Button
                variant="secondary"
                onClick={() => setSelectedDeals([])}
              >
                Clear Selection
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  // Handle bulk optimization
                  selectedDeals.forEach(id => onOptimize?.(id));
                }}
              >
                Optimize Selected Deals
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}