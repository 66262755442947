// src/components/deals/steps/BudgetStep.tsx
import { useState, useMemo } from 'react';
import { 
  Card, 
  Title, 
  Text, 
  Badge, 
  ProgressBar,
  Metric,
  AreaChart, Button
} from '@tremor/react';
import { 
  CurrencyDollarIcon,
  ChartBarIcon,
  ClockIcon,
  SparklesIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon 
} from '@heroicons/react/24/outline';
import { 
    DealConfiguration, 
    PricingModelType, 
    PacingStrategy,
    KPIType,
    StepComponentProps
  } from '../../../types/dealTypes';

interface BudgetStepProps {
  configuration: DealConfiguration;
  onUpdate: (updates: Partial<DealConfiguration>) => void;
}


export function BudgetStep({ 
    configuration,
    onUpdate,
    onNext,
    onBack,
    onLaunch
  }: StepComponentProps) {


  const pricingModels = [
    { id: 'CPM' as PricingModelType, name: 'Cost Per Mille (CPM)' },
    { id: 'CPC' as PricingModelType, name: 'Cost Per Click (CPC)' },
    { id: 'CPCV' as PricingModelType, name: 'Cost Per Completed View (CPCV)' }
  ];
  
  const pacingStrategies = [
    { id: 'even' as PacingStrategy, name: 'Even Delivery' },
    { id: 'front_loaded' as PacingStrategy, name: 'Front Loaded' },
    { id: 'back_loaded' as PacingStrategy, name: 'Back Loaded' }
  ];

  const [selectedPacingStrategy, setSelectedPacingStrategy] = 
  useState<PacingStrategy>('even');

  const handleStrategyClick = (strategyId: PacingStrategy) => {
    setSelectedPacingStrategy(strategyId);
    onUpdate({
      budget: {
        ...configuration.budget,
        pacing_strategy: strategyId
      }
    });
  };
  
  // Update handlers
const handlePacingStrategyChange = (strategyId: PacingStrategy) => {
    setSelectedPacingStrategy(strategyId);
    onUpdate({
      budget: {
        ...configuration.budget,
        pacing_strategy: strategyId
      }
    });
  };
  
  const handlePricingModelChange = (modelId: PricingModelType) => {
    onUpdate({
      budget: {
        ...configuration.budget,
        pricing: {
          ...configuration.budget.pricing,
          model: modelId
        }
      }
    });
  };
  
  const handleKPIChange = (kpi: KPIType, isPrimary: boolean = true) => {
    if (isPrimary) {
      onUpdate({
        goals: {
          ...configuration.goals,
          primary: {
            ...configuration.goals.primary,
            kpi
          }
        }
      });
    }
};

  // Calculate delivery and spend projections
  const projections = useMemo(() => {
    // Add your projection calculations here
    return {
      daily: Array.from({ length: 30 }, (_, i) => ({
        day: i + 1,
        spend: Math.random() * 1000,
        impressions: Math.random() * 100000,
        reach: Math.random() * 50000
      })),
      summary: {
        totalSpend: configuration.budget.total,
        avgCpm: 15.50,
        projectedDelivery: 0.95
      }
    };
  }, [configuration.budget]);

  return (
    <div className="space-y-6">
      {/* Budget Configuration */}
      <Card>
        <Title>Budget Settings</Title>
        <div className="mt-6 space-y-6">
          {/* Total Budget */}
          <div>
            <Text className="font-medium">Total Budget</Text>
            <div className="mt-2 grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm text-gray-500">
                  Campaign Budget
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>
                  <input
                    type="number"
                    className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                    value={configuration.budget.total}
                    onChange={(e) => onUpdate({
                      budget: {
                        ...configuration.budget,
                        total: Number(e.target.value)
                      }
                    })}
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm text-gray-500">
                  Daily Budget
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>
                  <input
                    type="number"
                    className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                    value={configuration.budget.daily}
                    onChange={(e) => onUpdate({
                      budget: {
                        ...configuration.budget,
                        daily: Number(e.target.value)
                      }
                    })}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Pacing Strategy */}
          <div>
            <Text className="font-medium">Pacing Strategy</Text>
            <div className="mt-4 grid grid-cols-3 gap-4">
              {[
                {
                  id: 'even',
                  name: 'Even Delivery',
                  description: 'Consistent daily spend throughout campaign',
                  icon: ChartBarIcon
                },
                {
                  id: 'front_loaded',
                  name: 'Front Loaded',
                  description: 'Higher spend in early campaign days',
                  icon: ChartBarIcon
                },
                {
                  id: 'back_loaded',
                  name: 'Back Loaded',
                  description: 'Higher spend in later campaign days',
                  icon: ChartBarIcon
                }
              ].map((strategy) => (
                <div
                  key={strategy.id}
                  className={`
                    p-4 rounded-lg border cursor-pointer transition-all
                    ${selectedPacingStrategy === strategy.id
                      ? 'border-blue-500 bg-blue-50'
                      : 'border-gray-200 hover:bg-gray-50'
                    }
                  `}
                  onClick={() => handleStrategyClick(strategy.id as PacingStrategy)}
                >
                  <div className="flex items-start space-x-3">
                    <div className={`
                      p-2 rounded-lg
                      ${selectedPacingStrategy === strategy.id
                        ? 'bg-blue-100 text-blue-600'
                        : 'bg-gray-100 text-gray-600'
                      }
                    `}>
                      <strategy.icon className="h-5 w-5" />
                    </div>
                    <div>
                      <Text className="font-medium">{strategy.name}</Text>
                      <Text className="text-sm text-gray-500 mt-1">
                        {strategy.description}
                      </Text>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Pricing Model */}
          <div className="mt-6">
            <Text className="font-medium">Pricing Model</Text>
            <div className="mt-4 grid grid-cols-3 gap-4">
              {[
                {
                  id: 'CPM',
                  name: 'Cost Per Mille (CPM)',
                  description: 'Pay per thousand impressions',
                  metrics: ['Impressions', 'Viewability', 'Reach'],
                  recommended: true
                },
                {
                  id: 'CPC',
                  name: 'Cost Per Click (CPC)',
                  description: 'Pay per user click',
                  metrics: ['Clicks', 'CTR', 'Conversion Rate']
                },
                {
                  id: 'CPCV',
                  name: 'Cost Per Completed View',
                  description: 'Pay per completed video view',
                  metrics: ['Completion Rate', 'Time Watched', 'Engagement']
                }
              ].map((model) => (
                <div
                  key={model.id}
                  className={`
                    p-4 rounded-lg border cursor-pointer transition-all
                    ${configuration.budget.pricing.model === model.id
                      ? 'border-blue-500 bg-blue-50'
                      : 'border-gray-200 hover:bg-gray-50'
                    }
                  `}
                  onClick={() => onUpdate({
                    budget: {
                      ...configuration.budget,
                      pricing: {
                        ...configuration.budget.pricing,
                        model: model.id as 'CPM' | 'CPC' | 'CPCV'
                      }
                    }
                  })}
                >
                  <div className="flex justify-between items-start">
                    <div>
                      <Text className="font-medium">{model.name}</Text>
                      <Text className="text-sm text-gray-500 mt-1">
                        {model.description}
                      </Text>
                    </div>
                    {model.recommended && (
                      <Badge color="blue" icon={SparklesIcon}>
                        Recommended
                      </Badge>
                    )}
                  </div>

                  <div className="mt-4">
                    <Text className="text-sm text-gray-500">Key Metrics</Text>
                    <div className="mt-2 flex flex-wrap gap-2">
                      {model.metrics.map((metric) => (
                        <Badge key={metric} color="gray" size="sm">
                          {metric}
                        </Badge>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Rate Configuration */}
          <div className="mt-6">
            <div className="flex items-center justify-between">
              <Text className="font-medium">Rate Configuration</Text>
              <Badge color="blue" icon={SparklesIcon}>
                AI Optimized
              </Badge>
            </div>
            
            <div className="mt-4 space-y-6">
              {/* Rate Input */}
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <label className="block text-sm text-gray-500">
                    Target Rate
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <span className="text-gray-500 sm:text-sm">$</span>
                    </div>
                    <input
                      type="number"
                      className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                      value={configuration.budget.pricing.rate}
                      onChange={(e) => onUpdate({
                        budget: {
                          ...configuration.budget,
                          pricing: {
                            ...configuration.budget.pricing,
                            rate: Number(e.target.value)
                          }
                        }
                      })}
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-sm text-gray-500">
                    Floor Price
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <span className="text-gray-500 sm:text-sm">$</span>
                    </div>
                    <input
                      type="number"
                      className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                      value={configuration.budget.pricing.floor || ''}
                      onChange={(e) => onUpdate({
                        budget: {
                          ...configuration.budget,
                          pricing: {
                            ...configuration.budget.pricing,
                            floor: Number(e.target.value)
                          }
                        }
                      })}
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-sm text-gray-500">
                    Ceiling Price
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <span className="text-gray-500 sm:text-sm">$</span>
                    </div>
                    <input
                      type="number"
                      className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                      value={configuration.budget.pricing.ceiling || ''}
                      onChange={(e) => onUpdate({
                        budget: {
                          ...configuration.budget,
                          pricing: {
                            ...configuration.budget.pricing,
                            ceiling: Number(e.target.value)
                          }
                        }
                      })}
                    />
                  </div>
                </div>
              </div>

              {/* Market Intelligence */}
              <Card className="bg-gray-50">
                <div className="flex items-center justify-between">
                  <Text className="font-medium">Market Intelligence</Text>
                  <Text className="text-sm text-gray-500">Last 30 Days</Text>
                </div>
                <div className="mt-4 grid grid-cols-3 gap-4">
                  <div>
                    <Text className="text-sm text-gray-500">Avg. Clearing Price</Text>
                    <Text className="font-medium">$14.75</Text>
                    <Text className="text-xs text-green-600">+5% vs prev period</Text>
                  </div>
                  <div>
                    <Text className="text-sm text-gray-500">Win Rate</Text>
                    <Text className="font-medium">68%</Text>
                    <Text className="text-xs text-red-600">-2% vs prev period</Text>
                  </div>
                  <div>
                    <Text className="text-sm text-gray-500">Bid Competition</Text>
                    <Text className="font-medium">Medium</Text>
                    <Text className="text-xs text-gray-500">3.2 avg bidders</Text>
                  </div>
                </div>
                <div className="mt-4">
                  <AreaChart
                    className="h-28"
                    data={[
                      { date: '2024-01', price: 12.5, volume: 1000000 },
                      { date: '2024-02', price: 13.2, volume: 1200000 },
                      { date: '2024-03', price: 14.75, volume: 980000 }
                    ]}
                    index="date"
                    categories={["price", "volume"]}
                    colors={["blue", "gray"]}
                  />
                </div>
              </Card>
            </div>
          </div>

          {/* Performance Goals */}
          <div className="mt-6">
            <Text className="font-medium">Performance Goals</Text>
            <div className="mt-4 space-y-4">
              {/* Primary KPI */}
              <div>
                <Text className="text-sm text-gray-500">Primary KPI</Text>
                <div className="mt-2 grid grid-cols-2 gap-4">
                                <select
                className="block w-full rounded-md border border-gray-300 px-3 py-2"
                value={configuration.goals.primary.kpi}
                onChange={(e) => onUpdate({
                    goals: {
                    ...configuration.goals,
                    primary: {
                        ...configuration.goals.primary,
                        kpi: e.target.value as KPIType
                    }
                    }
                })}
                >
                <option value="viewability">Viewability</option>
                <option value="ctr">CTR</option>
                <option value="completion">Completion Rate</option>
                <option value="conversion">Conversion Rate</option>
                </select>
                  <input
                    type="number"
                    className="block w-full rounded-md border border-gray-300 px-3 py-2"
                    placeholder="Target value"
                    value={configuration.goals.primary.target}
                    onChange={(e) => onUpdate({
                      goals: {
                        ...configuration.goals,
                        primary: {
                          ...configuration.goals.primary,
                          target: Number(e.target.value)
                        }
                      }
                    })}
                  />
                </div>
              </div>

              {/* Secondary KPIs */}
              <div>
                <div className="flex items-center justify-between">
                  <Text className="text-sm text-gray-500">Secondary KPIs</Text>
                  <Button variant="secondary" size="sm">
                    Add KPI
                  </Button>
                </div>
                <div className="mt-2 space-y-2">
                  {configuration.goals.secondary.map((kpi, index) => (
                    <div key={index} className="grid grid-cols-3 gap-4">
                    <select
                    className="block w-full rounded-md border border-gray-300 px-3 py-2"
                    value={kpi.kpi}
                    onChange={(e) => {
                        const newSecondary = [...configuration.goals.secondary];
                        newSecondary[index] = {
                        ...newSecondary[index],
                        kpi: e.target.value as KPIType
                        };
                        onUpdate({
                        goals: {
                            ...configuration.goals,
                            secondary: newSecondary
                        }
                        });
                    }}
                    >
                    <option value="viewability">Viewability</option>
                    <option value="ctr">CTR</option>
                    <option value="completion">Completion Rate</option>
                    <option value="conversion">Conversion Rate</option>
                    </select>
                      <input
                        type="number"
                        className="block w-full rounded-md border border-gray-300 px-3 py-2"
                        placeholder="Target value"
                        value={kpi.target}
                        onChange={(e) => {
                          const newSecondary = [...configuration.goals.secondary];
                          newSecondary[index] = {
                            ...newSecondary[index],
                            target: Number(e.target.value)
                          };
                          onUpdate({
                            goals: {
                              ...configuration.goals,
                              secondary: newSecondary
                            }
                          });
                        }}
                      />
                      <Button 
                        variant="secondary" 
                        size="sm"
                        onClick={() => {
                          const newSecondary = configuration.goals.secondary
                            .filter((_, i) => i !== index);
                          onUpdate({
                            goals: {
                              ...configuration.goals,
                              secondary: newSecondary
                            }
                          });
                        }}
                      >
                        Remove
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>

          {/* Budget Projections & Analysis */}
          <div className="mt-6">
            <Card>
              <div className="flex items-center justify-between">
                <div>
                  <Title>Delivery Projections</Title>
                  <Text className="text-gray-500">
                    AI-powered delivery and performance forecasts
                  </Text>
                </div>
                <Badge color="blue" icon={SparklesIcon}>
                  95% Confidence
                </Badge>
              </div>

              {/* Projection Charts */}
              <div className="mt-6 grid grid-cols-2 gap-6">
                {/* Spend Pacing */}
                <div>
                  <Text className="font-medium">Spend Pacing</Text>
                  <AreaChart
                    className="h-64 mt-4"
                    data={projections.daily.map(d => ({
                      date: `Day ${d.day}`,
                      "Projected Spend": d.spend,
                      "Ideal Pace": configuration.budget.total / 30
                    }))}
                    index="date"
                    categories={["Projected Spend", "Ideal Pace"]}
                    colors={["blue", "gray"]}
                    valueFormatter={(number) => `$${number.toFixed(2)}`}
                  />
                  <div className="mt-2 flex items-center justify-between text-sm">
                    <Text className="text-gray-500">
                      Projected Total Spend: ${projections.summary.totalSpend.toLocaleString()}
                    </Text>
                    <Badge color={
                      projections.summary.projectedDelivery > 0.95 ? 'green' :
                      projections.summary.projectedDelivery > 0.85 ? 'yellow' : 'red'
                    }>
                      {(projections.summary.projectedDelivery * 100).toFixed(1)}% Delivery
                    </Badge>
                  </div>
                </div>

                {/* Performance Metrics */}
                <div>
                  <Text className="font-medium">Performance Metrics</Text>
                  <AreaChart
                    className="h-64 mt-4"
                    data={projections.daily.map(d => ({
                      date: `Day ${d.day}`,
                      "Impressions": d.impressions,
                      "Reach": d.reach
                    }))}
                    index="date"
                    categories={["Impressions", "Reach"]}
                    colors={["purple", "green"]}
                    valueFormatter={(number) => number.toLocaleString()}
                  />
                  <div className="mt-2 flex items-center justify-between text-sm">
                    <Text className="text-gray-500">
                      Avg. CPM: ${projections.summary.avgCpm.toFixed(2)}
                    </Text>
                    <Text className="text-gray-500">
                      Est. Total Impressions: {(projections.daily
                        .reduce((sum, d) => sum + d.impressions, 0))
                        .toLocaleString()}
                    </Text>
                  </div>
                </div>
              </div>

              {/* Key Metrics Summary */}
              <div className="mt-6 grid grid-cols-4 gap-4">
                {[
                  {
                    label: 'Projected Reach',
                    value: '2.4M',
                    change: '+15%',
                    status: 'positive'
                  },
                  {
                    label: 'Estimated Frequency',
                    value: '3.2x',
                    change: '-0.5x',
                    status: 'neutral'
                  },
                  {
                    label: 'Win Rate',
                    value: '72%',
                    change: '+8%',
                    status: 'positive'
                  },
                  {
                    label: 'Viewability',
                    value: '75%',
                    change: '+5%',
                    status: 'positive'
                  }
                ].map((metric) => (
                  <Card key={metric.label} className="bg-gray-50">
                    <Text className="text-gray-500">{metric.label}</Text>
                    <div className="mt-2 flex items-baseline justify-between">
                      <Metric>{metric.value}</Metric>
                      <Badge color={
                        metric.status === 'positive' ? 'green' :
                        metric.status === 'negative' ? 'red' : 'gray'
                      }>
                        {metric.change}
                      </Badge>
                    </div>
                  </Card>
                ))}
              </div>

              {/* Optimization Recommendations */}
              <div className="mt-6">
                <Text className="font-medium">Optimization Recommendations</Text>
                <div className="mt-4 space-y-3">
                  {[
                    {
                      title: 'Increase bid price for premium inventory',
                      impact: 'Potential 15% viewability improvement',
                      type: 'pricing'
                    },
                    {
                      title: 'Adjust dayparting to focus on high-performing hours',
                      impact: 'Expected 12% CTR increase',
                      type: 'targeting'
                    },
                    {
                      title: 'Expand reach with similar audience segments',
                      impact: 'Additional 500K qualified users',
                      type: 'audience'
                    }
                  ].map((recommendation) => (
                    <div
                      key={recommendation.title}
                      className="flex items-start space-x-3 p-4 bg-blue-50 rounded-lg"
                    >
                      <SparklesIcon className="h-5 w-5 text-blue-600 flex-shrink-0 mt-0.5" />
                      <div>
                        <Text className="font-medium">
                          {recommendation.title}
                        </Text>
                        <div className="flex items-center space-x-2 mt-1">
                          <Badge color="blue" size="sm">
                            {recommendation.type}
                          </Badge>
                          <Text className="text-sm text-blue-700">
                            {recommendation.impact}
                          </Text>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Risk Analysis */}
              <div className="mt-6">
                <Text className="font-medium">Risk Analysis</Text>
                <div className="mt-4 grid grid-cols-3 gap-4">
                  {[
                    {
                      factor: 'Budget Pacing',
                      risk: 'Low',
                      details: 'On track for full delivery'
                    },
                    {
                      factor: 'Inventory Availability',
                      risk: 'Medium',
                      details: 'Some competition in target segments'
                    },
                    {
                      factor: 'Performance Goals',
                      risk: 'Low',
                      details: 'Tracking above benchmarks'
                    }
                  ].map((risk) => (
                    <Card key={risk.factor}>
                      <div className="flex justify-between items-start">
                        <Text className="font-medium">{risk.factor}</Text>
                        <Badge color={
                          risk.risk === 'Low' ? 'green' :
                          risk.risk === 'Medium' ? 'yellow' : 'red'
                        }>
                          {risk.risk} Risk
                        </Badge>
                      </div>
                      <Text className="text-sm text-gray-500 mt-2">
                        {risk.details}
                      </Text>
                    </Card>
                  ))}
                </div>
              </div>
            </Card>
          </div>

          {/* Flight Rules & Constraints */}
          <div className="mt-6">
            <Card>
              <Title>Flight Rules & Constraints</Title>
              <div className="mt-4 space-y-4">
                {/* Flight Settings */}
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <Text className="font-medium">Flight Pattern</Text>
                    <select
                      className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                      value={configuration.goals.pacing.type}
                      onChange={(e) => onUpdate({
                        goals: {
                          ...configuration.goals,
                          pacing: {
                            ...configuration.goals.pacing,
                            type: e.target.value as 'even' | 'front_loaded' | 'back_loaded'
                          }
                        }
                      })}
                    >
                      <option value="even">Even Delivery</option>
                      <option value="front_loaded">Front Loaded</option>
                      <option value="back_loaded">Back Loaded</option>
                    </select>
                  </div>
                  <div>
                    <Text className="font-medium">Frequency Cap</Text>
                    <input
                      type="number"
                      className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                      placeholder="Impressions per user/day"
                      value={configuration.goals.pacing.frequency_caps.daily || ''}
                      onChange={(e) => onUpdate({
                        goals: {
                          ...configuration.goals,
                          pacing: {
                            ...configuration.goals.pacing,
                            frequency_caps: {
                              ...configuration.goals.pacing.frequency_caps,
                              daily: Number(e.target.value)
                            }
                          }
                        }
                      })}
                    />
                  </div>
                </div>

                {/* Advanced Settings */}
                <div>
                  <Text className="font-medium">Advanced Settings</Text>
                  <div className="mt-2 space-y-2">
                    {[
                      'Enable auto-optimization',
                      'Allow dynamic budget allocation',
                      'Enable bid multipliers',
                      'Use machine learning for pacing'
                    ].map((setting) => (
                      <div key={setting} className="flex items-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 text-blue-600 rounded border-gray-300"
                        />
                        <Text className="ml-2">{setting}</Text>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Card>
          </div>

    </div>
  );
}