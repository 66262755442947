import { Title } from '@tremor/react';
import { Card } from '../ui/Card';
import { 
  RocketLaunchIcon, 
  SparklesIcon, 
  ChartBarIcon, 
  ArrowTrendingUpIcon 
} from '@heroicons/react/24/outline';

interface Recommendation {
  id: string;
  type: 'optimization' | 'opportunity' | 'insight' | 'trend';
  title: string;
  description: string;
  impact: 'high' | 'medium' | 'low';
  timeToImplement: string;
}

const mockRecommendations: Recommendation[] = [
  {
    id: '1',
    type: 'optimization',
    title: 'Optimize Automotive Segment',
    description: 'Increase reach by 25% by expanding to similar audiences',
    impact: 'high',
    timeToImplement: '10 min'
  },
  {
    id: '2',
    type: 'opportunity',
    title: 'New Premium Inventory',
    description: 'High-performing news sites available for targeting',
    impact: 'medium',
    timeToImplement: '5 min'
  },
  {
    id: '3',
    type: 'trend',
    title: 'Rising Category',
    description: 'Tech vertical showing 32% increase in performance',
    impact: 'high',
    timeToImplement: '15 min'
  }
];

const typeIcons = {
  optimization: RocketLaunchIcon,
  opportunity: SparklesIcon,
  insight: ChartBarIcon,
  trend: ArrowTrendingUpIcon
};

const impactColors = {
  high: 'text-green-700 bg-green-50',
  medium: 'text-yellow-700 bg-yellow-50',
  low: 'text-gray-700 bg-gray-50'
};

export function SmartRecommendations() {
  return (
    <Card>
      <div className="flex items-center justify-between mb-4">
        <Title className="text-base font-medium">Smart Recommendations</Title>
        <button className="text-sm text-blue-600 hover:text-blue-700">
          View All
        </button>
      </div>

      <div className="divide-y divide-gray-100">
        {mockRecommendations.map((recommendation, index) => {
          const Icon = typeIcons[recommendation.type];
          
          return (
            <button
              key={recommendation.id}
              className={`w-full text-left group p-3
                ${index === 0 ? 'pt-0' : ''} 
                ${index === mockRecommendations.length - 1 ? 'pb-0 border-b-0' : ''}`
              }
              onClick={() => {}}
            >
              <div className="relative flex items-start space-x-3">
                <div className="flex-shrink-0">
                  <div className={`p-2 rounded-lg ${impactColors[recommendation.impact]}`}>
                    <Icon className="h-5 w-5" />
                  </div>
                </div>
                
                <div className="flex-1 min-w-0">
                  <div className="flex items-start justify-between mb-1">
                    <p className="text-sm font-medium text-gray-900">
                      {recommendation.title}
                    </p>
                    <span className={`ml-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium ${impactColors[recommendation.impact]}`}>
                      {recommendation.impact}
                    </span>
                  </div>
                  <p className="text-sm text-gray-500 mb-1">
                    {recommendation.description}
                  </p>
                  <p className="text-xs text-gray-500">
                    {recommendation.timeToImplement} to implement
                  </p>
                </div>
              </div>
            </button>
          );
        })}
      </div>
    </Card>
  );
}