// src/types/domains.ts

export interface DomainMetrics {
  reach: number;
  viewability: number;
  completion?: number;
  ctr: number;
  avgCpm: number;
}
  
  export interface Domain {
    url: string;
    categories: string[];
    quality_score: number;
    brand_safety_score: number;
    metrics: DomainMetrics;
    formats: string[];
    languages: string[];
    geo_coverage: string[];
  }
  
  export interface DomainBundle {
    id: string;
    name: string;
    type: 'News' | 'Sports' | 'Entertainment' | 'Technology' | 'Lifestyle' | 'Business';
    description: string;
    domains: Domain[];
    metrics: {
      totalReach: number;
      avgViewability: number;
      avgCompletion?: number;
      avgCtr: number;
      avgCpm: number;
    };
    similarityScore?: number;
    tags: string[];
    lastUpdated: string;
  }
  
  // Example domain bundles
  export const mockDomainBundles: DomainBundle[] = [
    {
      id: 'BUNDLE-001',
      name: 'Premium News Network',
      type: 'News',
      description: 'Top-tier news publishers with high viewability and brand safety',
      domains: [
        {
          url: 'nytimes.com',
          categories: ['News', 'Politics', 'Business'],
          quality_score: 95,
          brand_safety_score: 98,
          metrics: {
            reach: 45000000,
            viewability: 85,
            completion: 82,
            ctr: 0.12,
            avgCpm: 18.50
          },
          formats: ['Display', 'Video', 'Native'],
          languages: ['English'],
          geo_coverage: ['United States', 'Global']
        },
        {
          url: 'wsj.com',
          categories: ['News', 'Business', 'Finance'],
          quality_score: 94,
          brand_safety_score: 97,
          metrics: {
            reach: 35000000,
            viewability: 83,
            completion: 80,
            ctr: 0.15,
            avgCpm: 22.00
          },
          formats: ['Display', 'Video', 'Native'],
          languages: ['English'],
          geo_coverage: ['United States', 'Global']
        },
        // Add more domains...
      ],
      metrics: {
        totalReach: 80000000,
        avgViewability: 84,
        avgCompletion: 81,
        avgCtr: 0.135,
        avgCpm: 20.25
      },
      similarityScore: 95,
      tags: ['Premium', 'News', 'High Viewability'],
      lastUpdated: '2024-01-15T14:30:00Z'
    },
    {
      id: 'BUNDLE-002',
      name: 'Sports Entertainment Package',
      type: 'Sports',
      description: 'Leading sports publishers with high user engagement',
      domains: [
        {
          url: 'espn.com',
          categories: ['Sports', 'Entertainment'],
          quality_score: 92,
          brand_safety_score: 95,
          metrics: {
            reach: 55000000,
            viewability: 82,
            completion: 78,
            ctr: 0.18,
            avgCpm: 15.75
          },
          formats: ['Display', 'Video', 'Mobile'],
          languages: ['English', 'Spanish'],
          geo_coverage: ['United States', 'Latin America']
        },
        {
          url: 'cbssports.com',
          categories: ['Sports', 'News'],
          quality_score: 90,
          brand_safety_score: 94,
          metrics: {
            reach: 28000000,
            viewability: 80,
            completion: 75,
            ctr: 0.16,
            avgCpm: 14.50
          },
          formats: ['Display', 'Video'],
          languages: ['English'],
          geo_coverage: ['United States']
        },
        // Add more domains...
      ],
      metrics: {
        totalReach: 83000000,
        avgViewability: 81,
        avgCompletion: 76.5,
        avgCtr: 0.17,
        avgCpm: 15.125
      },
      similarityScore: 88,
      tags: ['Sports', 'High Engagement', 'Video'],
      lastUpdated: '2024-01-15T15:45:00Z'
    },
    {
      id: 'BUNDLE-003',
      name: 'Tech & Innovation Network',
      type: 'Technology',
      description: 'Premium technology and innovation focused publishers',
      domains: [
        {
          url: 'techcrunch.com',
          categories: ['Technology', 'Business', 'Startups'],
          quality_score: 88,
          brand_safety_score: 92,
          metrics: {
            reach: 18000000,
            viewability: 78,
            completion: 72,
            ctr: 0.22,
            avgCpm: 16.25
          },
          formats: ['Display', 'Native'],
          languages: ['English'],
          geo_coverage: ['Global']
        },
        // Add more domains...
      ],
      metrics: {
        totalReach: 45000000,
        avgViewability: 79,
        avgCompletion: 74,
        avgCtr: 0.20,
        avgCpm: 16.80
      },
      similarityScore: 82,
      tags: ['Technology', 'Innovation', 'Early Adopters'],
      lastUpdated: '2024-01-15T16:20:00Z'
    }
  ];