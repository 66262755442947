// src/data/dashboardData.ts

import {
    RocketLaunchIcon,
    UsersIcon,
    MagnifyingGlassIcon,
    ChartBarIcon,
    SparklesIcon,
    ArrowTrendingUpIcon,
    StarIcon,
    ClockIcon,
    BoltIcon,
  } from '@heroicons/react/24/outline';
  
  export const quickActionTiles: DashboardTile[] = [
    {
      id: '1',
      title: 'Create New Deal',
      description: 'Set up a new programmatic deal with customized targeting',
      link: '/deals/new',
      tags: ['Quick Setup', 'Guided Flow'],
      gradient: {
        from: 'from-blue-50',
        to: 'to-indigo-50',
        direction: 'to-br'
      },
      icon: RocketLaunchIcon,
    },
    {
      id: '2',
      title: 'Build Audience',
      description: 'Create and analyze custom audience segments',
      link: '/audiences/new',
      tags: ['Segmentation', 'Analytics'],
      gradient: {
        from: 'from-purple-50',
        to: 'to-pink-50',
        direction: 'to-r'
      },
      icon: UsersIcon,
    },
    {
      id: '3',
      title: 'Explore Inventory',
      description: 'Discover and package premium inventory',
      link: '/discovery',
      tags: ['Discovery', 'Premium'],
      gradient: {
        from: 'from-emerald-50',
        to: 'to-teal-50',
        direction: 'to-br'
      },
      icon: MagnifyingGlassIcon,
    },
    {
      id: '4',
      title: 'Performance Analysis',
      description: 'Review and optimize campaign performance',
      link: '/performance',
      tags: ['Analytics', 'Optimization'],
      gradient: {
        from: 'from-amber-50',
        to: 'to-orange-50',
        direction: 'to-tr'
      },
      icon: ChartBarIcon,
    }
  ];
  
  export const trendingDealTiles: DashboardTile[] = [
    {
      id: '1',
      title: 'Premium Sports Package',
      description: 'High-performance sports content bundle',
      link: '/deals/sports-premium',
      metrics: [
        { label: 'CTR', value: '0.15%', trend: 12 },
        { label: 'Viewability', value: '85%', trend: 5 },
        { label: 'Conv. Rate', value: '2.8%', trend: 8 }
      ],
      tags: ['Sports', 'Premium', 'High CTR'],
      status: 'active',
      highlight: true,
      gradient: {
        from: 'from-blue-100',
        to: 'to-indigo-50',
        direction: 'to-br'
      }
    },
    {
      id: '2',
      title: 'Auto Intenders Campaign',
      description: 'Targeted automotive audience campaign',
      link: '/deals/auto-premium',
      metrics: [
        { label: 'CTR', value: '0.18%', trend: 15 },
        { label: 'Viewability', value: '88%', trend: 7 },
        { label: 'Conv. Rate', value: '3.2%', trend: 10 }
      ],
      tags: ['Automotive', 'High Performance'],
      status: 'active',
      highlight: true,
      gradient: {
        from: 'from-emerald-100',
        to: 'to-green-50',
        direction: 'to-r'
      }
    },
    // More trending deals...
  ];
  
  export const recentActivityTiles: DashboardTile[] = [
    {
      id: '1',
      title: 'Weekly Performance Review',
      description: 'Analysis of active campaigns and optimization opportunities',
      link: '/analysis/weekly',
      metrics: [
        { label: 'Deals Analyzed', value: '24' },
        { label: 'Optimizations', value: '8' },
        { label: 'Potential Lift', value: '+15%' }
      ],
      gradient: {
        from: 'from-violet-50',
        to: 'to-purple-50',
        direction: 'to-br'
      }
    },
    // More activities...
  ];
  
  export interface DashboardTile {
    id: string;
    title: string;
    description?: string;
    link: string;
    metrics?: {
      label: string;
      value: string;
      trend?: number;
    }[];
    tags?: string[];
    status?: 'active' | 'pending' | 'completed';
    highlight?: boolean;
    gradient?: {
      from: string;
      to: string;
      direction?: 'to-r' | 'to-br' | 'to-tr';
    };
    icon?: any; // Type for Heroicon components
  }
  
  // Your existing tiles data...
  
  export const recommendedTiles: DashboardTile[] = [
    {
      id: '1',
      title: 'Optimize Auto Campaign',
      description: 'Suggested optimizations based on recent performance',
      link: '/optimization/auto-campaign',
      metrics: [
        { label: 'Potential Lift', value: '+15%' },
        { label: 'Confidence', value: '92%' }
      ],
      tags: ['AI Recommended', 'High Impact'],
      gradient: {
        from: 'from-purple-50',
        to: 'to-indigo-50',
        direction: 'to-br'
      },
      icon: SparklesIcon
    },
    {
      id: '2',
      title: 'Expand Sports Audience',
      description: 'Lookalike audience opportunity identified',
      link: '/audiences/expand-sports',
      metrics: [
        { label: 'Reach', value: '2.4M' },
        { label: 'Similarity', value: '89%' }
      ],
      tags: ['Audience', 'Growth'],
      gradient: {
        from: 'from-blue-50',
        to: 'to-cyan-50',
        direction: 'to-r'
      },
      icon: ArrowTrendingUpIcon
    },
    {
      id: '3',
      title: 'Premium Inventory Bundle',
      description: 'High-performance inventory package',
      link: '/inventory/premium-bundle',
      metrics: [
        { label: 'Viewability', value: '92%' },
        { label: 'CPM', value: '$12.50' }
      ],
      tags: ['Premium', 'High Value'],
      gradient: {
        from: 'from-emerald-50',
        to: 'to-teal-50',
        direction: 'to-br'
      },
      icon: StarIcon
    },
    {
      id: '4',
      title: 'Performance Alert',
      description: 'CTR optimization opportunity detected',
      link: '/alerts/ctr-optimization',
      metrics: [
        { label: 'Current CTR', value: '0.08%' },
        { label: 'Target CTR', value: '0.12%' }
      ],
      tags: ['Alert', 'Optimization'],
      gradient: {
        from: 'from-amber-50',
        to: 'to-yellow-50',
        direction: 'to-r'
      },
      icon: BoltIcon
    }
  ];
  
  export const topPerformingTiles: DashboardTile[] = [
    {
      id: '1',
      title: 'Sports Premium Package',
      description: 'Premium sports content network',
      link: '/deals/sports-premium',
      metrics: [
        { label: 'CTR', value: '0.22%', trend: 15 },
        { label: 'ROI', value: '3.8x', trend: 12 }
      ],
      tags: ['Top Performance', 'Sports'],
      status: 'active',
      gradient: {
        from: 'from-blue-100',
        to: 'to-indigo-50',
        direction: 'to-br'
      },
      icon: StarIcon
    },
    {
      id: '2',
      title: 'Tech Enthusiasts',
      description: 'High-intent technology audience',
      link: '/audiences/tech-premium',
      metrics: [
        { label: 'Conversion', value: '4.2%', trend: 18 },
        { label: 'Engagement', value: '92%', trend: 8 }
      ],
      tags: ['Audience', 'Technology'],
      status: 'active',
      gradient: {
        from: 'from-violet-100',
        to: 'to-purple-50',
        direction: 'to-r'
      },
      icon: UsersIcon
    },
    {
      id: '3',
      title: 'Premium News Network',
      description: 'Top-tier news publishers package',
      link: '/inventory/news-premium',
      metrics: [
        { label: 'Viewability', value: '94%', trend: 5 },
        { label: 'Completion', value: '88%', trend: 7 }
      ],
      tags: ['Premium', 'News'],
      status: 'active',
      gradient: {
        from: 'from-emerald-100',
        to: 'to-green-50',
        direction: 'to-br'
      },
      icon: ChartBarIcon
    }
  ];
  
  export const exploreNewTiles: DashboardTile[] = [
    {
      id: '1',
      title: 'CTV Opportunities',
      description: 'Explore premium CTV inventory',
      link: '/explore/ctv',
      metrics: [
        { label: 'Available Publishers', value: '45+' },
        { label: 'Avg Completion', value: '95%' }
      ],
      tags: ['CTV', 'Premium', 'New'],
      gradient: {
        from: 'from-rose-50',
        to: 'to-pink-50',
        direction: 'to-br'
      },
      icon: SparklesIcon
    },
    {
      id: '2',
      title: 'Commerce Audiences',
      description: 'New shopping behavior segments',
      link: '/explore/commerce',
      metrics: [
        { label: 'Segments', value: '24' },
        { label: 'Total Reach', value: '45M' }
      ],
      tags: ['Retail', 'New Segments'],
      gradient: {
        from: 'from-amber-50',
        to: 'to-yellow-50',
        direction: 'to-r'
      },
      icon: UsersIcon
    },
    {
      id: '3',
      title: 'Gaming Network',
      description: 'Premium gaming inventory bundle',
      link: '/explore/gaming',
      metrics: [
        { label: 'Publishers', value: '35+' },
        { label: 'Engagement', value: '88%' }
      ],
      tags: ['Gaming', 'New Bundle'],
      gradient: {
        from: 'from-purple-50',
        to: 'to-violet-50',
        direction: 'to-br'
      },
      icon: RocketLaunchIcon
    }
  ];