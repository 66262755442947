import { Card as TremorCard } from '@tremor/react';

interface CardProps {
  children: React.ReactNode;
  className?: string;
}

export function Card({ children, className = '' }: CardProps) {
  return (
    <TremorCard className={`bg-white rounded-lg shadow-sm p-4 overflow-hidden ${className}`}>
      {children}
    </TremorCard>
  );
}