import { Card } from '../ui/Card';
import { Title, AreaChart } from '@tremor/react';
import { ExclamationTriangleIcon, ArrowTrendingUpIcon } from '@heroicons/react/24/outline';

const data = [
  {
    date: '2024-01-01',
    'Expected': 1400,
    'Actual': 1380,
  },
  {
    date: '2024-01-02',
    'Expected': 1398,
    'Actual': 1390,
  },
  {
    date: '2024-01-03',
    'Expected': 1420,
    'Actual': 1580,
  },
  {
    date: '2024-01-04',
    'Expected': 1425,
    'Actual': 1620,
  },
  {
    date: '2024-01-05',
    'Expected': 1430,
    'Actual': 1680,
  },
];

const anomalies = [
  {
    id: 1,
    title: 'Unexpected performance spike',
    description: 'CTR increased by 45% in the last 24 hours',
    severity: 'high',
    type: 'positive'
  },
  {
    id: 2,
    title: 'Bid rate deviation',
    description: 'Bid rate is 20% below expected range',
    severity: 'medium',
    type: 'negative'
  }
];

export function AnomalyDetection() {
  return (
    <Card>
      <div className="space-y-6">
        <div className="flex items-start justify-between">
          <div>
            <Title className="text-base font-medium">Anomaly Detection</Title>
            <p className="text-sm text-gray-500 mt-1">
              AI-detected patterns and deviations in your campaigns
            </p>
          </div>
          <span className="inline-flex items-center rounded-full bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800">
            2 anomalies detected
          </span>
        </div>

        {/* Chart */}
        <div className="h-72">
          <AreaChart
            data={data}
            index="date"
            categories={['Expected', 'Actual']}
            colors={['gray', 'blue']}
            showLegend={true}
            showGridLines={false}
            showAnimation={true}
          />
        </div>

        {/* Anomalies List */}
        <div className="space-y-4">
          {anomalies.map((anomaly) => (
            <div
              key={anomaly.id}
              className="flex items-start space-x-3 p-3 rounded-lg bg-gray-50"
            >
              {anomaly.type === 'positive' ? (
                <ArrowTrendingUpIcon className="h-5 w-5 text-green-500 mt-0.5" />
              ) : (
                <ExclamationTriangleIcon className="h-5 w-5 text-yellow-500 mt-0.5" />
              )}
              <div>
                <h4 className="text-sm font-medium text-gray-900">{anomaly.title}</h4>
                <p className="text-sm text-gray-500 mt-1">{anomaly.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
}