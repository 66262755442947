import { useState } from 'react';
import { Tab } from '@headlessui/react';
import {
  PlusIcon,
  AdjustmentsHorizontalIcon,
  UsersIcon,
  TableCellsIcon,
  Squares2X2Icon,
} from '@heroicons/react/24/outline';
import { Card } from '../components/ui/Card';
import { AudienceMetrics } from '../components/audiences/AudienceMetrics';
import { AudienceTable } from '../components/audiences/AudienceTable';
import { AudienceBuilder } from '../components/audiences/AudienceBuilder';
import { AudienceFilters } from '../components/audiences/AudienceFilters';

const viewOptions = [
  { name: 'List View', icon: TableCellsIcon },
  { name: 'Grid View', icon: Squares2X2Icon }
];

const audienceTypes = [
  { value: 'all', label: 'All Segments' },
  { value: 'first-party', label: 'First Party' },
  { value: 'third-party', label: 'Third Party' },
  { value: 'contextual', label: 'Contextual' },
  { value: 'custom', label: 'Custom Built' }
];

export default function Audiences() {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [isBuilderOpen, setIsBuilderOpen] = useState(false);
  const [selectedType, setSelectedType] = useState('all');

  return (
    <div className="min-h-screen bg-gray-50 pb-12">
      <div className="max-w-[1920px] mx-auto p-4 sm:p-6 lg:p-8">
        {/* Header */}
        <div className="mb-8">
          <div className="flex items-center justify-between">
            <div>
              <h1 className="text-2xl font-semibold text-gray-900">Audiences</h1>
              <p className="mt-1 text-sm text-gray-500">
                Create, analyze, and manage audience segments
              </p>
            </div>
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              onClick={() => setIsBuilderOpen(true)}
            >
              <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
              Create Audience
            </button>
          </div>
        </div>

        {/* Metrics Overview */}
        <AudienceMetrics />

        {/* Main Content */}
        <div className="mt-8">
          <Card>
            <Tab.Group>
              {/* Controls Bar */}
              <div className="px-4 py-5 sm:px-6">
                <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between space-y-4 sm:space-y-0">
                  {/* Left side controls */}
                  <div className="flex items-center space-x-4">
                    <select
                      className="rounded-md border-gray-300 py-1.5 pl-3 pr-8 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                      value={selectedType}
                      onChange={(e) => setSelectedType(e.target.value)}
                    >
                      {audienceTypes.map((type) => (
                        <option key={type.value} value={type.value}>
                          {type.label}
                        </option>
                      ))}
                    </select>
                    <Tab.List className="isolate inline-flex rounded-md shadow-sm">
                      {viewOptions.map((option, optionIdx) => (
                        <Tab
                          key={option.name}
                          className={({ selected }) =>
                            `relative inline-flex items-center px-3 py-2 text-sm font-semibold ring-1 ring-inset focus:z-10
                            ${optionIdx === 0 ? 'rounded-l-md' : ''}
                            ${optionIdx === viewOptions.length - 1 ? 'rounded-r-md' : ''}
                            ${selected
                              ? 'bg-blue-600 text-white ring-blue-600'
                              : 'bg-white text-gray-900 ring-gray-300 hover:bg-gray-50'
                            }`
                          }
                        >
                          <option.icon className="mr-2 h-5 w-5" aria-hidden="true" />
                          {option.name}
                        </Tab>
                      ))}
                    </Tab.List>
                  </div>

                  {/* Right side controls */}
                  <button
                    type="button"
                    className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={() => setIsFiltersOpen(true)}
                  >
                    <AdjustmentsHorizontalIcon className="mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                    Filters
                  </button>
                </div>
              </div>

              {/* Content */}
              <Tab.Panels>
                <Tab.Panel>
                  <AudienceTable />
                </Tab.Panel>
                <Tab.Panel>
                  <div className="p-4">Grid View Coming Soon</div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </Card>
        </div>
      </div>

      {/* Audience Builder Modal */}
      <AudienceBuilder
        open={isBuilderOpen}
        onClose={() => setIsBuilderOpen(false)}
      />

      {/* Filters Sidebar */}
      <AudienceFilters
        open={isFiltersOpen}
        onClose={() => setIsFiltersOpen(false)}
      />
    </div>
  );
}